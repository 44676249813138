import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const Modal = ({ children, urlKey, ...props }) => {
  // Router info
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // Tracking whether the modal is visible or not
  const [visible, setVisible] = useState(() => {
    if (params.get(urlKey) === "true") {
      return true;
    } else {
      return false;
    }
  });

  // ESC key listener function
  const escapeClick = useCallback(
    e => {
      if (navigate && e.keyCode === 27) {
        navigate(location.pathname);
      }
    },
    [location, navigate]
  );

  // Initially setting the visibility according to the url query param and changing it after that according to the url
  useEffect(() => {
    setVisible(() => {
      if (params.get(urlKey) === "true") {
        return true;
      } else {
        return false;
      }
    });
  }, [location, params, urlKey]);

  // Setting the state of the page overflow/event listeners according to the visibility of the modal
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", escapeClick, false);
    }
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", escapeClick, false);
    };
  }, [escapeClick, visible]);

  return (
    visible &&
    createPortal(
      <OverLay
        id="modal-overlay"
        onMouseDown={e => {
          if (e.target.id === "modal-overlay" && navigate && !props.preventClicking) {
            navigate(location.pathname);
          }
        }}
        {...props}
        urlKey={urlKey}
      >
        <StyledModal>{children}</StyledModal>
      </OverLay>,
      document.getElementById("modal_root")
    )
  );
};
const StyledModal = styled.div`
  @keyframes modal-fade-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  animation: modal-fade-in 0.6s;
  @media (max-width: 768px) {
    align-items: ${props => (props.overflow ? "initial" : "center")};
  }
`;

const OverLay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
`;

export default Modal;
