import React, { useEffect } from "react";
import { StyledContainer } from "../Account";
import AccTitle from "../../../Components/MyAccount/AccTitle";
import { useNavigate } from "react-router";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../../Components/MyAccount/Button";
import { ReactComponent as LoginIcon } from "../../../images/Account/protect.svg";
import { ReactComponent as EditIcon } from "../../../images/Account/edit.svg";
import { useForm } from "react-hook-form";
import {
  ButtonGroup,
  ErrorMessage,
  FormContainer,
  InfoCard,
  StyledBoxInput,
} from "../../../Components/MyAccount/CommonStyled";

const defaultValues = {
  mobileNumber: "+201012052557",
};

function obfuscatePhoneNumber(phoneNumber) {
  const strPhoneNumber = String(phoneNumber);

  // Extract the parts of the phone number
  const countryCode = strPhoneNumber.slice(0, 5);
  const visibleEnd = strPhoneNumber.slice(-3);
  const obfuscatedMiddle = "*".repeat(strPhoneNumber.length - 8);

  return `${countryCode}${obfuscatedMiddle}${visibleEnd}`;
}

function SendCode() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const navigate = useNavigate();
  const [edit, setEdit] = React.useState(false);

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: { mobileNumber: obfuscatePhoneNumber("+201012052557") },
  });

  const { errors } = formState;

  useEffect(() => {
    if (edit) {
      setValue("mobileNumber", defaultValues.mobileNumber);
    }
  }, [edit, setValue]);

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <AccTitle title={t.account.loginDetails} description={t.account.loginDetailsDesc} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div>
            <StyledBoxInputWithLegend disabled={!edit} language={language} err={!!errors?.mobileNumber}>
              <label htmlFor="mobileNumber">{t.account.mobileNumber}</label>
              <input
                type="tel"
                id="mobileNumber"
                placeholder={t.account.enterMobileNumber}
                disabled={!edit}
                {...register("mobileNumber", {
                  required: t.thisFieldIsRequired,
                  pattern: {
                    value: /^(\+?\d{1,10}[\s-]?)?\d{10}$/,
                    message: t.account.invalidMobileNumber,
                  },
                })}
              />
              <button
                type="button"
                onClick={() => {
                  setEdit(true);
                }}
              >
                <EditIcon /> {t.edit}
              </button>
            </StyledBoxInputWithLegend>
            {errors.mobileNumber && <ErrorMessage>{errors.mobileNumber.message}</ErrorMessage>}
          </div>
          <div>
            <InfoCard>
              <span>
                <LoginIcon />
              </span>
              <h4>{t.account.PasswordAndQuestions}</h4>
              <p>...</p>
            </InfoCard>
          </div>
        </div>
        <BtnGroup>
          <div>
            <Button onClick={() => navigate(-1)} type="button" variation="secondary">
              {t.cancel}
            </Button>
            <Button>{t.account.sendCode}</Button>
          </div>
        </BtnGroup>
      </FormContainer>
    </StyledContainer>
  );
}

export default SendCode;

const BtnGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 2rem;
`;

const StyledBoxInputWithLegend = styled(StyledBoxInput)`
  position: relative;
  input {
    text-align: ${props => (props.language === "ar" ? "end" : "start")};
  }
  button {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
    position: absolute;
    top: 50%;
    color: ${props => (props.disabled ? "#666666" : "#0b42cc")};
    inset-inline-end: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      fill: ${props => (props.disabled ? "#666666" : "#0b42cc")};
    }
  }
`;
