import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useApi from "../../hooks/useApi";
import { ArrowIcon } from "../assets/Icons";
import Skeleton from "./Skeleton";
import { Link } from "react-router-dom";

const SellerBrands = ({ sellerId, language, t }) => {
  const { data, error, get, loading } = useApi();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    get(`/brand`, { page, page_size: 8, seller: sellerId });
  }, [get, page, sellerId]);

  useEffect(() => {
    setHasMore(data?.next ? true : false);
  }, [data, page]);

  useEffect(() => {
    if (data?.results) {
      setBrands(prev => {
        const newBrands = data.results.filter(brand => !prev.some(b => b.id === brand.id));
        return [...prev, ...newBrands];
      });
    }
  }, [data]);

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  if (error) return <div>Error loading brands. Please try again later.</div>;

  const CardSkeleton = () => <Skeleton width="100%" height="100px" style={{ borderRadius: "8px" }} />;

  return (
    <BrandsSection>
      <Title>{t.seller.exploreBrands}</Title>
      {!loading ? (
        <BrandsGrid>
          {brands?.map(brand => (
            <Link to="/cars" state={{ brand: brand.id }} className="brand">
              <BrandItem key={brand.id}>
                <BrandLogo src={brand.logo} alt={`${brand.name} logo`} />
                <div>
                  <BrandName>{language === "ar" ? brand.name_ar : brand.name}</BrandName>
                  <CarsAvailable>12 {t.seller.CarsAvailable}</CarsAvailable>
                </div>
              </BrandItem>
            </Link>
          ))}
        </BrandsGrid>
      ) : (
        <BrandsGrid>
          {Array.from({ length: 8 }).map((_, index) => (
            <CardSkeleton key={index} />
          ))}
        </BrandsGrid>
      )}
      {hasMore && (
        <ShowMoreButton onClick={handleLoadMore}>
          <StyledArrowIcon /> {t.seller.showMore}
        </ShowMoreButton>
      )}
    </BrandsSection>
  );
};

export default SellerBrands;

const BrandsSection = styled.section`
  padding: 4vw 10vw;
  @media (max-width: 768px) {
    padding: 4vw;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
`;

const BrandItem = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BrandLogo = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-bottom: 0.5rem;
`;

const BrandName = styled.p`
  font-size: 1rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const CarsAvailable = styled.p`
  font-size: 0.8rem;
  color: #555;
`;

const ShowMoreButton = styled.button`
  color: #154cd6;
  background-color: transparent;
  margin: auto;
  border: none;
  border-radius: 8px;
  margin-top: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  fill: #154cd6;
  stroke: #154cd6;
`;
