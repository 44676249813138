import React, { useEffect, useState } from "react";
import StyledForm from "../../assets/StyledForm";
import lock from "../../../images/auth/lock.svg";
import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { useForm } from "react-hook-form";
import SelectMenu from "../../assets/SelectMenu";
import { countriesCodes } from "../../../util/data";
import "./style.css"
import { forget_password } from "../../../hooks/Auth/forget-password/authFunctions";

const Recover = ({ onSubmit, formData, setFormData, isDisabled }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const codeField = {
    name: "code",
    options: countriesCodes,
  };

  const [loginLoading, setLoginLoading] = useState(false);

  const recoverSubmit = handleSubmit(async (data) => {
    const { countryCode, mobile } = data;
    const formattedMobile = String(mobile).replace(/^0+/, "");
    const formattedData = {
      phonenumber: `${countryCode}${formattedMobile}`,
    };
    console.log("Final Login Data : ", formattedData);

    setLoginLoading(true);

    try {
      const response = await forget_password(formattedData);
      
      if (response?.status === 200) {
        onSubmit();
        localStorage.setItem("phone_number", formattedData.phonenumber);
      }
    } catch (err) {
      console.error("Error during login:", err);
    } finally {
      setLoginLoading(false);
    }
  });

  useEffect(() => {
    setValue('countryCode', formData.countryCode);
  }, [formData.countryCode, setValue]);

  return (
    <div className="container">
      <img src={lock} className="section-icon" alt='img' />
      <div className="title">
        <p>{t.authPages.forget_password_page.recovery.title_line1}</p>
        <p>{t.authPages.forget_password_page.recovery.title_line2}</p>
      </div>
      <div className="text desktop-only">
        {t.authPages.forget_password_page.recovery.text}
      </div>
      <StyledForm
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="fields">
          <div className="input-div" key="mobile">
            <div className="mobile-input-div">
              <input
                className="the-mobile-input"
                name="mobile"
                placeholder={t.authPages.mobile}
                // type={"number"}
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                required={true}
                onKeyDown={(e) => {
                  if (e.key === "e" || e.key === "-") {
                    e.preventDefault();
                  }
                }}
                {...register("mobile", {
                  required: {
                    value: true,
                    message: "required",
                  },
                  validate: (value) => {
                    if (!isNaN(value) || formData["mobile"]) {
                      return true;
                    } else return "required";
                  },
                  valueAsNumber: true,

                  onChange: (e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    setFormData({
                      ...formData,
                      mobile: value,
                    });
                  },
                })}
                value={formData["mobile"] || ""}
              />
              <div className="select-code">
                <SelectMenu
                  field={codeField}
                  value={formData.countryCode}
                  setValue={(value) => {
                    setFormData({
                      ...formData,
                      countryCode: value,
                    });
                  }}
                />
              </div>
            </div>
            {errors["mobile"]?.message && (
              <p className="error-text">
                {language === "en"
                  ? `${t.authPages.mobile} is ${t[errors["mobile"]?.message]
                  }`
                  : `${t[errors["mobile"]?.message]} ${t.authPages.mobile
                  }`}
              </p>
            )}
          </div>
        </div>

        <div className="btn-container">
          <button
            className="submit"
            type="submit"
            onClick={recoverSubmit}
            disabled={loginLoading}
          >
            {t.authPages.forget_password_page.recovery.recover}
          </button>
        </div>
      </StyledForm>
    </div>
  );
};

export default Recover;
