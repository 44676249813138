import React, { cloneElement } from "react";
import Modal from "../assets/Modal";
import { useNavigate } from "react-router";
import { CloseIcon, PhoneIcon } from "../assets/Icons";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import useApi from "../../hooks/useApi";

function SellerPhoneModal({ seller, children }) {
  const navigate = useNavigate();
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const { get } = useApi();

  const handleClickOnPhone = async () => {
    await get(`/seller/${seller.id}/contact/`);
  };

  const openModal = () => {
    navigate("?modal=true");
  };

  const onClose = () => {
    navigate("?model=false");
  };
  return (
    <div>
      {cloneElement(children, {
        onClick: () => {
          openModal();
          handleClickOnPhone();
        },
      })}

      <Modal urlKey="modal">
        <PopupContainer>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <Logo>
            <img src={seller?.logo} alt="logo" />
          </Logo>
          <Title>{t.sellerPopup.title}</Title>
          <Subtitle>{t.sellerPopup.workingHours}</Subtitle>
          <PhoneButton disabled={!seller?.phonenumber} href={`tel:${seller?.phonenumber}`}>
            <PhoneIcon size={18} />
            {seller?.phonenumber ? seller?.phonenumber : t.sellerPopup.noPhone}
          </PhoneButton>
        </PopupContainer>
      </Modal>
    </div>
  );
}

export default SellerPhoneModal;

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
`;

const Logo = styled.div`
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
`;

const PhoneButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #f0f0f0;
  border: 1px solid #154cd6;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  color: #154cd6;
  font-weight: bold;
  svg {
    width: 30px;
    height: 30px;
    stroke: #154cd6;
  }
  /* handle disabled */
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;
