import React from 'react'
import { ReactComponent as LogoIcon } from "./icons/empty.svg";
import styled from "styled-components";

const Empty = ({ title }) => {
    return (
        <StyledPage>
            <div className="empty-logo">
                <LogoIcon />
            </div>
            <h3>There are no {title ? title : "Data"}</h3>
        </StyledPage>
    )
}
const StyledPage = styled.div`
    .empty-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .empty-logo svg {
        max-width: 300px;
        max-height: 300px;
    }
    h3 {
        margin-top: 15px;
        text-align: center;
        font-size: 17px;
    }
`
export default Empty