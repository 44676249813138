import React, { useState, useEffect } from "react";
import styled from "styled-components";
import engine_icon from "../../images/engine.svg";
import tank_icon from "../../images/tank.svg";
import seat_icon from "../../images/seat.svg";
import trim_icon from "../../images/trim.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const Features = ({ features }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [featuresAttr, setFeaturesArr] = useState({});

  useEffect(() => {
    if (language == "en") {
      setFeaturesArr({
        fuel_type: { name: t.carPage.features.fuel_type, icon: tank_icon },
        trim: { name: t.carPage.features.trim, icon: trim_icon },
        internal_tool_kit: {
          name: t.carPage.features.internal_toolkit,
          icon: seat_icon,
        },
        engine: { name: t.carPage.features.engine, icon: engine_icon },
      });
    } else {
      setFeaturesArr({
        trim: { name: t.carPage.features.trim, icon: trim_icon },
        fuel_type: { name: t.carPage.features.fuel_type, icon: tank_icon },
        engine: { name: t.carPage.features.engine, icon: engine_icon },
        internal_tool_kit: {
          name: t.carPage.features.internal_toolkit,
          icon: seat_icon,
        },
      });
    }
  }, [language]);

  // const featuresAttr = {
  //   fuel_type: { name: t.carPage.features.fuel_type, icon: tank_icon },
  //   trim: { name: t.carPage.features.trim, icon: trim_icon },
  //   internal_tool_kit: {
  //     name: t.carPage.features.internal_toolkit,
  //     icon: seat_icon,
  //   },
  //   engine: { name: t.carPage.features.engine, icon: engine_icon },
  // };
  return (
    <StyledSection language={language}>
      <div className="section-header">{t.carPage.features.title}</div>
      <div className="items">
        {Object.keys(featuresAttr).map(
          (item) =>
            featuresAttr[item] && (
              <div className="item">
                <div className="key">
                  <img src={featuresAttr[item].icon} />
                  <p className="text">{featuresAttr[item].name} </p>
                </div>
                <p
                  className="value"
                  style={
                    item == "engine"
                      ? {
                          direction: "ltr",
                          textAlign: language == "ar" ? "right" : "left",
                        }
                      : {}
                  }
                >
                  {features[item]}
                </p>
              </div>
            )
        )}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
  padding-top: 1.806vw;
  // border-bottom: 0.069vw solid #eeeeee;
  .features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.694vw 2.083vw;

    .item {
      width: 38.368vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.069vw solid #e4e6e8;
      padding-bottom: 1.111vw;

      .key {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #0d0a19;
        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .value {
        font-weight: 700;
        font-size: 0.833vw;
        line-height: 1.25vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .item:nth-last-child(-n + 2) {
      border: none;
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 4.267vw;
    // padding: 6.4vw 4.267vw;
    // border-bottom: 0.267vw solid #eeeeee;
    .features {
      display: flex;
      flex-direction: column;
      gap: 3.2vw;

      .item {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.267vw solid #e4e6e8;
        padding-bottom: 4.8vw;

        .key {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 3.733vw;
          line-height: 5.6vw;
        }
        .value {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 4.267vw;
          line-height: 6.4vw;
        }
      }
    }
  }
`;
export default Features;
