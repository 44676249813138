import React from "react";
import styled from "styled-components";
import CarImage from "../../images/car1.png";
import { CancelIcon, HeartIcon, PlusIcon, StarIcon, SupportIcon, WalletIcon } from "../assets/Icons";

const Feature = ({ icon, title, description }) => (
  <FeatureContainer>
    <IconContainer>{icon}</IconContainer>
    <FeatureContent>
      <FeatureTitle>{title}</FeatureTitle>
      <FeatureDescription>{description}</FeatureDescription>
    </FeatureContent>
  </FeatureContainer>
);

const WhySeller = ({ language, t, seller }) => {
  return (
    <Container>
      <BackgroundImage language={language} />
      <ContentContainer>
        <Title>
          {t.Why} {language === "ar" ? seller?.name_ar : seller?.name} {language === "ar" ? "؟" : "?"}
        </Title>
        <Subtitle>{t.seller.something_to_make_you_love_us_more}</Subtitle>

        <FeaturesGrid>
          <Feature
            icon={<SupportIcon />}
            title={t.seller.premium_support}
            description={t.seller.available_for_helping_you}
          />
          <Feature
            icon={<StarIcon />}
            title={t.seller.high_quality_cars}
            description={t.seller.cars_certificated_by_gurus}
          />
          <Feature
            icon={<HeartIcon />}
            title={t.seller.trusted_by_10_clients}
            description={t.seller.happy_clients_ready_for_our_cars}
          />
          <Feature
            icon={<CancelIcon />}
            title={t.seller.free_cancelation}
            description={t.seller.free_cancelation_description}
          />
          <Feature
            icon={<WalletIcon />}
            title={t.seller.payment_with_many_plans}
            description={t.seller.provide_us_your_details}
          />
          <Feature
            icon={<PlusIcon />}
            title={t.seller.and_more_features}
            description={t.seller.this_space_is_too_small}
          />
        </FeaturesGrid>
      </ContentContainer>
    </Container>
  );
};

export default WhySeller;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem;
  background-color: white;
  position: relative;
  height: 85vh;
  background-color: #eef5fb;

  @media (max-width: 900px) {
    height: auto;
    padding: 2rem 0;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  inset: 0;
  background-image: url(${CarImage});
  background-size: calc(30% + 2rem);
  background-position: left;
  background-repeat: no-repeat;
  transform: ${props => (props.language === "ar" ? "scaleX(-1)" : "scaleX(1)")};
  @media (max-width: 900px) {
    visibility: hidden;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  position: absolute;
  padding-left: 2rem;
  top: 10%;
  inset-inline-end: 10vw;
  @media (max-width: 900px) {
    width: 100%;
    padding: 2rem;
    position: relative;
    inset-inline-end: 0;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  @media (max-width: 900px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  color: #1e40af;
  text-transform: uppercase;
  margin-bottom: 4rem;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 40px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  margin-inline-end: 10px;
  margin-top: 0.25rem;
  color: #1e40af;
`;

const FeatureContent = styled.div``;

const FeatureTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 0.8rem;
  color: #3d5974;
  font-size: 16px;
`;

const FeatureDescription = styled.p`
  color: #4b5563;
  font-size: 15px;
  font-weight: 400;
`;
