import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { shapes } from "../../util/data";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const CarShapes = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledSection language={language}>
      <div className="section-header">{t.shapes.title}</div>
      <div className="shapes">
        {shapes.map((shape, i) => (
          <Link className="shape" to="/cars" state={{ shape: shape.value }}>
            <img src={shape.img} />
            <p> {language == "en" ? shape.name : shape.name_ar}</p>
          </Link>
        ))}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: ${(props) =>
    props.language == "en" ? "0 10.417vw" : "0  10.694vw 0 10.417vw"};
  margin-top: 7.153vw;
  .shapes {
    display: flex;
    align-items: center;
    // gap: 3.125vw;
    justify-content: space-between;
    margin-top: 3.056vw;
    .shape {
      display: flex;
      flex-direction: column;
      gap: 1.667vw;

      img {
        width: 11.528vw;
        height: 7.639vw;
        object-fit: contain;
        -webkit-transform: ${(props) =>
          props.language === "ar" && "scaleX(-1)"};
        transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
      }
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Inter" : "GE-SS-Bold"};
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.667vw;
        color: #0d0a19;
        text-align: center;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${(props) =>
      props.language == "en" ? " 0 0 0 4.267vw" : " 0 4.267vw 0 0"};
    margin-top: 6.4vw;
    .shapes {
      display: flex;
      align-items: center;
      // gap: 3.2vw;
      margin-top: 4.267vw;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      .shape {
        display: flex;
        flex-direction: column;
        gap: 2.133vw;

        img {
          width: 58.667vw;
          height: 29.333vw;
          object-fit: contain;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 4.267vw;
          line-height: 6.4vw;
        }
      }
    }

    .shapes::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;
export default CarShapes;
