import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Next } from "../../images/next.svg";
import { ReactComponent as Prev } from "../../images/prev.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const PrevNextPagination = ({ currentPage, totalItems, pageSize, onPageChange }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  // Calculate total pages based on totalItems and pageSize
  const totalPages = Math.ceil(totalItems / pageSize) || 1;

  // Handle previous page click
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);  // Pass new page to parent component
    }
  };

  // Handle next page click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);  // Pass new page to parent component
    }
  };

  return (
    <StyledPagination language={language}>
      <button disabled={currentPage === 1} onClick={handlePrevClick}>
        <Prev />
      </button>
      <button disabled={currentPage === totalPages} onClick={handleNextClick}>
        <Next />
      </button>
    </StyledPagination>
  );
};
const StyledPagination = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  display: flex;
  align-items: center;
  gap: 0.625vw;
  flex-direction: ${(props) => props.language == "ar" && "row-reverse"};
  button {
    width: 3.194vw;
    height: 3.194vw;
    background: #f5f6f7;
    border-radius: 0.694vw;
    cursor: pointer;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    width: 1.25vw;
    height: 1.25vw;
  }
  .disabled {
    svg {
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    gap: 2.133vw;

    button {
      width: 6.4vw;
      height: 6.4vw;
      border-radius: 1.067vw;
      cursor: pointer;
    }

    svg {
      width: 3.2vw;
      height: 3.2vw;
    }
  }
`;
export default PrevNextPagination;
