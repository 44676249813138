import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BrandCard from "./BrandCard";
import subaru from "../../images/brands/subaru.png";
import nissan from "../../images/brands/nissan.png";
import mercedes from "../../images/brands/mercedes.png";
import toyota from "../../images/brands/toyota.png";
import { ReactComponent as Down } from "../../images/down-arrow.svg";
import { ReactComponent as Next } from "../../images/next.svg";
import { axiosCarvenui } from "../../util/integration";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes } from "../../util/sizes";

export const fake_brands = [
  {
    id: 1,
    name: "Subaru",
    logo: subaru,
    cars_count: 12,
  },
  {
    id: 2,
    name: "Nissan",
    logo: nissan,
    cars_count: 12,
  },
  {
    id: 3,
    name: "Toyota",
    logo: toyota,
    cars_count: 12,
  },
  {
    id: 4,
    name: "Mercedes-Benz",
    logo: mercedes,
    cars_count: 5,
  },
  {
    id: 5,
    name: "Subaru",
    logo: subaru,
    cars_count: 12,
  },
  {
    id: 6,
    name: "Nissan",
    logo: nissan,
    cars_count: 12,
  },
  {
    id: 7,
    name: "Toyota",
    logo: toyota,
    cars_count: 12,
  },
  {
    id: 8,
    name: "Mercedes-Benz",
    logo: mercedes,
    cars_count: 5,
  },
];

const Brands = ({ initialBrands, initialLoading, initialNextBrands }) => {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  useEffect(() => {
    setBrands(initialBrands);
    setLoading(initialLoading);
  }, [initialBrands, initialLoading]);

  useEffect(() => {
    setBrands(initialBrands);
    setLoading(initialLoading);
  }, [initialBrands, initialLoading]);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);
  // useEffect(() => {
  //   const getBrands = async () => {
  //     try {
  //       setLoading(true);
  //       const res = await axiosCarvenui.get(`/brand?page_size=24`);
  //       const data = res.data.results;
  //       setBrands(data);
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   };
  //   getBrands();
  // }, []);
  return (
    !loading && (
      <StyledSection showMore={showMore} language={language}>
        <div className="section-header" id="header">
          {isMobile ? t.brands.mobileTitle : t.brands.title}
        </div>
        <div className="brands">
          {brands
            .slice(0, showMore ? brands.length : isMobile ? 4 : 8)
            .map((brand) => (
              <Link to="cars" state={{ brand: brand.id }} className="brand">
                <BrandCard brand={brand} key={brand.id} />
              </Link>
            ))}
        </div>
        <div className="show-more">
          {brands.length > 8 && (
            <button
              onClick={() => {
                if (showMore) {
                  const element = document.getElementById(`header`);
                  if (element) {
                    // 👇 Will scroll smoothly to the top of the next section
                    element.scrollIntoView({
                      // behavior: "smooth",
                      // block: "nearest",
                      // inline: "start",
                    });
                  }
                }
                setShowMore(!showMore);
              }}
            >
              <Down />
              <p> {!showMore ? t.showMore : t.showLess}</p>
            </button>
          )}
        </div>
      </StyledSection>
    )
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  .brands {
    margin-top: 2.222vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2.222vw 1.944vw;
  }
  .show-more {
    margin-top: 2.361vw;
    width: 100%;
    display: flex;
    justify-content: center;
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.556vw;
      background: none;
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-style: normal;
        font-weight: 700;
        font-size: 1.389vw;
        line-height: 1.458vw;
        color: #154cd6;
        @media (min-width: 1440px) {
          font-size: 20px;
          line-height: 21px;
        }
      }
    }
    svg {
      width: 1.667vw;
      height: 1.667vw;
      -webkit-transform: ${(props) => (props.showMore ? "scaleY(-1)" : "")};
      transform: ${(props) => (props.showMore ? "scaleY(-1)" : "")};

      @media (min-width: 1440px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 ${mobileSizes._16px};
    .brands {
      margin-top: ${mobileSizes._16px};
      grid-template-columns: 1fr 1fr;
      gap: ${mobileSizes._8px} ${mobileSizes._8px};
    }
    .show-more {
      margin-top: ${mobileSizes._16px};
      button {
        gap: 0.556vw;
        p {
          font-weight: 600;
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
      }
      svg {
        width: ${mobileSizes._24px};
        height: ${mobileSizes._24px};
      }
    }
  }
`;
export default Brands;
