import { useContext, useEffect } from "react";
import AuthContext from "./AuthContext";
import { refreshTokenHandler } from "../signin/authFunctions";
import { authApi } from "../../../util/integration";
import { jwtDecode } from "jwt-decode";
import toast from 'react-hot-toast';

function Interceptor({ children }) {

    const { loginHandler, logoutHandler } = useContext(AuthContext);
    

    useEffect(() => {
        const interceptor = authApi.interceptors.request.use(
            async (config) => {
                try {
                    const accessToken = localStorage.getItem('access_token');
                    const refreshToken = localStorage.getItem('refresh_token');
                    if (accessToken && refreshToken) {
                        const currentDecoded = jwtDecode(accessToken);           
                        const { access, refresh } = await refreshTokenHandler(
                            accessToken,
                            refreshToken,
                            currentDecoded
                        ) || {};
                        if (!access || !refresh) {
                            throw new Error("Error in refresh token");
                        }

                        config.headers.Authorization = `Bearer ${access}`;
                        loginHandler({ accessToken: access, refreshToken: refresh });
                    }
                } catch (error) {
                    console.log("Error from Interceptor", error)
                    toast.error('Error happen, try later!')
                    logoutHandler();
                }
                return config
            },
            (error) => {
                logoutHandler();
                toast.error('Error happen, try later!')
                return Promise.reject(error);
            },
        );

        return () => {
            authApi.interceptors.request.eject(interceptor);
        };
    }, [loginHandler, logoutHandler]);

    return <>{children}</>;
}

export default Interceptor;