import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { fake_car as car } from "../../pages/Car";
import PrevNextPagination from "../assets/PrevNextPagination";
import Paginaion from "../assets/PrevNextPagination";
import CarCard from "./CarCard";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const SpecialCars = ({ title, cars, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  // const cars = [car, car, car];
  return (
    <StyledSection language={language} >
      <div className="header">
        <div className="section-header">{title}</div>
        <div className="desktop-only"> 
        <PrevNextPagination />
        </div>
      </div>
      <div className="cars">
        {cars.map((car_detail) => (
          <Link to={"/car/" + car_detail.car.id}>
            <CarCard car_detail={car_detail} homePage={props.homePage} />
          </Link>
        ))}
      </div>
    </StyledSection>
  );
};


const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.222vw;
  }
  .cars {
    display: flex;
    align-items: center;
    gap: 1.944vw;
  }

  @media (max-width: 768px) {
    .header {
      margin-bottom: 4.267vw;
      margin-right: 0;
      margin-left : 4.267vw;
    }
    .cars {
      gap: 3.2vw;
      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      // height: 69.867vw;
      height: 86.133vw;
      align-items: flex-start;
    }
    .cars::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;


// const StyledSection = styled.div`
//   .header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin-bottom: 2.222vw;
//   }
//   .cars {
//     display: flex;
//     align-items: center;
//     gap: 1.944vw;
//   }

//   @media (max-width: 768px) {
//     .header {
//       margin-bottom: 4.267vw;
//       margin-right: 4.267vw;
//     }
//     .cars {
//       gap: 3.2vw;
//       overflow-y: scroll;
//       -ms-overflow-style: none; /* Internet Explorer 10+ */
//       scrollbar-width: none; /* Firefox */
//       // height: 69.867vw;
//       height: 86.133vw;
//       align-items: flex-start;
//     }
//     .cars::-webkit-scrollbar {
//       display: none; /* Safari and Chrome */
//     }
//   }
// `;
export default SpecialCars;
