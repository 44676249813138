import React, { useContext, useEffect } from "react";
import { StyledContainer } from "./Account";
import AccTitle from "../../Components/MyAccount/AccTitle";
import { FormRow, Input } from "../../Components/MyAccount/FormRow";
import styled from "styled-components";
import Button from "../../Components/MyAccount/Button";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { useNavigate } from "react-router";
import AuthContext from "../../hooks/Auth/providers/AuthContext";
import { useGetBanks, useUpdateFinancialInfo } from "../../hooks/AccountHooks";
import SelectDropdown from "../../Components/MyAccount/SelectDropdown";

function Expenses() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { updateFinancialInfo, isLoading } = useUpdateFinancialInfo();
  const { getBanks, data: banks, isFetching } = useGetBanks();

  const { register, formState, handleSubmit, control } = useForm({
    defaultValues: user?.extra_details?.financial_info,
  });

  useEffect(() => {
    getBanks();
  }, []);

  const { errors, isDirty } = formState;

  const onSubmit = data => {
    updateFinancialInfo(data);
  };

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <AccTitle title={t.account.expenses} description={t.account.expensesDesc} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3>{t.account.workInfo}</h3>
          <FormRowContainer>
            <Controller
              name="work_type"
              control={control}
              rules={{ required: t.thisFieldIsRequired }}
              render={({ field }) => (
                <SelectDropdown
                  label={t.account.workType}
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { value: "government", label: t.account.government },
                    { value: "private", label: t.account.private },
                  ]}
                  errMessage={errors?.work_type?.message}
                />
              )}
            />
            <Controller
              name="retired"
              control={control}
              rules={{ required: t.thisFieldIsRequired }}
              render={({ field }) => (
                <SelectDropdown
                  label={t.account.retired}
                  value={field.value}
                  onChange={field.onChange}
                  options={[
                    { value: "false", label: t.no },
                    { value: "true", label: t.yes },
                  ]}
                  errMessage={errors?.retired?.message}
                />
              )}
            />

            <FormRow label={t.account.WorkDuration} error={errors?.work_duration?.message}>
              <Input
                type="text"
                placeholder={t.account.enterYourWorkDuration}
                {...register("work_duration", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.salary} legend={t.sar} error={errors?.salary?.message}>
              <Input
                type="text"
                placeholder={t.account.enterSalary}
                {...register("salary", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>

            <Controller
              name="payrol_bank"
              control={control}
              rules={{ required: t.thisFieldIsRequired }}
              render={({ field }) => (
                <SelectDropdown
                  isLoading={isFetching}
                  label={t.account.payrollBank}
                  value={field.value}
                  onChange={field.onChange}
                  options={banks?.results?.map(bank => ({
                    value: bank.id,
                    label: bank.name,
                  }))}
                  errMessage={errors?.payrol_bank?.message}
                />
              )}
            />
          </FormRowContainer>
        </div>
        <div>
          <h3>{t.account.financialInfo}</h3>
          <FormRowContainer>
            <FormRow label={t.account.foodExpense} legend={t.sar} error={errors?.food_expense?.message}>
              <Input
                type="text"
                placeholder={t.account.enterFoodExpense}
                {...register("food_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.wagesForDomestics} legend={t.sar} error={errors?.wages_for_domestics?.message}>
              <Input
                type="text"
                placeholder={t.account.enterWagesForDomestics}
                {...register("wages_for_domestics", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.housingRent} legend={t.sar} error={errors?.housing_rent?.message}>
              <Input
                type="text"
                placeholder={t.account.enterHousingRent}
                {...register("housing_rent", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.educationExpense} legend={t.sar} error={errors?.education_expense?.message}>
              <Input
                type="text"
                placeholder={t.account.enterEducationExpense}
                {...register("education_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.healthCareExpense} legend={t.sar} error={errors?.health_care_expense?.message}>
              <Input
                type="text"
                placeholder={t.account.enterHealthCareExpense}
                {...register("health_care_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow
              label={t.account.transportAndCommunicationExpense}
              legend={t.sar}
              error={errors?.transport_and_communication_expense?.message}
            >
              <Input
                type="text"
                placeholder={t.account.enterTransportAndCommunicationExpense}
                {...register("transport_and_communication_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.insuranceExpense} legend={t.sar} error={errors?.insurance_expense?.message}>
              <Input
                type="text"
                placeholder={t.account.enterInsuranceExpense}
                {...register("insurance_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.otherExpense} legend={t.sar} error={errors?.other_expense?.message}>
              <Input
                type="text"
                placeholder={t.account.enterOtherExpense}
                {...register("other_expense", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
          </FormRowContainer>
        </div>
        <div>
          <h3>{t.account.loansInfo}</h3>
          <FormRowContainer>
            <FormRow label={t.account.homeFinance} legend={t.sar} error={errors?.home_finance?.message}>
              <Input
                type="text"
                placeholder={t.account.enterHomeFinance}
                {...register("home_finance", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.personalFinance} legend={t.sar} error={errors?.personal_finance?.message}>
              <Input
                type="text"
                placeholder={t.account.enterPersonalFinance}
                {...register("personal_finance", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow
              label={t.account.socialBankOthers}
              legend={t.sar}
              error={errors?.social_bank_and_other_field?.message}
            >
              <Input
                type="text"
                placeholder={t.account.enterSocialBankOthers}
                {...register("social_bank_and_other_field", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.autoLease} legend={t.sar} error={errors?.auto_lease?.message}>
              <Input
                type="text"
                placeholder={t.account.enterAutoLease}
                {...register("auto_lease", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
            <FormRow label={t.account.creditCards} legend={t.sar} error={errors?.credit_card?.message}>
              <Input
                type="text"
                placeholder={t.account.enterCreditCards}
                {...register("credit_card", {
                  required: t.thisFieldIsRequired,
                })}
              />
            </FormRow>
          </FormRowContainer>
        </div>
        <ButtonGroup>
          <Button isLoading={isLoading} disabled={!isDirty || isLoading}>
            {t.account.updateAccount}
          </Button>
          <Button onClick={() => navigate(-1)} type="button" variation="secondary">
            {t.cancel}
          </Button>
        </ButtonGroup>
      </FormContainer>
    </StyledContainer>
  );
}

export default Expenses;

const FormRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  @media (max-width: 966px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 0;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 20px auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.2rem;
`;
