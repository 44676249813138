import React, { useEffect, useState, useContext } from "react";
import { axiosCarvenui } from "../../util/integration";
import { Link, useLocation } from "react-router-dom";
import Form from "../assets/Form";
import history from "../../util/history";
import logo from "../../images/bmw.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import FinanceDetailsModal from "./Modal/FinanceDetailsModal";
import AuthContext from "../../hooks/Auth/providers/AuthContext";

const CarDetailsForm = ({ next,  setCar_detail, calculate }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [materialColors, setMaterialColors] = useState([]);
  const [years, setYears] = useState([]);
  const [models, setModels] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [carDetails, setCarDetails] = useState([]);
  const [reachedInput, setReachedInput] = useState(1);
  const [tempData, setTempData] = useState({});
  const [nextBrands, setNextBrands] = useState("");
  const [nextModels, setNextModels] = useState("");
  const [nextLoading, setNextLoading] = useState(false);
  const location = useLocation();

  const { user } = useContext(AuthContext);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const state = location.state;
    if (state?.calcData && brands.length > 0) {
      setTempData({
        model: state.calcData.model,
        year: state.calcData.year,
        car_color: state.calcData.car_color,
      });
      let found_barnd = brands.find((b) => b.id == state.calcData.brand);
      if (found_barnd) {
        setFormData({
          brand: state.calcData.brand,
        });
        history.replace("/car-finance");
      } else {
        getNextBrands();
      }
    }
  }, [location.state, brands, nextBrands]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoading(true);
        const res = await axiosCarvenui.get(`/brand/?page_size=50`);
        const data = res.data.results.map((brand) => {
          return {
            ...brand,
            value: brand.id,
          };
        });
        setBrands(data);
        setLoading(false);
        setNextBrands(res.data.next);
      } catch (error) {
        console.log(error);
      }
    };
    getBrands();
  }, []);

  const searchBrand = async (value) => {
    const res = await axiosCarvenui.get("/brand?per_page=50&name__icontains=" + value);
    const data = res.data.results.map((brand) => {
      return {
        ...brand,
        value: brand.id,
      };
    });
    setBrands(data);
    setNextBrands(res.data.next);
  };

  const clearData = () => {
    setColors([]);
    setMaterialColors([]);
    setMaterials([]);
    setYears([]);
    setSellers([]);
    setReachedInput(2);
  };

  useEffect(() => {
    const getModels = async () => {
      try {
        const res = await axiosCarvenui.get(`car_model/?brand=${formData.brand}&page_size=50`);
        const data = res.data.results.map((model) => {
          return {
            ...model,
            value: model.id,
          };
        });
        setModels(data);
        setNextModels(res.data.next);
      } catch (e) {}
    };
    if (formData.brand) {
      setModels([]);
      getModels();
      setReachedInput(2);
      if (!tempData.model) {
        setFormData((prev) => ({ brand: prev.brand }));
        clearData();
      }
    }
  }, [formData.brand]);

  useEffect(() => {
    if (models.length > 0) {
      if (models.length == 1) {
        setFormData({ ...formData, model: models[0].value });
      }
      if (tempData.model) {
        let found_model = models.find((m) => m.id == tempData.model);
        if (found_model) {
          setFormData({ ...formData, model: tempData.model });
          setTempData((prev) => ({
            year: prev.year,
            car_color: prev.car_color,
          }));
        } else {
          getNextModels();
        }
      }
    }
  }, [models, nextModels]);

  const getNextBrands = async () => {
    if (nextBrands !== "" && nextBrands !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextBrands.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setBrands([
        ...brands,
        ...res.data.results.map((brand) => ({
          ...brand,
          value: brand.id,
        })),
      ]);
      setNextBrands(res.data.next);
      setNextLoading(false);
    }
  };

  const getNextModels = async () => {
    if (nextModels !== "" && nextModels !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextModels.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setModels([
        ...models,
        ...res.data.results.map((model) => ({
          ...model,
          value: model.id,
        })),
      ]);
      setNextModels(res.data.next);
      setNextLoading(false);
    }
  };

  useEffect(() => {
    if (!location.state) {
      const getCarDetails = async () => {
        try {
          let res;
          if (formData.brand) {
            res = await axiosCarvenui.get(`/car_detail/?car__model=${formData.model}`);
          }

          if (res) {
            let details = res.data.results;
            let yearsArr = [];
            setCarDetails(details);
            details.map((d) => {
              yearsArr.push({ name: d.car?.year, value: d.car?.year });
            });
            setYears([...new Map(yearsArr.map((y) => [y.name, y])).values()]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      setYears([]);
      getCarDetails();
      setReachedInput(3);
      if (!tempData.year) {
        setFormData((prev) => ({
          brand: prev.brand,
          model: prev.model,
        }));
        setColors([]);
        setMaterialColors([]);
        setMaterials([]);
      }
    }
  }, [formData.model]);

  useEffect(() => {
    if (formData.year) {
      const filterdDetails = carDetails.filter((d) => d.car.year === formData.year);
      // setCarDetails(filterdDetails);
      let colors_arr = filterdDetails.map((d) => {
        return {
          name: d.car_color,
          value: d.car_color,
          name_ar: ar.colors[d.car_color.toLowerCase()],
        };
      });

      setColors([...new Map(colors_arr.map((c) => [c.name, c])).values()]);

      setReachedInput(4);
      setMaterialColors([]);
      setMaterials([]);
      if (!tempData.car_color) {
        setFormData((prev) => ({
          brand: prev.brand,
          model: prev.model,
          year: prev.year,
        }));
      }
    }
  }, [formData.year]);

  useEffect(() => {
    if (years.length > 0) {
      if (years.length == 1) {
        setFormData({ ...formData, year: years[0].value });
      }
      if (tempData.year) {
        setFormData({ ...formData, year: tempData.year });
        setTempData((prev) => ({ car_color: prev.car_color }));
      }
    }
  }, [years]);

  useEffect(() => {
    if (formData.car_color) {
      const filterdDetails = carDetails.filter(
        (d) => d.car.year === formData.year && d.car_color === formData.car_color
      );
      // setCarDetails(filterdDetails);

      let materials_arr = filterdDetails.map((d) => {
        return {
          name: d.interior_fabric,
          value: d.interior_fabric,
          name_ar: ar.materials[d.interior_fabric.toLowerCase()],
        };
      });

      setMaterials([...new Map(materials_arr.map((m) => [m.name, m])).values()]);
      setReachedInput(5);
      setMaterialColors([]);
      setFormData((prev) => ({
        brand: prev.brand,
        model: prev.model,
        year: prev.year,
        car_color: prev.car_color,
      }));
    }
  }, [formData.car_color]);

  useEffect(() => {
    if (colors.length > 0) {
      if (colors.length == 1) {
        setFormData({ ...formData, car_color: colors[0].value });
      }
      if (tempData.car_color) {
        setFormData({ ...formData, car_color: tempData.car_color });
        setTempData({});
      }
    }
  }, [colors]);

  useEffect(() => {
    if (formData.interior_fabric) {
      const filterdDetails = carDetails.filter(
        (d) =>
          d.car.year === formData.year &&
          d.car_color === formData.car_color &&
          d.interior_fabric == formData.interior_fabric
      );

      // setCarDetails(filterdDetails);

      let materialsColors_arr = filterdDetails.map((d) => {
        return {
          name: d.interior_color,
          value: d.interior_color,
          name_ar: ar.colors[d.interior_color.toLowerCase()],
        };
      });

      setMaterialColors([...new Map(materialsColors_arr.map((c) => [c.name, c])).values()]);
      setReachedInput(6);
      setFormData((prev) => ({
        brand: prev.brand,
        model: prev.model,
        year: prev.year,
        car_color: prev.car_color,
        interior_fabric: prev.interior_fabric,
      }));
    }
  }, [formData.interior_fabric]);

  useEffect(() => {
    if (materials.length == 1) {
      setFormData({ ...formData, interior_fabric: materials[0].value });
    }
  }, [materials]);
  useEffect(() => {

    if (formData.year && formData.interior_color && formData.car_color && formData.interior_fabric) {

      const getCarDetails = async () => {
        try {
          // const res = await axiosCarvenui.get(
          //   `/car_detail/?car__model=${formData.model}&car__year=${formData.year}&car_color__iexact=${formData.car_color}&interior_color__iexact=${formData.interior_color}&interior_fabric__iexact=${formData.interior_fabric}`
          // );

          // if (res) {
          //   let details = res.data.results;
          //   setCarDetails(details);
          const filterdDetails = carDetails.filter(
            (d) =>
              d.car.year === formData.year &&
              d.car_color === formData.car_color &&
              d.interior_fabric == formData.interior_fabric &&
              d.interior_color == formData.interior_color
          );

          let sellersArr = filterdDetails.map((d) => {
            return { ...d.seller, value: d.seller?.name };
          });
          console.log("sellers", sellersArr);
          setSellers([...new Map(sellersArr.map((s) => [s.name, s])).values()]);
          if (sellersArr.length == 1) {
            setFormData({ ...formData, seller: sellersArr[0].value });
          }
          setReachedInput(7);
        } catch (e) {}
      };
      setFormData({ ...formData, seller: "" });
      getCarDetails();
    }
  }, [formData.interior_color]);

  useEffect(() => {
    if (materialColors.length == 1) {
      setFormData({ ...formData, interior_color: materialColors[0].value });
    }
  }, [materialColors]);

  useEffect(() => {
    const targetDetail = carDetails.find(
      (d) =>
        d.car.year === formData.year &&
        d.car_color === formData.car_color &&
        d.interior_fabric == formData.interior_fabric &&
        d.interior_color == formData.interior_color &&
        d.seller.name === formData.seller
    );
    setCar_detail(targetDetail);
  }, [formData.seller]);

  useEffect(() => {
    setCar_detail();
  }, [carDetails]);

  const brand_field = {
    name: "brand",
    label: t.carFinance.carDetails.brand,
    displayName: t.carFinance.carDetails.brand,
    type: "select",
    options: brands,
    getPaginationData: getNextBrands,
    // disableArabic: true,
    allowTyping: true,
    search: searchBrand,
  };
  const model_field = {
    name: "model",
    label: t.carFinance.carDetails.model,
    displayName: t.carFinance.carDetails.model,
    type: "select",
    options: models,
    disabled: reachedInput < 2,
    getPaginationData: getNextModels,
    // disableArabic: true,
  };

  const year_field = {
    name: "year",
    label: t.carFinance.carDetails.year,
    displayName: t.carFinance.carDetails.year,
    type: "select",
    options: years,
    disabled: reachedInput < 3,
    disableArabic: true,
  };

  const color_field = {
    name: "car_color",
    label: t.carFinance.carDetails.color,
    displayName: t.carFinance.carDetails.color,
    type: "select",
    options: colors,
    disabled: reachedInput < 4,
  };

  const material_field = {
    name: "interior_fabric",
    label: t.carFinance.carDetails.interiorMaterial,
    displayName: t.carFinance.carDetails.interiorMaterial,
    type: "select",
    options: materials,
    disabled: reachedInput < 5,
  };

  const materialColor_field = {
    name: "interior_color",
    label: t.carFinance.carDetails.materialColor,
    displayName: t.carFinance.carDetails.materialColor,
    type: "select",
    options: materialColors,
    disabled: reachedInput < 6,
  };

  const seller_field = {
    name: "seller",
    label: t.carFinance.carDetails.seller,
    displayName: t.carFinance.carDetails.seller,
    type: "select",
    options: sellers,
    disabled: reachedInput < 7,
  };

  const fields = [brand_field, model_field, year_field, color_field, material_field, materialColor_field, seller_field];

  return (
    <>
      <Form
        formData={formData}
        setFormData={setFormData}
        fields={fields}
        onSubmit={() => {

          if (user?.extra_details?.financial_info) {
            setModalOpen(true);
          } else {
            next();
          }
        }}
        submitText={t.next}
      />
      <FinanceDetailsModal next={next} calculate={calculate} open={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default CarDetailsForm;
