import { useContext, useState } from "react";
import { authApi } from "../util/integration";
import toast from "react-hot-toast";
import ar from "../util/locales/ar";
import en from "../util/locales/en";
import { useSelector } from "react-redux";
import AuthContext from "./Auth/providers/AuthContext";

export const useUpdateAccount = id => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const { setUser, user } = useContext(AuthContext);

  const updateAccountInfo = async data => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await authApi.put(`/users/edit_personal_info/`, data);
      console.log("response:",response)
      const updatedUserData = { ...user, ...data }; 
      setData(updatedUserData);
      toast.success(t.account.accountUpdated);
      setUser(updatedUserData);
    } catch (err) {
      console.log(err);
      setError(err.message);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, data, error, updateAccountInfo };
};

export const useUpdateFinancialInfo = id => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { setUser } = useContext(AuthContext);

  const updateFinancialInfo = async data => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await authApi.post(`/users/financial_info/`, data);
      setData(response.data);
      toast.success("Financial Info Updated");
      setUser(prevUser => ({
        ...prevUser,
        extra_details: { ...prevUser.extra_details, financial_info: data },
      }));
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, data, error, updateFinancialInfo };
};

export const useGetBanks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const getBanks = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await authApi.get(`/bank/`);
      setData(response.data);
    } catch (err) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { isFetching: isLoading, data, error, getBanks };
};

export const useUpdatePassword = id => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const updatePassword = async data => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await authApi.post(`/users/change_password/`, data);
      setData(response.data);
      toast.success(t.account.passwordUpdated);
    } catch (err) {
      console.log(err);
      setError(err.message);
      toast.error(err?.response?.data?.detail);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, data, error, updatePassword };
};
