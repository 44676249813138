import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import select_arrow_black from "../../images/select-arrow.svg";
import select_arrow_gray from "../../images/select-arrow-gray.svg";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { isArabic } from "./Form";
import { useForm } from "react-hook-form";

const SelectMenu = ({
  field,
  disabled,
  setValue,
  value,
  inputFont = "Poppins",
  inputMobileFont = "Poppins",
  search = (value) => null,
  allowTyping = false,
  getPaginationData = () => null,
  registerObj,
  ...props
}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const checkIsMobile = () => window.innerWidth <= 768;
  const [selectedOption, setSelectedOption] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [mode, setMode] = useState("select");
  const [searchValue, setSearchValue] = useState();
  const menuRef = useRef();
  const options = field.options;
  const optionRefs = useRef(options.map(() => React.createRef()));
  const { register } = useForm();
  const [focusedIndex, setFocusedIndex] = useState(0);
  const font = isMobile ? inputMobileFont : inputFont;
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    const handleOverflow = () => {
      const container = document.getElementById("select-menu");
      if (container) {
        setHasOverflow(container.scrollHeight > container.clientHeight);
      }
    };

    handleOverflow(); // Check overflow on component mount

    window.addEventListener("resize", handleOverflow);
    return () => {
      window.removeEventListener("resize", handleOverflow);
    };
  }, [showMenu]);

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      const selectedOption = field.options[focusedIndex];
      if (selectedOption) {
        setValue(selectedOption.value);
      }
      setSelectedOption(selectedOption);
      setShowMenu(!showMenu);
    }
  };

  useEffect(() => {
    if (searchValue !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        search(searchValue);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);

  const vwToPx = (vw) => {
    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth;
    return (vw * screenWidth) / 100;
  };

  useEffect(() => {
    if (field.name == "code") {
      if (!value && options.length > 0) {
        setValue(options[0].value);
        setSelectedOption(options[0]);
      }
    }
  }, [options]);

  const onScroll = () => {
    // console.log("scroll");

    if (menuRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuRef.current;
      const optionHeight =
        (scrollHeight - vwToPx(options.length * 0.556)) / options.length;
      setFocusedIndex(Math.round(scrollTop / optionHeight));
      if (scrollTop + clientHeight > scrollHeight - 5) {
        getPaginationData(value);
        console.log("Reached bottom");
      }
    }
  };

  useEffect(() => {
    const selected = field.options.find((option) => {
      return option.value === value;
    });
    setSelectedOption(selected);
    if (value || !showMenu) {
      // console.log("index", options.indexOf(selected));
      setFocusedIndex(options.indexOf(selected));
    }
  }, [value, field, showMenu]);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    optionRefs.current = optionRefs.current.slice(0, options.length);
  }, [options]);

  // useEffect(() => {
  //   console.log("focued", focusedIndex);
  //   if (optionRefs) {
  //     optionRefs?.current[focusedIndex]?.current.scrollIntoView({
  //       // behavior: "smooth",
  //       block: "nearest",
  //       // inline: "start",
  //     });
  //   }
  // }, [focusedIndex]);

  // useEffect(() => {
  //   if (showMenu && menuRef.current) {
  //     menuRef.current.focus();
  //     optionRefs?.current[focusedIndex]?.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "nearest",
  //       inline: "center",
  //     });
  //   }
  // }, [showMenu, focusedIndex]);

  useEffect(() => {
    if (showMenu && menuRef.current) {
      // menuRef.current.focus();
      const optionHeight =
        (menuRef.current.scrollHeight - vwToPx(options.length * 0.556)) /
        options.length;
      menuRef.current.scrollTop = focusedIndex * optionHeight;
    }
  }, [showMenu, focusedIndex]);

  return (
    <StyledSelect
      language={language}
      disableArabic={field.disableArabic}
      {...props}
      onKeyDown={(e) => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          if (showMenu && menuRef.current) {
            menuRef.current.focus();
          }
        }
      }}
    >
      <div
        className="select-input-div"
        onClick={() => {
          if (!disabled) {
            if (allowTyping) {
              setShowMenu(true);
            } else {
              setShowMenu(!showMenu);
            }
          }
        }}
      >
        {field.placeholder && selectedOption && (
          <label for={field.id}>{field.placeholder}</label>
        )}

        <input
          style={
            field.placeholder && selectedOption
              ? {
                  backgroundImage: selectedOption
                    ? `url(${select_arrow_black})`
                    : `url(${select_arrow_gray})`,
                  paddingTop: !isMobile ? "1.944vw" : "10.256vw",
                  paddingBottom: !isMobile ? "0.833vw" : "4.872vw",
                  backgroundPositionY: "68%",
                  fontFamily:
                    language == "en" || field.disableArabic
                      ? font
                      : "GE-SS-Meduim",
                }
              : {
                  backgroundImage: field.placeholder
                    ? `url(${select_arrow_gray})`
                    : `url(${select_arrow_black})`,
                  fontFamily:
                    language == "en" || field.disableArabic
                      ? font
                      : "GE-SS-Meduim",
                }
          }
          type="text"
          autocomplete="off"
          {...field.other}
          name={field.name}
          disabled={disabled}
          placeholder={
            field.placeholder
              ? field.placeholder
              : !value
              ? language == "en"
                ? "-Select-"
                : "-اختر-"
              : ""
          }
          required={field.required === false ? false : true}
          id="input-field"
          onInput={(e) => {
            if (allowTyping) {
              setSearchValue(e.target.value);
              setValue(null);
              setMode("typing");
            }
          }}
          registerObj
          value={
            mode == "typing"
              ? searchValue
              : selectedOption
              ? language == "en" || field.disableArabic
                ? selectedOption?.name
                : selectedOption.name_ar || selectedOption.name
              : ""
          }
          onBlur={() => {
            setTimeout(() => {
              if (
                document.activeElement.className !== "option" &&
                document.activeElement.id !== "select-menu"
              ) {
                setShowMenu(false);
                // setMode("select");
              }
            });
          }}
        />

        {field.name == "code" && (
          <div className="code">
            <img
              className="flag"
              src={
                selectedOption ? selectedOption?.flag : field.options[0]?.flag
              }
            />
            <div className="value">
              {selectedOption ? selectedOption?.name : field.options[0]?.name}{" "}
            </div>
          </div>
        )}
      </div>

      {showMenu && !disabled && (
        <div className="menu-container">
          <StyledMenu
            language={language}
            disableArabic={field.disableArabic || field.name == "code"}
            key={field.name}
            ref={menuRef}
            onScroll={onScroll}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            className={`select-menu ${hasOverflow ? "custom-scrollbar" : ""}`}
            id="select-menu"
          >
            {field.options.map((option, i) => (
              <div
                key={option.value}
                className="option"
                id={`option-${i}`}
                tabindex={-1}
                onClick={() => {
                  setValue(option.value);
                  setSelectedOption(option);
                  setShowMenu(!showMenu);
                  setMode("select");
                }}
                ref={optionRefs.current[i]}
              >
                <div className="space"></div>
                {field.name == "code" && (
                  <img className="flag" src={option.flag} />
                )}
                {field.name == "brand" && (
                  <img className="logo" src={option.logo} />
                )}

                {field.isColor && (
                  <div
                    className="color-circle"
                    style={{
                      background: option.value,
                      border:
                        option.value.toLowerCase() == "white"
                          ? "1px solid #E4E6E8"
                          : 0,
                    }}
                  ></div>
                )}

                <label className={`option-name ${field.name}-option`}>
                  {language == "en" || field.disableArabic
                    ? option.name
                    : option.name_ar || option.name}
                </label>
              </div>
            ))}
          </StyledMenu>
        </div>
      )}
    </StyledSelect>
  );
};

const StyledSelect = styled.div`
  .select-input-div {
    position: relative;
    label {
      position: absolute;
      top: 0.556vw;

      left: ${(props) => props.language == "en" && "1.389vw"};

      right: ${(props) => props.language == "ar" && " 1.389vw"};
      font-family: ${(props) =>
        props.language == "en" ? "Inter" : "GE-SS-Meduim"};
      font-weight: ${(props) => (props.language == "en" ? "400" : "500")};
      font-size: 0.833vw;
      line-height: 1.25vw;
      color: #9a9ea7;

      @media (min-width: 1440px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    input {
      font-family: ${(props) =>
        props.language == "en" || props.disableArabic
          ? "Poppins"
          : "GE-SS-Meduim"};
      font-style: normal;
      font-weight: 500;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background-repeat: no-repeat;
      background-image: url(${select_arrow_black});
      background-position-x: 91%;
      background-position-y: 50%;
      width: ${(props) => (props.inputWidth ? props.inputWidth : "auto")};
      cursor: pointer;
    }
    .code {
      direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
      position: absolute;
      left: ${(props) => props.language == "en" && "0"};
      right: ${(props) => props.language == "ar" && "0"};
      top: 0;
      display: flex;
      align-items: center;
      gap: 0.278vw;
      .flag {
        width: 2.778vw;
        height: 1.528vw;
        object-fit: contain;
        border-radius: ${desktopSizes._2px};
      }
      .value {
        direction: ltr;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;

        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .menu-container {
    height: 0;
  }

  @media (max-width: 768px) {
    .select-input-div {
      label {
        top: ${mobileSizes._16px};
        left: 0;
        font-size: 3.2vw;
        line-height: 4.615vw;
      }
      input {
        background-position-x: 98%;
        background-position-y: 50%;
      }
      .code {
        gap: ${mobileSizes._4px};
        ${mobileSizes._4px};
        .flag {
          width: ${mobileSizes._40px};
          height: ${mobileSizes._21px};
          border-radius: ${mobileSizes._2px};
        }
        .value {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._21px};
        }
      }
    }
  }
`;

const StyledMenu = styled.div`
  // position: relative;
  &:focus {
    outline: none;
  }
  &[tabindex]:focus {
    outline: none;
  }
  height: auto;
  max-height: 16.875vw;
  overflow-x: hidden;
  z-index: 10000;
  top: 0.278vw;
  display: grid;
  gap: 0.556vw;
  position: relative;
  background: #ffffff;
  border: 0.069vw solid #d9e1e7;
  box-shadow: 0 0.278vw 0.486vw rgba(0, 0, 0, 0.06);
  border-radius: 0.556vw;
  // padding: 1.111vw 0;

  &.custom-scrollbar {
    scrollbar-color: blue #f3efe9;
    overflow-y: scroll;
  }
  /* Customizing scroll bar */
  &.custom-scrollbar::-webkit-scrollbar {
    width: 0.55vw;
    height: 1vw;
    @media (max-width: 768px) {
      width: 1.7vw;
    }
  }

  /* Track */
  &.custom-scrollbar::-webkit-scrollbar-track {
    background: #f3efe9;
    border-radius: 1vw;
    @media (max-width: 768px) {
      border-radius: 3vw;
    }
  }

  /* Handle */
  &.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #154cd6;
    border-radius: 1vw;
    @media (max-width: 768px) {
      border-radius: 3vw;
    }
  }

  /* Handle on hover */
  &.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    /* background: #154CD6; */
  }

  .option {
    outline: none;
    display: flex;
    align-items: center;
    gap: 0.556vw;
    // border-bottom: 0.069vw solid #d9e1e7;
    padding: 0.833vw 0;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    .space {
      width: ${desktopSizes._8px};
    }
    .option-name {
      direction: ${(props) =>
        props.language == "en" || (props.disableArabic && "ltr")};
      font-family: ${(props) =>
        props.language == "en" || props.disableArabic
          ? "Poppins"
          : "GE-SS-Meduim"};
      font-style: normal;
      font-weight: 500;
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #12141d;
      margin: 0;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
    .payrol_bank-option {
      font-family: "Poppins";
    }
    .flag {
      width: 2.083vw;
      height: 1.389vw;
      object-fit: contain;
      border-radius: ${desktopSizes._2px};
      @media (min-width: 1440px) {
        width: 30px;
        height: 20px;
        border-radius: ${mobileSizes._2px};
      }
    }
    .logo {
      width: 2.222vw;
      height: 1.25vw;
      object-fit: contain;
      @media (min-width: 1440px) {
        width: 32px;
        height: 18px;
      }
    }
    .color-circle {
      width: ${desktopSizes._16px};
      height: ${desktopSizes._16px};
      border-radius: 50%;

      @media (min-width: 1440px) {
        width: 16px;
        height: 16px;
      }

      @media (max-width: 768px) {
        width: ${mobileSizes._16px};
        height: ${mobileSizes._16px};
      }
    }
  }
  .option:last-child {
    border: none;
    // padding-bottom: 0;
  }

  @media (max-width: 768px) {
    top: ${mobileSizes._4px};
    gap: 2.051vw;
    border-radius: 2.051vw;
    padding: 3.077vw 0;
    max-height: 54.615vw;
    box-shadow: 0 1.026vw 1.795vw rgba(0, 0, 0, 0.06);
    .option {
      .space {
        width: ${mobileSizes._12px};
      }
      border: 0;
      padding: 1.333vw 0;
      gap: ${mobileSizes._6px};
      .option-name {
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._21px};
      }
      .flag {
        width: ${mobileSizes._30px};
        height: ${mobileSizes._21px};
      }
      .logo {
        width: ${mobileSizes._32px};
        height: ${mobileSizes._18px};
        object-fit: contain;
      }
    }
  }
`;

export default SelectMenu;
