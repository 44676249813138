import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { ReactComponent as Next } from "../../images/next.svg";
import { ReactComponent as Prev } from "../../images/prev.svg";
import styled from "styled-components";

function Pagination({ totalItems, pageSize, currentPage = 1, onPageChange }) {
  const location = useLocation();
  const navigate = useNavigate();
  if (!totalItems || totalItems <= pageSize) return null;
  const pagesNum = Math.ceil(totalItems / pageSize);

  const getPageNumbers = () => {
    const pages = [];
    if (pagesNum <= 5) {
      for (let i = 1; i <= pagesNum; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", pagesNum);
      } else if (currentPage >= pagesNum - 2) {
        pages.push(1, "...", pagesNum - 3, pagesNum - 2, pagesNum - 1, pagesNum);
      } else {
        pages.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...", pagesNum);
      }
    }
    return pages;
  };

  const handlePageClick = (page) => {
    if (page === "...") return;
    onPageChange(page);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", currentPage);

    navigate(`${location.pathname}?${searchParams.toString()}`);
    onPageChange(page);
  };

  return (
    <StyledPage>
      <div className="pagination-container">
        <Button
          disabled={currentPage === 1}
          className="p-2"
          onClick={() => onPageChange(currentPage - 1)}
        >
          <Prev />
        </Button>
        <div className="flex gap-2">
          {getPageNumbers().map((page, index) => {
            return page === "..." ? (
              <span key={index}>...</span>
            ) : (
              <Button
                key={index}
                className={`${parseInt(currentPage) === parseInt(page) ? "ghost" : "not-ghost"}`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </Button>
            );
          })}
        </div>
        <Button
          disabled={currentPage === pagesNum}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <Next />
        </Button>
      </div>
    </StyledPage>
  );
}

const StyledPage = styled.div`
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
  }
  .pagination-container > div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .pagination-container button{
    background: white;
    border: 1px solid #D9E1E7;
    min-width: 38px !important;
    width: 38px !important;
    height: 38px;
    transition: .3s all;
  }
  .pagination-container button:hover{
    background: #f6f5f5;
  }
  .pagination-container button.ghost{
    background: #154CD6;
    border: 1px solid #154CD6;
    color: white;
  }
`;

export default Pagination;
