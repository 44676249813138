import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonWrapper = styled.div`
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "20px"};
  background: linear-gradient(
    90deg,
    rgba(218, 218, 218, 0) 25%,
    rgba(218, 218, 218, 0.3) 50%,
    rgba(218, 218, 218, 0) 75%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  border-radius: 4px;
`;

const Skeleton = ({ width, height }) => {
  return <SkeletonWrapper width={width} height={height} />;
};

export default Skeleton;
