import { forwardRef } from "react";
import styled from "styled-components";

const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.2rem 0;
  position: relative;

  &:has(button) {
    display: flex;
    justify-content: flex-end;
    gap: 1.2rem;
  }
  span {
    position: absolute;
    inset-inline-end: 0.8rem;
    top: 3.8rem;
    background-color: white;
    color: #7a7a7a;
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: #3b4453;
  white-space: nowrap;
  overflow: hidden;
`;

const Error = styled.div`
  font-size: smaller;
  color: red;
`;

export function FormRow({ legend, label, error, children }) {
  return (
    <StyledFormRow>
      {label && <Label htmlFor={children.props.id}>{label}</Label>}
      {children}
      {legend && <span className="legend">{legend}</span>}
      {error && <Error>{error}</Error>}
    </StyledFormRow>
  );
}

export const Input = forwardRef(({ type, ...props }, ref) => {
  return <StyledInput type={type} {...props} ref={ref} />;
});

export const StyledInput = styled.input`
  border: none;
  background-color: white;
  &::placeholder {
    color: #afafaf;
  }
  border-radius: 6px;
  padding: 0.8rem 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
`;

export const StyledSelect = styled.select`
  border: none;
  background-color: white;
  border-radius: 6px;
  padding: 0.8rem 1.2rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  &:focus {
    outline: none;
    transition: all 0.5s ease;
  }
  &:disabled {
    color: #111;
    font-weight: bold;
  }

  option {
    font-size: 15px;
    background-color: #ffffff;
  }
`;
