import React, { useState, useEffect } from "react";
import styled from "styled-components";
import warranty from "../../images/warranty.svg";
import distance from "../../images/distance.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const WarrantyCoverage = ({ warrantyCovarge, brand }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [warrantyAttr, setWarrantyAttr] = useState({});

  useEffect(() => {
    if (language == "en") {
      setWarrantyAttr({
        warranty: { name: t.carPage.warranty.warranty, icon: warranty },
        engine: { name: t.carPage.warranty.distance, icon: distance },
      });
    } else {
      setWarrantyAttr({
        engine: { name: t.carPage.warranty.distance, icon: distance },
        warranty: { name: t.carPage.warranty.warranty, icon: warranty },
      });
    }
  }, [language]);
  // const warrantyAttr = {
  //   // bumper_to_bumber: { name: "Bumper-to-Bumper", icon: bumper_to_bumber },
  //   // gasoline: { name: "Gasoline", icon: gasoline },
  //   // roadside_assistance: {
  //   //   name: "Roadside Assistance",
  //   //   icon: roadside_assistance,
  //   // },
  //   // roadside_fuel: { name: "Roadside Fuel", icon: roadside_fuel },
  //   warranty: { name: t.carPage.warranty.warranty, icon: warranty },
  //   engine: { name: t.carPage.warranty.distance, icon: distance },
  // };
  return (
    <StyledSection language={language}>
      <div className="section-header">{t.carPage.warranty.title}</div>
      {/* <div className="warranty-desc">
        New {brand.toUpperCase()} cars covered by Warranty and Insurance
      </div> */}
      <div className="items">
        {Object.keys(warrantyAttr).map(
          (item) =>
            warrantyAttr[item] && (
              <div className="item">
                <div className="key">
                  <img src={warrantyAttr[item].icon} />
                  <p className="text">{warrantyAttr[item].name} </p>
                </div>
                <p className="value">{warrantyCovarge[item]}</p>
              </div>
            )
        )}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  width: 38.542vw;
  // padding-top: 5.903vw;
  // border-bottom: 0.069vw solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
  .warranty-desc {
    margin-top: 1.667vw;
    margin-bottom: 1.389vw;
    font-weight: 400;
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #0d0a19;
    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 4.267vw;
    width: auto;
    padding-bottom: 6.4vw;
    border-bottom: 0;
    .warranty-desc {
      margin-top: 2.133vw;
      margin-bottom: 4.267vw;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 4.267vw;
      line-height: 6.4vw;
      color: #5a5a5a;
    }
  }
`;
export default WarrantyCoverage;
