import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
}

button { 
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}
button:focus {outline:0;}

a { 
    text-decoration : none;
    color : #000;
}

input { 
  outline : none;
}

.margin-auto{
  margin: auto;
}


.fw400{
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw {
  width: 100% !important;
}

.align-center {
  text-align: center;
}

/* Stripe Form styles */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mobile-only{
  @media (min-width: 769px) {
  display: none !important;
  }
}

.desktop-only {
 @media (max-width: 768px) {
  display: none !important;
  }
}

`;

export default GlobalStyle;
