
import { axiosCarvenui } from '../../../util/integration';
import toast from 'react-hot-toast';

export const register_admin_handler = async ({ name, email, phonenumber, password, password2 }) => {
    try {
        const response = await axiosCarvenui.post('/users/register/', {
            name,
            email,
            phonenumber,
            password,
            password2,
        });
        console.log(response)
        toast.success("Sign Up Successfully, Now Verify You'r Account")
        return response;
    } catch (err) {
        console.error('Register failed:', err);
        const errorField = err.response?.data?.email?.[0] || err.response?.data?.phonenumber?.[0] || err.response?.data?.detail;
        toast.error(errorField)
    }
};

export const send_otp = async (phone_number) => {
    console.log("phone_number send_otp: ",phone_number)
    try {
        const response = await axiosCarvenui.post('/users/resend_otp/', {
            phonenumber: phone_number,
        });
        console.log('resend_otp good: ',response)
        toast.success(response.data.message)
        return response;
    } catch (error) {
        console.error('resend_otp failed:', error);
        toast.error(error.response.data.error)
        throw error;
    }
};

export const otp_code_register = async ({ otp, phonenumber }) => {
    try {
        const response = await axiosCarvenui.post('/users/verify_phonenumber_by_otp/', {
            otp,
            phonenumber
        });
        console.log('otp_code_register: ',response)
        toast.success(response.data.message)
        return response;
    } catch (error) {
        console.error('otp_code_register failed:', error);
        toast.error(error.response.data.error)
        throw error;
    }
};