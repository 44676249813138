import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const SpecificationItem = ({ icon, item, itemValue }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledItem language={language}>
      <div className="icon-container">
        <img src={icon} />
      </div>

      <div>
        <p className="key">{item}</p>
        <p className="value">{itemValue}</p>
      </div>
    </StyledItem>
  );
};
const StyledItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1.111vw;
  .icon-container {
    background: #f5f6f7;
    border-radius: 0.556vw;
    width: 3.333vw;
    height: 3.333vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 1440px) {
      width: 48px;
      height: 48px;
    }

    img {
      width: 1.528vw;
      height: 1.25vw;
      @media (min-width: 1440px) {
        font-size: 22px;
        line-height: 18px;
      }
    }
  }
  .key {
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
    font-style: normal;
    font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #9a9ea7;
    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  .value {
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Bold"};
    font-style: normal;
    font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
    font-size: 0.972vw;
    line-height: 1.458vw;
    color: #0d0a19;
    @media (min-width: 1440px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    gap: 2.133vw;
    .icon-container {
      border-radius: 0.556vw;
      width: 8.533vw;
      height: 8.533vw;
      img {
        width: 4.267vw;
        height: 4.8vw;
      }
    }
    .key {
      font-size: 3.733vw;
      line-height: 5.6vw;
    }
    .value {
      font-size: 4.267vw;
      line-height: 6.4vw;
    }
  }
`;
export default SpecificationItem;
