import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import Features from "../Components/Car/Features";
import Specifications from "../Components/Car/Specifications";
import WarrantyCoverage from "../Components/Car/WarrantyCoverage";
import bmw from "../images/bmw.svg";
import car_image from "../images/car.png";
import Video from "../Components/assets/Video";
import SpecialCars from "../Components/Home/SpecialCars";
import image1 from "../images/car/img1.png";
import image2 from "../images/car/img2.png";
import image3 from "../images/car/img3.png";
import { axiosCarvenui } from "../util/integration";
import CarIntro from "../Components/Car/Intro";
import AvailabilityCard from "../Components/Car/AvailabilityCard";
import { desktopSizes, mobileSizes } from "../util/sizes";
import LoginModal from "../Components/Car/LoginModal";
import RegisterModal from "../Components/Car/RegisterModal";
import CarCustomization from "../Components/Car/CarCustomization";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import LoadingGif from "../Components/assets/LoadingGif";
import useApi from "../hooks/useApi";

export const fake_car = {
  id: 1,
  name: "BMW 218i 1.5 A/T GC Sport line",
  model: "BMW 218i 1.5 A/T GC Sport line",
  image: car_image,
  logo: bmw,
  price: 137500,
  specifications: {
    engine: "6-Cylinders",
    body_type: "Sedan",
    body_volume: 5,
    tank: 55,
  },
  features: {
    fuel_type: "Gasoline 95 Plus",
    trim: "2.0L I4 16V GDI DOHC Turbo",
    exterior_color: "Jet Black",
    interior_color: "Black",
    interior_support: "Leather - Metallic Grey",
    interior_material: "Leather - Metallic Grey",
    internal_tool_kit: "Extra LED + Monitor 10 Inch",
    engine: "2.0L I4 16V GDI DOHC Turbo",
  },
  warranty_coverage: {
    description: "New BMW cars covered by Warranty and Insurance",
    bumper_to_bumber: "48 months / 50,000 miles",
    gasoline: "95 Plus",
    roadside_assistance: "48 months / unlimited distance",
    roadside_fuel: "1 Year",
    warranty: "5 Years",
    engine: "100,000 Km",
  },
  video: "https://www.youtube.com/embed/AarR83hDsUc",
  seller: {
    name: "Abdul Latif Jameel",
  },
  images: [image1, image2, image3, image1, image2, image3, image1, image2, image3],
  colors: ["white", "black", "blue"],
  materials: ["Leather", "Textile", "Carbon"],
};

const Car = () => {
  const { id } = useParams();
  const [car, setCar] = useState({});
  const [brand, setBrand] = useState();
  const [seller, setSeller] = useState({});
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(true);
  const [specialLoading, setspecialLoading] = useState(true);
  const [carDetails, setCarDetails] = useState([]);
  const [specifications, setSpecifications] = useState({});
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [materialColors, setMaterialColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [selectedMaterialColor, setSelectedMaterialColor] = useState();
  const [features, setFeatures] = useState({});
  const [warranty, setWarranty] = useState({});
  const [images, setImages] = useState([]);
  const [groupedDetails, setGroupedDetails] = useState([]);
  const ids = [1];
  const [similarsCars, setSimilarsCars] = useState([]);
  const [availabilityLoading, setAvailabilityLoading] = useState(true);
  const [initialSelection, setInitialSelection] = useState({});
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [materialChanged_flag, setMaterialChanged_flag] = useState(false);
  const [loanData, setLoanData] = useState({});
  const selectedMaterialRef = useRef(selectedMaterial);
  const [error, setError] = useState(false);
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const { get } = useApi();

  useEffect(() => {
    const getCar = async () => {
      try {
        const res = await axiosCarvenui.get(`/car/${id}/`);
        if (res) {
          console.log("car data", res.data);
          setCar(res.data);
          setImages(Object.values(res.data.meta_data?.images) || []);
          setSpecifications({
            body_type: res.data.body_type,
            num_of_seats: res.data.num_of_seats,
            engine: res.data.cylinders,
            litre_capacity: res.data.litre_capacity,
          });
          setFeatures({
            fuel_type: res.data.fuel_type,
            internal_tool_kit: res.data.interior_tool_kit,
            trim: res.data.rim,
            engine: `${res.data.engine} CC`,
          });
        }
      } catch (error) {
        console.log(error);
        alert(error?.response?.data?.message || "Something went wrong");
        setError(true);
      }
    };

    const groupDetails = data => {
      let result = data.reduce(function (rv, x) {
        let keyToGroup = x["seller"]["name"];
        (rv[keyToGroup] = rv[keyToGroup] || []).push(x);

        return rv;
      }, {});
      return Object.keys(result).map(key => {
        return {
          seller: key,
          details: result[key],
        };
      });
    };

    const getCarDetails = async () => {
      try {
        const res = await axiosCarvenui.get(`/car_detail/?car=${id}`);
        console.log("car_details", res.data);
        let details = res.data.results;
        console.log("details  0", details[0]);
        setSeller((details[0] && details[0].seller) || {});
        console.log("seller", seller);
        setBrand(details[0]?.car.model.brand);
        setLoanData({
          brand: details[0]?.car.model.brand.id,
          model: details[0]?.car.model.id,
          year: details[0]?.car.year,
        });
        details.sort(function (a, b) {
          return Number(a.car_price) - Number(b.car_price);
        });
        setCarDetails(details);
        let grouped = groupDetails(details);
        setGroupedDetails(grouped);
        console.log(Math.min(...details.map(o => o.car_price)));
        const lowest_price = Math.min(...details.map(o => o.car_price));
        setPrice(lowest_price);
        const lowest_price_detail = details.find(d => d.car_price == lowest_price);
        if (lowest_price_detail) {
          setInitialSelection({
            car_color: lowest_price_detail.car_color,
            interior_fabric: lowest_price_detail.interior_fabric,
            interior_color: lowest_price_detail.interior_color,
          });
        }
        let car_colors = details.map(d => d.car_color);
        setColors(
          [...new Set(car_colors)].map(e => {
            return {
              name: e,
              value: e,
              name_ar: ar.colors[e.toLowerCase()],
            };
          })
        );
        setLoading(false);
      } catch (error) {
        console.log(error);
        alert(error?.response?.data?.message || "Something went wrong");
        setError(true);
        setLoading(false);
      }
    };

    getCar();
    getCarDetails();
  }, [id]);

  useEffect(() => {
    const getSpecialCars = async () => {
      try {
        setspecialLoading(true);
        let res = await axiosCarvenui.get(`/car_detail/details_by_car/?car_in=${ids.join(",")}`);
        setSimilarsCars(res.data.splice(0, 3));
        setspecialLoading(false);
      } catch (e) {}
    };
    getSpecialCars();
  }, []);

  useEffect(() => {
    if (colors.length > 0) {
      if (initialSelection.car_color) {
        setSelectedColor(initialSelection.car_color);
        setInitialSelection({ ...initialSelection, car_color: null });
      } else {
        // setSelectedColor(colors[0].value);
      }
    }
  }, [colors]);

  useEffect(() => {
    if (selectedColor) {
      let details = carDetails.filter(d => d.car_color == selectedColor);
      let car_materials = details.map(d => d.interior_fabric);
      setMaterials(
        [...new Set(car_materials)].map(e => {
          return {
            name: e,
            value: e,
            name_ar: ar.materials[e.toLowerCase()],
          };
        })
      );
      // const lowest_price = Math.min(...details.map((o) => o.car_price));
      // const lowest_price_detail = details.find(
      //   (d) => d.car_price == lowest_price
      // );

      setMaterialChanged_flag(true);
      setSelectedMaterial([...new Set(car_materials)][0]);
    }
  }, [selectedColor]);

  useEffect(() => {
    if (selectedMaterial) {
      if (selectedMaterialRef.current != selectedMaterial || materialChanged_flag) {
        let details = carDetails.filter(d => d.car_color == selectedColor && d.interior_fabric == selectedMaterial);
        let interior_colors = details.map(d => d.interior_color);
        setMaterialColors(
          [...new Set(interior_colors)].map(e => {
            return {
              name: e,
              value: e,
              name_ar: ar.colors[e.toLowerCase()],
            };
          })
        );
        // const lowest_price = Math.min(...details.map((o) => o.car_price));
        // const lowest_price_detail = details.find(
        //   (d) => d.car_price == lowest_price
        // );
        setSelectedMaterialColor([...new Set(interior_colors)][0]);
        setMaterialChanged_flag(false);
      }
    }
  }, [selectedMaterial, materialChanged_flag]);

  useEffect(() => {
    setWarranty({
      warranty: `${car.warranty_years} ${t.carPage.warranty.years}`,
      engine: `${car.warranty_km?.toLocaleString()} ${t.carPage.warranty.km}`,
    });
  }, [car, language]);

  useEffect(() => {
    const sort = async () => {
      try {
        let arr = [];
        if (groupedDetails.length > 0 && selectedColor && selectedMaterialColor && selectedMaterial) {
          await Promise.all(
            groupedDetails.map(i => {
              let available_detail = i.details.find(
                d =>
                  d.car_color === selectedColor &&
                  d.interior_color == selectedMaterialColor &&
                  d.interior_fabric == selectedMaterial
              );

              if (available_detail) {
                arr.push({
                  ...i,
                  isAvailable: true,
                  price: +available_detail.car_price,
                });
              }
            })
          );
          arr.sort(function (a, b) {
            return a.price - b.price;
          });
          setFilteredDetails(arr);
          setAvailabilityLoading(false);
        }
      } catch (e) {
        console.log("err", e);
      }
    };
    sort();
  }, [selectedColor, selectedMaterial, selectedMaterialColor]);

  useEffect(() => {
    const viewCar = async () => {
      await axiosCarvenui.get(`/car_detail/${id}/view-car/`);
    };
    viewCar();
  }, [id]);

  return !loading && !error && carDetails.length > 0 ? (
    <StyledPage language={language}>
      <CarIntro
        images={images}
        name={language == "en" ? car?.name : car?.name_ar}
        logo={brand?.logo}
        price={price}
        loanData={loanData}
      />
      <Specifications specifications={specifications} />

      <div className="warranty-features">
        <div className="col1">
          <WarrantyCoverage warrantyCovarge={warranty} brand={brand?.name} />
          <Features features={features} />
        </div>
        <img src={images[images.length - 1]} className="section-img" />
      </div>
      {/* <Availability carDetails={carDetails} /> */}
      <div className="avalibilty">
        <div className="section-header desktop-only">{t.carPage.availability.title}</div>
        <div className="customization-and-cards">
          <CarCustomization
            colors={colors}
            materials={materials}
            materialColors={materialColors}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            selectedMaterial={selectedMaterial}
            setSelectedMaterial={setSelectedMaterial}
            selectedMaterialColor={selectedMaterialColor}
            setSelectedMaterialColor={setSelectedMaterialColor}
          />
          <LoginModal />
          <RegisterModal />
          <div className="section-header mobile-only">{t.carPage.availability.title}</div>
          <div className="cards">
            {!availabilityLoading &&
              filteredDetails.map(
                (detail, index) =>
                  detail.isAvailable && (
                    <AvailabilityCard
                      key={index}
                      data={detail}
                      seller={seller}
                      selectedMaterialColor={selectedMaterialColor}
                      selectedColor={selectedColor}
                      selectedMaterial={selectedMaterial}
                    />
                  )
              )}
          </div>
        </div>
      </div>
      {!specialLoading && (
        <div className="cars-list">
          <SpecialCars title={language == "en" ? "Similar Cars" : "سيارات مماثلة"} cars={similarsCars} />
        </div>
      )}
    </StyledPage>
  ) : error ? (
    ""
  ) : (
    <LoadingGif />
  );
};
const StyledPage = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: 1.667vw 10.417vw 6.528vw 10.417vw;
  background: #fbfbfb;
  .section-header {
    font-family: ${(props) =>
      props.language == "en" ? "Inter" : "GE-SS-Bold"};
    font-weight: 700;
    font-size: 1.528vw;
    line-height: 2.222vw;
    color: #0d0a19;
    @media (min-width: 1440px) {
      font-size: 22px;
      line-height: 32px;
    }
  }
  .warranty-features {
    margin-top: 3.333vw;
    // padding-left: 2.639vw;
    display: flex;
    justify-content: space-between;
    .col1 {
      .items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 2.083vw;
        .item {
          display: flex;
          flex-direction: column;
          gap: 0.972vw;
          .key {
            display: flex;
            align-items: center;
            gap: 0.486vw;
            .text {
              font-family: ${(props) =>
                props.language == "en" ? "Inter" : "GE-SS-Bold"};
              font-weight: ${(props) =>
                props.language == "en" ? "600" : "700"};
              font-weight: 700;
              font-size: 0.972vw;
              line-height: 1.458vw;
              color: #0d0a19;

              @media (min-width: 1440px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
            img {
              width: 1.389vw;
              height: 1.389vw;
              object-fit: contain;

              @media (min-width: 1440px) {
                width: 20px;
                height: 20px;
              }
            }
          }
          .value {
            font-weight: 400;
            font-size: 0.972vw;
            line-height: 1.458vw;
            color: #0d0a19;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
    .section-img {
      width: 38.403vw;
      height: 22.5vw;
      border-radius: ${desktopSizes._12px};
      object-fit: cover;
    }
  }
  .avalibilty {
    margin-top: 3.75vw;
    margin-bottom: 3.958vw;
    // padding: 0 ${mobileSizes._16px};
    .customization-and-cards {
      display: flex;
      align-items: flex-start;
      gap: ${desktopSizes._28px};
      padding-top: ${desktopSizes._32px};
      .cards {
        display: flex;
        flex-direction: column;
        gap: ${desktopSizes._24px};
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    .section-header {
      font-weight: 600;
      font-size: ${mobileSizes._18px};
      line-height: ${mobileSizes._27px};
    }
    .warranty-features {
      padding: 0 4.267vw;
      margin-top: ${mobileSizes._16px};
      margin-bottom: ${mobileSizes._16px};
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._16px};
      .col1 {
        .items {
          display: flex;
          flex-direction: column;
          margin-left: ${(props) => props.language == "en" && "1.333vw"};
          margin-right: ${(props) => props.language == "ar" && "1.333vw"};
          gap: ${mobileSizes._12px};
          .item {
            display: flex;
            flex-direction: column;
            gap: 0;
            .key {
              display: flex;
              align-items: center;
              gap: ${mobileSizes._8px};
              .text {
                font-family: ${(props) =>
                  props.language == "en" ? "Inter" : "GE-SS-Bold"};
                font-weight: ${(props) =>
                  props.language == "en" ? "600" : "700"};
                font-size: ${mobileSizes._16px};
                line-height: ${mobileSizes._24px};
                color: #0d0a19;
              }
              img {
                width: ${mobileSizes._18px};
                height: ${mobileSizes._18px};
                object-fit: contain;
              }
            }
            .value {
              font-family: Poppins;
              font-weight: 400;
              font-size: ${mobileSizes._14px};
              line-height: ${mobileSizes._21px};
              color: #5a5a5a;
              margin-left: 8.533vw;
            }
          }
        }
      }
      .section-img {
        width: 91.467vw;
        height: 41.333vw;
        border-radius: ${mobileSizes._8px};
        object-fit: cover;
      }
    }
    .avalibilty {
      padding: 0 ${mobileSizes._16px};
      margin-top: 0;
      margin-bottom: ${mobileSizes._16px};
      .customization-and-cards {
        display: flex;
        align-items: initial;
        flex-direction: column;
        gap: 0;
        padding-top: 0;
        .cards {
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._16px};
        }
      }
    }
    .cars-list {
      padding-left: ${(props) => props.language == "en" && "4.267vw"};
      padding-right: ${(props) => props.language == "ar" && "4.267vw"};
    }
  }
`;
export default React.memo(Car);
