import React, { useEffect, useState } from "react";
import Form from "../assets/Form";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const FinancialInfoForm = ({ next, data, ...props }) => {
  const [formData, setFormData] = useState({...data});
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const food_expense_field = {
    name: "food_expense",
    displayName: t.carFinance.financialInfo.food_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const wages_field = {
    name: "wages_for_domestics",
    displayName: t.carFinance.financialInfo.wages_for_domestics,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const housing_field = {
    name: "housing_rent",
    displayName: t.carFinance.financialInfo.housing_rent,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const education_expense_field = {
    name: "education_expense",
    displayName: t.carFinance.financialInfo.education_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const healthcare_expense_field = {
    name: "health_care_expense",
    displayName: t.carFinance.financialInfo.health_care_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const transport_communication_field = {
    name: "transport_and_communication_expense",
    displayName: t.carFinance.financialInfo.transport_and_communication_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const insurance_expense_field = {
    name: "insurance_expense",
    displayName: t.carFinance.financialInfo.insurance_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const other_expense_field = {
    name: "other_expense",
    displayName: t.carFinance.financialInfo.other_expense,
    placeholder: "0",
    displayCurrency: true,
    required: false,
    type: "number",
  };

  const fields = [
    food_expense_field,
    wages_field,
    housing_field,
    education_expense_field,
    healthcare_expense_field,
    transport_communication_field,
    insurance_expense_field,
    other_expense_field,
  ];

  const keys = fields.map((field) => field.name);
  return (
    <Form
      formData={formData}
      setFormData={setFormData}
      fields={fields}
      submitText={t.next}
      onSubmit={async () => {
        let o = {};
        await Promise.all(
          keys.map((item) => {
            if (!formData[item]) {
              o[item] = 0;
            }
          })
        );
        // setFormData({ ...values, ...formData });

        next({ ...formData, ...o });
      }}
    />
  );
};

export default FinancialInfoForm;
