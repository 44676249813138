import styled from "styled-components";

const StyledLoadingSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  margin: 60px 0;

  .loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    border: 8px solid rgba(255, 255, 255, 0.2);
    border-top: 8px solid #154CD6;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const Spin = () => (
    <StyledLoadingSpinner>
        <div className="loader"></div>
    </StyledLoadingSpinner>
);

export default Spin;
