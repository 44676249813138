import React, { useState, useEffect } from "react";
import styled from "styled-components";
import buildCar from "../../images/build-car.png";
import history from "../../util/history";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const BuildCar = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledSection language={language}>
      <div className="col1">
        <div className="section-header">{t.buildCar.title}</div>
        <div className="text-info">{t.buildCar.text}</div>
        <button
          className="section-btn"
          onClick={() => {
            history.push("/build-car");
          }}
        >
          {t.buildCar.button}
        </button>
      </div>
      <div className="col2">
        <img src={buildCar} />
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: ${(props) =>
    props.language == "en" ? " 0 3.958vw 0 10.417vw" : "0 10.694vw 0 3.958vw"};
  margin-top: 6.944vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 4.861vw;
  .col1 {
    width: 38.472vw;
    @media (min-width: 1440px) {
      width: 554px;
    }
  }
  .col2 {
    img {
      width: 42.292vw;
      height: 19.931vw;
      object-fit: contain;
      -webkit-transform: ${(props) => props.language === "en" && "scaleX(-1)"};
      transform: ${(props) => props.language === "en" && "scaleX(-1)"};
    }
  }

  @media (max-width: 768px) {
    padding: 0 4.267vw;
    margin-top: ${mobileSizes._32px};
    display: flex;
    flex-direction: column;
    gap: 4.267vw;
    .col1 {
      width: auto;
      order: 2;
    }
    .col2 {
      img {
        width: 91.467vw;
        height: 41.333vw;
        order: 1;
      }
    }
  }
`;
export default BuildCar;
