import React, { useRef } from "react";
import { StyledContainer } from "../Account";
import AccTitle from "../../../Components/MyAccount/AccTitle";
import { ButtonGroup, FormContainer, InfoCard } from "../../../Components/MyAccount/CommonStyled";
import { useNavigate } from "react-router";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ReactComponent as LoginIcon } from "../../../images/Account/protect.svg";
import styled from "styled-components";
import Button from "../../../Components/MyAccount/Button";
import { Link } from "react-router-dom";

function ConfirmCode() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const navigate = useNavigate();

  const inputs = useRef([]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (value.length === 1 && index < 3) {
      inputs.current[index + 1].focus();
    }

    if (value.length === 0 && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handlePaste = e => {
    const paste = e.clipboardData.getData("text");
    const digits = paste
      .split("")
      .filter(char => /\d/.test(char))
      .slice(0, 4);

    if (digits.length === 4) {
      digits.forEach((digit, index) => {
        inputs.current[index].value = digit;
      });
      inputs.current[3].focus();
    }

    e.preventDefault();
  };

  const { register, formState, handleSubmit } = useForm();

  const onSubmit = data => {
    console.log(data);
  };

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <AccTitle title={t.account.loginDetails} description={t.account.loginDetailsDesc} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div>
            <Head>{t.account.enter4Digits} +20123********125</Head>
            <Wrapper>
              <p>{t.account.enterToProceed}</p>
              <CodeInputContainer>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Input
                    key={index}
                    type="text"
                    maxLength="1"
                    placeholder="-"
                    onPaste={index === 0 ? handlePaste : null}
                    onChange={e => handleInputChange(e, index)}
                    ref={el => (inputs.current[index] = el)}
                  />
                ))}
              </CodeInputContainer>
              <p>
                <StyledLink>{t.account.contactSupport}</StyledLink>
                {t.account.facingIssues}
              </p>
            </Wrapper>
          </div>
          <div>
            <InfoCard>
              <span>
                <LoginIcon />
              </span>
              <h4>{t.account.PasswordAndQuestions}</h4>
              <p>...</p>
            </InfoCard>
          </div>
        </div>
        <BtnGroup>
          <div>
            <Button onClick={() => navigate(-1)} type="button" variation="secondary">
              {t.cancel}
            </Button>
            <Button>{t.confirm}</Button>
          </div>
        </BtnGroup>
      </FormContainer>
    </StyledContainer>
  );
}

export default ConfirmCode;

const BtnGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 2rem;
`;

const CodeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const Input = styled.input`
  width: 97px;
  height: 72px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #007bff;
  }

  @media (max-width: 996px) {
    width: 50px;
    height: 50px;
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  p:first-child {
    font-size: 14px;
    text-align: center;
    margin: 30px 0 30px 0;
    color: #898989;
  }
  p:last-child {
    text-align: center;
    margin: 30px 0 30px 0;
  }

  @media (max-width: 996px) {
    p:first-child {
      font-size: 12px;
    }
    p:last-child {
      font-size: 12px;
    }
  }
`;

const StyledLink = styled(Link)`
  color: #007bff;
  text-decoration: none;

  text-decoration: underline;
`;

const Head = styled.h1`
  font-size: 24px;

  @media (max-width: 996px) {
    font-size: 20px;
  }
`;
