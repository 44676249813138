import styled from "styled-components";

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
`;

export const StyledBoxInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${({ err }) => (err ? "1px solid #ff0000" : "1px solid #c4c4c4")} !important;
  border-radius: 10px;
  background-color: white;
  max-width: 35vw;
  label {
    padding: 0.7rem 1rem 0rem 1rem;
    font-size: 0.8rem;
    color: ${({ err }) => (err ? "#ff0000" : "#c4c4c4")} !important;
  }
  input {
    padding: 1rem;
    padding-top: 0.5rem;
    border: none;
    border-radius: 10px;

    /* placeholder */
    ::placeholder {
      color: #c4c4c4;
      font-size: 0.7rem;
    }
  }
  &:focus-within {
    transition: all 0.5s ease;
    border: ${({ err }) => (err ? "1px solid #ff0000" : "1px solid #154cd6")} !important;
    label {
      font-size: 0.8rem;
      color: ${props => (props.err ? "#ff0000" : "#154cd6")} !important;
    }
  }
  @media (max-width: 996px) {
    max-width: 100%;
  }
  .password_icon {
    position: absolute;
    inset-inline-end: 8px;
    top: 12px;
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    background-color: white;
    cursor: pointer;
  }
`;

export const InfoCard = styled.div`
  padding: 1.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  h4 {
    padding-top: 1.2rem;
    font-size: 1rem;
    color: #333;
  }
  p {
    padding-top: 0.5rem;
    font-size: 0.8rem;
    color: #333;
    width: 80%;
  }
  span {
    padding: 1rem 1.3rem;
    display: flex;
    width: fit-content;
    border-radius: 10px;
    background-color: #edecec;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 3rem;

  div {
    display: flex;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1;
  }
`;
export const FormContainer = styled.form`
  padding: 1.5rem 0;
  .container {
    display: grid;
    grid-template: auto / 2fr 1fr;
    gap: 10rem;

    @media (max-width: 996px) {
      grid-template: auto / 1fr;
      gap: 2rem;
    }

    @media (max-width: 768px) {
      grid-template: auto / 1fr;
      gap: 2rem;
    }
  }
`;
