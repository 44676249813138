import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Play } from "../../images/play.svg";

const Video = ({ src }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
  return (
    <StyledVideo>
      
      <iframe
        src={src}
        controls
        className="video"
        allowfullscreen="allowfullscreen"
        ref={videoRef}
        onClick={() => {
        
          setIsPlaying(!isPlaying);
          console.log("is",  isPlaying)
        }} 
      />
      {/* {!isPlaying && <Play />} */}
    </StyledVideo>
  );
};
const StyledVideo = styled.div`
  position: relative;
  width: 38.403vw;
  height: 22.5vw;
  .video {
    width: 100%;
    height: 100%;
    border: none;
    border-radius : 0.833vw;
  }
  svg {
    width: 3.681vw;
    height: 3.681vw;
    position: absolute;
    left: 17.431vw;
    top: 10.069vw;
  }

  @media(max-width : 768px){
    width: 91.467vw;
    height: 41.333vw;
    .video {
      border-radius : 2.133vw;
    }
  }
`;
export default Video;
