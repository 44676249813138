import { authApi } from "../../util/integration";

export const me = async () => {
    try {
        const { data } = await authApi.get(`/users/me`);
        console.log('data me:', data);
        return data;
    } catch (err) {
        console.error("Error fetching user data:", err);
        throw new Error("Error: cannot retrieve users/me data");
    }
};
