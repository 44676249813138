import { useState, useCallback } from "react";
import { axiosCarvenui } from "../util/integration";

const useApi = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(async (url, method = "GET", queryParams = {}, body = null) => {
    setLoading(true);
    setError(null);

    try {
      const config = {
        method,
        url,
        params: method === "GET" ? queryParams : undefined,
        data: ["POST", "PATCH", "PUT"].includes(method) ? body : undefined,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axiosCarvenui(config);
      setData(response.data);
      return response.data;
    } catch (err) {
      setError(err.response ? err.response.data : "An error occurred");
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const get = useCallback((url, queryParams = {}) => callApi(url, "GET", queryParams), [callApi]);
  const post = useCallback((url, body) => callApi(url, "POST", {}, body), [callApi]);
  const patch = useCallback((url, body) => callApi(url, "PATCH", {}, body), [callApi]);

  return { data, loading, error, get, post, patch };
};

export default useApi;
