import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useUserMe } from "../useUserMe";
import toast from 'react-hot-toast';
import history from "../../../util/history";


const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {

  const navigate = useNavigate();

  const initialRefreshToken = localStorage.getItem("refresh_token");
  const initialAccessToken = localStorage.getItem("access_token");
  const initialUser =
    initialRefreshToken && initialAccessToken ? JSON.parse(localStorage.getItem("user")) ?? null : null;



  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
  const [user, setUser] = useState(initialUser);

  const userIsLoggedIn = !!accessToken && !!refreshToken;

  const { error: userDataError, isLoading: isLoadingUserData, data: userData } = useUserMe(userIsLoggedIn);


  useEffect(() => {
    if (userDataError) {
      console.log("Error fetching user data");
      toast.error('Error user data')
    }

    if (userIsLoggedIn && !isLoadingUserData && userData) {
      setUser(userData);
      localStorage.setItem("user", JSON.stringify(userData));
    } else {
      setUser(null);
    }
  }, [userDataError, userIsLoggedIn, userData, isLoadingUserData]);


  useEffect(() => {
    if (user && userIsLoggedIn) {
      localStorage.setItem("user", JSON.stringify(user));
    }
    if (!userIsLoggedIn) {
      setUser(null);
      localStorage.removeItem("user");
    }
  }, [user, userIsLoggedIn]);

  // If User Login But Not Verified
  const initialRender = useRef(true); 
  useEffect(() => {
    if (initialRender.current) {
   
      initialRender.current = false;
      return;
    }
    if (user && userIsLoggedIn && !user?.is_phone_verified) {
      history.push("/verify-user")
    }
  }, [user, userIsLoggedIn]);

  const loginHandler = async ({ accessToken, refreshToken }) => {

    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);

  };

  const logoutHandler = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
    sessionStorage.removeItem("calculator_banks");
    sessionStorage.removeItem("calculator_banks_offers");
    sessionStorage.removeItem("calculator_car_detail");
    toast.success('Successfully Logout')
    navigate("/login");
  };

  const contextValue = {
    accessToken,
    refreshToken,
    isLoggedIn: userIsLoggedIn,
    user,
    setUser,
    loginHandler,
    logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>; 
};

export default AuthContext;
