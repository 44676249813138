import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SpecificationItem from "./SpecificationItem";
import engine_icon from "../../images/engine.svg";
import tank_icon from "../../images/tank.svg";
import seat_icon from "../../images/seat.svg";
import body_icon from "../../images/body.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const Specifications = ({ specifications }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const specificationsAttr = {
    body_type: {
      name: t.carPage.specifications.body_type,
      icon: body_icon,
      value: t.body_types[specifications["body_type"]] || specifications["body_type"],
    },
    num_of_seats: {
      name: t.carPage.specifications.body_volume,
      icon: seat_icon,
      value: `${specifications["num_of_seats"]} ${t.carPage.specifications.seats}`,
    },
    tank: {
      name: t.carPage.specifications.tank,
      icon: tank_icon,
      value: `${specifications["litre_capacity"]} ${t.carPage.specifications.liters}`,
    },
    engine: {
      name: t.carPage.specifications.engine,
      icon: engine_icon,
      value: `${specifications["engine"]}${t.carPage.specifications.cylinders}`,
    },
  };
  return (
    <StyledSection>
      <div className="section-header">
        {t.carPage.specifications.specifications}
      </div>
      <div className="specifications">
        {Object.keys(specificationsAttr).map(
          (item) =>
            specificationsAttr[item] && (
              <SpecificationItem
                icon={specificationsAttr[item].icon}
                item={specificationsAttr[item].name}
                itemValue={specificationsAttr[item].value}
              />
            )
        )}

        {/* <SpecificationItem
          icon={body_icon}
          item="Body type"
          itemValue={bodyType}
        />
        <SpecificationItem
          icon={seat_icon}
          item="Body Volume"
          itemValue={bodyVolume + " Seats"}
        />
        <SpecificationItem
          icon={tank_icon}
          item="Tank"
          itemValue={tank + " Liters"}
        />
        <SpecificationItem
          icon={engine_icon}
          item="Engine"
          itemValue={engine}
        /> */}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.667vw;
  padding: 2.222vw 0;
  border-bottom: 0.069vw solid #eeeeee;
  .specifications {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 5.333vw;
    padding: 6.4vw 4.267vw;
    border-bottom: 0.267vw solid #eeeeee;
    .specifications {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 5.333vw;
    }
  }
`;
export default Specifications;
