import React, { useEffect, useState } from "react";
import { countriesCodes } from "../../util/data";
import Form from "../assets/Form";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { extractMobileDetails } from "../../util/utilFunctions";

const PersonalForm = ({ next, data, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const { countryCode, mobileNumber:mobile } = extractMobileDetails(data?.phone) ?? {};

  const [formData, setFormData] = useState({ ...data, countryCode, mobile });
  const codeField = {
    name: "code",
    options: countriesCodes,
  };

  const nationality_field = {
    name: "nationality",
    label: t.carFinance.personalInfo.nationality,
    displayName: t.carFinance.personalInfo.nationality,
    type: "select",
    options: [
      { name: "Egyptian", value: "Egyptian", name_ar: "مصري" },
      { name: "Saudi Arabian", value: "Saudi Arabian", name_ar: "سعودي" },
    ],
  };

  const fields = [
    {
      name: "first_name",
      placeholder: t.carFinance.personalInfo.fname,
      displayName: t.carFinance.personalInfo.fname,
    },
    {
      name: "last_name",
      placeholder: t.carFinance.personalInfo.lname,
      displayName: t.carFinance.personalInfo.lname,
    },
    {
      name: "email",
      placeholder: t.carFinance.personalInfo.email,
      displayName: t.carFinance.personalInfo.email,
    },
    {
      name: "phone",
      disableArabic: true,
    },
    nationality_field,
    {
      name: "nationalId",
      placeholder: t.carFinance.personalInfo.nationalID,
      displayName: t.carFinance.personalInfo.nationalID,
      required: false,
      type: "number",
    },
  ];

  return (
    <Form
      formData={formData}
      setFormData={setFormData}
      fields={fields}
      submitText={t.next}
      onSubmit={() => {
        next(formData);
      }}
    />
 
  );
};

export default PersonalForm;
