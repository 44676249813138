
import { axiosCarvenui } from '../../../util/integration';
import toast from 'react-hot-toast';

export const forget_password = async ({ phonenumber }) => {
    try {
        const response = await axiosCarvenui.post('/users/forget_password_by_phonenumber/', {
            phonenumber,
        });
        console.log('forget_password: ',response)
        toast.success(response.data.message)
        return response;
    } catch (error) {
        console.error('forget_password failed:', error);
        toast.error(error.response.data.error)
        throw error;
    }
};

export const otp_code = async ({ otp, phone_number }) => {
    try {
        const response = await axiosCarvenui.post('/users/verify_forget_password_request_by_otp/', {
            otp,
            phone_number
        });
        console.log('otp_code: ',response)
        toast.success(response.data.message)
        return response;
    } catch (error) {
        console.error('otp_code failed:', error);
        toast.error(error.response.data.message)
        throw error;
    }
};


export const reset_password = async ({otp, phone_number, password, confirm_password }) => {
    try {
        const response = await axiosCarvenui.post('/users/reset_password_by_phonenumber/', {
            otp,
            phone_number,
            password,
            confirm_password
        });
        console.log(response)
        toast.success(response.data.message)
        return response;
    } catch (error) {
        console.error('reset_password failed:', error);
        toast.error(error.response.data.error)
        throw error;
    }
};