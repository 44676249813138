import { useState } from "react";
import { authApi } from "../util/integration";
import toast from "react-hot-toast";
import ar from "../util/locales/ar";
import en from "../util/locales/en";
import { useSelector } from "react-redux";
import history from "../util/history";


export const useRequestSubmission = id => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const language = useSelector(state => state.language);
    const t = language === "en" ? en : ar;

    const requestSubmission = async data => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await authApi.post(`/request_submission/`, data);
            setData(response.data);
            toast.success(t.carPage.quotationSuccess.title);
            
        } catch (err) {
            console.log(err);
            setError(err.message);
            toast.error(err?.response?.data?.detail);
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, data, error, requestSubmission };
};
