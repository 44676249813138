import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BuildStep from "../Components/BuildCar/BuildStep";
import car_img from "../images/build-car/car.png";
import engine from "../images/build-car/engine.png";
import carTrim from "../images/build-car/car-trim.png";
import gear from "../images/build-car/gear.png";
import payment from "../images/build-car/payment.png";
import { axiosCarvenui } from "../util/integration";
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import LoadingGif from "../Components/assets/LoadingGif";

const BuildCar = () => {
  const [step, setStep] = useState(1);
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedEngineCapacity, setSelectedEngineCapacity] = useState();
  const [selectedCarTrim, setSelectedCarTrim] = useState();
  const [selectedGearBox, setSelectedGearBox] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoading(true);
        const res = await axiosCarvenui.get(`/brand?page_size=24`);
        console.log("res", res);
        const data = res.data.results;
        setBrands(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getBrands();
  }, []);

  // const []
  const steps = [
    {
      id: 1,
      item: t.buildCarPage.brand,
      itemValues: brands.map((brand) => {
        return { ...brand, value: brand.name };
      }),
      selected: selectedBrand,
      selectValue: setSelectedBrand,
      image: car_img,
    },
    {
      id: 2,
      item: t.buildCarPage.engineCapacity,
      itemValues: [
        { name: "1000 CC", value: 1000 },
        { name: "1600 CC", value: 1600 },
        { name: "2000 CC", value: 2000 },
        { name: "3000 CC", value: 3000 },
      ],
      selectValue: setSelectedEngineCapacity,
      selected: selectedEngineCapacity,
      image: engine,
    },
    {
      id: 3,
      item: t.buildCarPage.carTrim,
      itemValues: [
        {
          name: "Performance Line",
          value: "Performance Line",
          name_ar: "Performance Line",
        },
        {
          name: "Performance Line +",
          value: "Performance Line +",
          name_ar: "Performance Line +",
        },
        {
          name: "Rivoli",
          value: "Rivoli",
          name_ar: "ريفولي",
        },
        { name: "Opera", value: "Opera", name_ar: "اوبرا" },
        {
          name: "Touring edition",
          value: "Touring edition",
          name_ar: " تورينج",
        },
        {
          name: "Grand Touring",
          value: "Grand Touring",
          name_ar: "جراند تورينج",
        },
        { name: "Extra", value: "Extra", name_ar: "اكسترا" },
        {
          name: "Classic/Custom Edition",
          value: "Classic/Custom Edition",
          name_ar: " كلاسيكية / مخصصة",
        },
        { name: "Sport Edition", value: "Sport Edition", name_ar: "سبورت" },
        { name: "Special Edition", value: "Special Edition", name_ar: "خاصة" },
      ],
      selectValue: setSelectedCarTrim,
      selected: selectedCarTrim,
      image: carTrim,
    },
    {
      id: 4,
      item: t.buildCarPage.gearBox,
      itemValues: [
        { name: "Manual", value: "Manual", name_ar: "يدوي" },
        { name: "Automatic", value: "Automatic", name_ar: "أوتوماتيكي" },
        { name: "Hybrid", value: "Hybrid", name_ar: "هايبريد" },
      ],
      selectValue: setSelectedGearBox,
      selected: selectedGearBox,
      image: gear,
    },
    {
      id: 5,
      item: t.buildCarPage.paymentMethod,
      itemValues: [
        { name: "Cash", value: "cash", name_ar: "نقدي" },
        { name: "Leasing", value: "leasing", name_ar: "تأجير" },
        { name: "Instalments", value: "installments", name_ar: "أقساط" },
      ],
      selectValue: setSelectedPaymentMethod,
      selected: selectedPaymentMethod,
      image: payment,
    },
  ];
  return (
    <StyledPage>
      {!loading ? (
        <BuildStep
          key={step}
          step={step}
          setStep={setStep}
          item={steps[step - 1].item}
          itemValues={steps[step - 1].itemValues}
          image={steps[step - 1].image}
          selectValue={steps[step - 1].selectValue}
          selected={steps[step - 1].selected}
        />
      ) : (
        <div className="loading-div">
          <LoadingGif />
        </div>
      )}
    </StyledPage>
  );
};
const StyledPage = styled.div`
  .loading-div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
`;
export default BuildCar;
