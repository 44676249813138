import { Route, Routes } from "react-router";

import Account from "./Account";
import AccountInfo from "./AccountInfo";
import Expenses from "./Expenses";
import LoginDetails from "./LoginDetails/LoginDetails";
import SendCode from "./LoginDetails/SendCode";
import ConfirmCode from "./LoginDetails/ConfirmCode";

function AccountRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Account />} />
      <Route path="/account-info" element={<AccountInfo />} />
      <Route path="/login-details/*">
        <Route index element={<LoginDetails />} />
        <Route path="send-code" element={<SendCode />} />
        <Route path="confirm-code" element={<ConfirmCode />} />
      </Route>
      <Route path="/payment-plans" element={<Expenses />} />
    </Routes>
  );
}

export default AccountRoutes;
