import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import { ReactComponent as User } from "../../images/user-octagon.svg";
import { ReactComponent as MobileMenuIcon } from "../../images/menu.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import history from "../../util/history";
import MobileNavMenu from "./MobileNavMenu";
import AuthContext from "../../hooks/Auth/providers/AuthContext";
import UserDropdown from "../MyAccount/UserDropdown";

const NavBar = () => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];
  const authPages_pathname = ["/sign-up", "/login", "/forget-password"];
  const { user } = useContext(AuthContext);

  return (
    <>
      <StyledNav className="desktop-only" language={language}>
        <Link to="/" className="logo-container">
          <img src={logo} />
        </Link>
        <div className="nav-links">
          <Link to="/cars" className={`${pathname == "cars" ? "selected" : ""}`}>
            {t.navBar.CarsForSale}
          </Link>
          <Link to="/build-car" className={`${pathname == "build-car" ? "selected" : ""}`}>
            {t.navBar.buildCar}
          </Link>
          <Link to="/car-finance" className={`${pathname == "car-finance" ? "selected" : ""}`}>
            {t.navBar.carFinance}
          </Link>
          <Link to="/reviews" className={`${pathname == "reviews" ? "selected" : ""}`}>
            {t.navBar.reviews}
          </Link>
        </div>
      </StyledNav>

      <StyledMobileNav className="mobile-only" language={language}>
        <MobileNavMenu />
        <div className="col">
          <button
            className="menu-icon-btn"
            onClick={() => {
              history.push("?mobile-menu=true");
            }}
          >
            <MobileMenuIcon />
          </button>
          <Link to="/" className="logo-container">
            <img src={logo} />
          </Link>
        </div>

        {!authPages_pathname.includes(location.pathname) && !user ? (
          <Link to="sign-up" className="signup">
            <User />
            <p> {t.header.signup}</p>
          </Link>
        ) : (
          <UserDropdown />
        )}
      </StyledMobileNav>
    </>
  );
};

const StyledMobileNav = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  width: 100%;
  padding: 6.4vw 4.267vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .col,
  .signup {
    display: flex;
    align-items: center;
    gap: 2.133vw;
    svg {
      width: 6.4vw;
      height: 6.4vw;
      -webkit-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      -moz-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      -o-transform: ${props => props.language == "ar" && " scaleX(-1)"};
      transform: ${props => props.language == "ar" && " scaleX(-1)"};
    }
  }
  .logo-container {
    img {
      width: 25.867vw;
      height: 6.4vw;
    }
  }
  .signup {
    p {
      display: flex;
      align-items: center;
      gap: 2.133vw;
      font-family: ${props => (props.language == "en" ? "Poppins" : "GE-SS-Bold")};
      font-style: normal;
      font-weight: ${props => (props.language == "en" ? "600" : "700")};
      font-size: 4.267vw;
      line-height: 6.4vw;
      color: #9a9ea7;
    }
  }
  .menu-icon-btn {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
  }
`;

const StyledNav = styled.div`
  direction: ${props => (props.language == "en" ? "ltr" : "rtl")};
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1.25vw 10.694vw 1.25vw 10.417vw;
  gap: 6.944vw;
  z-index: 1;
  .logo-container {
    z-index: 1;
    img {
      width: 6.736vw;
      height: 1.667vw;
      @media (min-width: 1440px) {
        width: 97px;
        height: 24px;
      }
    }
  }
  .nav-links {
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 2.222vw;
    a {
      font-family: ${props => (props.language == "en" ? "Inter" : "GE-SS-Bold")};
      font-weight: 700;
      font-size: 1.111vw;
      line-height: 1.667vw;
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .selected {
      color: #154cd6;
    }
  }
`;
export default NavBar;
