import { Player } from "@lottiefiles/react-lottie-player";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CarLoading from "../../lotties/CarLoading";

const LoadingGif = () => {
  return (
    <StyledLoading>
      <Player
        src={CarLoading}
        className="player"
        loop
        autoplay
        // style={{ height: "300px", width: "300px" }}
      />
    </StyledLoading>
  );
};
const StyledLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .player {
    width: 300px;
    height: 300px;
    @media(max-width : 768px){
      width: 80vw;
      height: 80vw;
    }
  }
`;

export default LoadingGif;
