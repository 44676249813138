import styled, { css } from "styled-components";

const sizes = {
  small: css`
    font-size: 1.2rem;
    padding: 0.4rem 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
  `,
  medium: css`
    font-size: 0.9rem;
    padding: 0.8rem 0.8rem;
    font-weight: 500;
  `,
  large: css`
    font-size: 1.6rem;
    padding: 1.2rem 2.4rem;
    font-weight: 500;
  `,
};

const variations = {
  primary: css`
    color: white;
    background-color: #154cd6;

    &:hover {
      background-color: #023fda;
    }
    &:disabled {
      background-color: #154cd67a;
      &:hover {
        background-color: #154cd67a;
      }
    }
  `,
  secondary: css`
    color: #1a1d1f;
    background: white;
    border: 1px solid #f1f1f1;

    &:hover {
      background-color: #f5f5f5;
    }

    &:disabled {
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  `,
  danger: css`
    color: white;
    background-color: #ff5630;

    &:hover {
      background-color: #ff3336;
    }

    &:disabled {
      background-color: #ff56307a;
      &:hover {
        background-color: #ff56307a;
      }
    }
  `,
};

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.5px 0.5px 0px;
  transition: background-color 0.3s;
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: small;
  }

  ${props => sizes[props.size]}
  ${props => variations[props.variation]}
`;

StyledButton.defaultProps = {
  variation: "primary",
  size: "medium",
};

const Button = ({ isLoading, children, ...props }) => {
  return (
    <StyledButton {...props}>
      {isLoading && <Spinner />} {children}
    </StyledButton>
  );
};

export default Button;

const Spinner = styled.div`
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid #fff;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;

  @keyframes l20-1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    62.5% {
      clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
  }
  @keyframes l20-2 {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
`;