import { useEffect, useState } from "react";
import { authApi, axiosCarvenui } from "../../util/integration";

export const useGetAllBlogs = (query) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const getAllBlogs = async () => {
        setIsLoading(true);
        setError(null);

        // Build the query string for pagination
        const { page = 1, page_size = 10 } = query || {};  // Default values if not provided

        try {
            const response = await axiosCarvenui.get(`/blog`, {
                params: {
                    ...query,
                    page,
                    page_size,
                },
            });
            setData(response.data);
        } catch (err) {
            console.log(err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllBlogs();  // Automatically fetch blogs when the component is mounted or query changes
    }, [query]);  // Refetch when query changes

    return { isFetching: isLoading, data, error };
};

export const useGetBlogById = (id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const getBlog = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axiosCarvenui.get(`/blog/${id}`);
            setData(response.data);
        } catch (err) {
            console.log(err);
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            getBlog();  // Automatically fetch blog by ID when the component is mounted and ID is available
        }
    }, [id]);

    return { isFetching: isLoading, data, error };
};