import sedan from "../images/shapes/sedan.png";
import suv from "../images/shapes/suv.png";
import coupe from "../images/shapes/coupe.png";
import hatchback from "../images/shapes/hatchback.png";
import van from "../images/shapes/van.png";
import station from "../images/shapes/station.png";
import egy from "../images/flags/egy.png";
import ksa from "../images/flags/ksa.png";
import ar from "./locales/ar";

export const countriesCodes = [
  {
    country: "KSA",
    flag: ksa,
    name: "+966",
    value: "+966",
  },
  {
    country: "Egypt",
    flag: egy,
    name: "+20",
    value: "+20",
  },
];

export const shapes = [
  {
    img: sedan,
    name: "Sedan",
    value: "sedan",
    name_ar: ar.body_types.sedan,
  },
  {
    img: suv,
    name: "Suv",
    value: "suv",
    name_ar: ar.body_types.suv,
  },
  {
    img: coupe,
    name: "Coupe",
    value: "other",
    name_ar: ar.body_types.coupe,
  },
  {
    img: hatchback,
    name: "Hatchback",
    value: "hatchback",
    name_ar: ar.body_types.hatchback,
  },
  {
    img: van,
    name: "Van",
    value: "other",
    name_ar: ar.body_types.van,
  },
  {
    img: station,
    name: "Station",
    value: "other",
    name_ar: ar.body_types.station,
  },
];
