import styled from "styled-components";
import { forwardRef, useRef, useState } from "react";

import { ReactComponent as EditIcon } from "../../images/Account/edit.svg";
import ar from "../../util/locales/ar";
import en from "../../util/locales/en";
import { useSelector } from "react-redux";

const InputField = forwardRef(({ errMessage, id, label, ...props }, ref) => {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const [fieldStatus, setFieldStatus] = useState(true);
  const innerRef = useRef(null);

  const handleEdit = () => {
    setFieldStatus(false);
    innerRef.current.focus();
  };

  const handleRef = node => {
    innerRef.current = node;
    if (typeof ref === "function") {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
  };

  return (
    <Input language={language}>
      <label htmlFor={id}>{label}</label>
      <div className="wrapper">
        <StyledInput ref={handleRef} disabled={fieldStatus} id={id} {...props} />
        <EditButton type="button" onClick={handleEdit}>
          <EditIcon />
          <p>{t.edit}</p>
        </EditButton>
      </div>
      {errMessage && <Error>{errMessage}</Error>}
    </Input>
  );
});

export default InputField;

const Input = styled.div`
  .wrapper {
    display: flex;
    position: relative;
  }

  label {
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #666666;
    display: block;
    margin-bottom: 5px;
    font-family: ${props => (props.language === "en" ? "Inter" : "GE-SS-Bold")} !important;
  }

  .wrapper:focus-within button {
    transition: all 0.5s ease;
    p {
      color: #0b42cc;
    }
    svg {
      fill: #0b42cc;
    }
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  display: flex;
  padding-bottom: 5px;
  gap: 0.5rem;
  right: -50px;
  margin-top: 1rem;
  align-items: center;
  p {
    font-size: 0.8rem;
    color: #666666;
  }
  &:hover {
    cursor: pointer;
    p {
      color: #0b42cc;
    }
    svg {
      fill: #0b42cc;
    }
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border: 0;
  padding: 10px;
  background-color: transparent;
  border-bottom: 2px solid #e0e0e0;
  &:focus {
    outline: none;
    border-bottom: 2px solid #0b42cc;
    transition: all 0.5s ease;
  }
  &:disabled {
    color: #000;
    font-weight: bold;
  }
`;

const Error = styled.span`
  font-size: smaller;
  color: red;
`;
