export const desktopSizes = {
  _1px: "0.069vw",
  _2px:"0.139vw",
  _4px: "0.278vw",
  _6px: "0.417vw",
  _8px: "0.556vw",
  _10px: "0.694vw",
  _12px: "0.833vw",
  _14px: "0.972vw",
  _15px:"1.042vw",
  _16px: "1.111vw",
  _18px: "1.25vw",
  _20px: "1.389vw",
  _21px:"1.458vw",
  _24px: "1.667vw",
  _27px:"1.875vw",
  _28px:"1.944vw",
  _30px: "2.083vw",
  _32px: "2.222vw",
  _36px: "2.5vw",
  _40px: "2.778vw",
  _48px: "3.333vw",
  _150px: "10.417vw",
};

export const mobileSizes = {
  _1px: "0.267vw",
  _2px:"0.533",
  _3px:"0.8",
  _4px: "1.067vw",
  _5px: "1.333vw",
  _6px: "1.6vw",
  _8px: "2.133vw",
  _10px: "2.667vw",
  _12px: "3.2vw",
  _14px: "3.733vw",
  _15px:"4vw",
  _16px: "4.267vw",
  _18px: "4.8vw",
  _19px : "5.067vw",
  _20px: "5.333vw",
  _21px:"5.6vw",
  _24px: "6.4vw",
  _27px:"7.2vw",
  _28px:"7.467vw",
  _30px: "8vw",
  _32px: "8.533vw",
  _36px: "9.6vw",
  _40px: "10.667vw",
};
