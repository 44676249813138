import React, { useState, useEffect, useCallback } from "react";
import useApi from "../../hooks/useApi";
import styled from "styled-components";
import { ArrowIcon } from "../assets/Icons";
import Skeleton from "./Skeleton";
import CarCard from "../Home/CarCard";

function SellerCars({ sellerId, language, t }) {
  const { get, data, loading } = useApi();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 9;

  const fetchCars = useCallback(() => {
    get("/car_detail/", { page: currentPage, page_size: pageSize, seller: sellerId });
  }, [get, currentPage, sellerId]);

  useEffect(() => {
    fetchCars();
  }, [fetchCars]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (data?.next) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const CardsSkeleton = () => {
    return (
      <SellerCardsContainer>
        {[...Array(9)].map((_, index) => (
          <Skeleton key={index} height="300px" />
        ))}
      </SellerCardsContainer>
    );
  };

  if (loading) return <CardsSkeleton />;

  return (
    <SellerCardsContainer>
      <TitleContainer>
        <h2>{t.seller.CarsWeSell}</h2>
        <div>
          <NavButton language={language} onClick={handlePrevPage} disabled={currentPage === 1 || loading}>
            <ArrowIcon />
          </NavButton>
          <NavButton language={language} onClick={handleNextPage} disabled={!data?.next || loading}>
            <ArrowIcon />
          </NavButton>
        </div>
      </TitleContainer>
      {data?.results?.length === 0 && <div>{t.seller.noCars}</div>}
      {data?.results?.map(car => (
        <CarCard car_detail={car} />
      ))}
    </SellerCardsContainer>
  );
}

export default SellerCars;

const SellerCardsContainer = styled.div`
  padding: 4vw 10vw;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2vw;
  @media (max-width: 768px) {
    padding: 4vw;
  }
`;

const TitleContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 2rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
`;

const NavButton = styled.button`
  background-color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
    stroke: #154cd6;
  }
  &:first-child {
    transform: ${props => (props.language === "ar" ? "rotate(-90deg)" : "rotate(90deg)")};
  }
  &:last-child {
    transform: ${props => (props.language === "ar" ? "rotate(90deg)" : "rotate(-90deg)")};
  }
  &:hover {
    background-color: #f5f5f5;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
