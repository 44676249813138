import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Slider } from "@material-ui/core";
import { desktopSizes, mobileSizes } from "../../util/sizes";

const PriceSlider = ({ priceRange, handlePriceChange, min, max, step }) => {
  return (
    <StyledSlider>
      <div className="slider">
        <Slider
          value={priceRange}
          onChange={handlePriceChange}
          min={min}
          max={max}
          step={step}
        />
      </div>
      <div className="values">
        <p>
          {priceRange[0].toLocaleString()} <span>SAR</span>{" "}
        </p>
        <p>
          {priceRange[1].toLocaleString()} <span>SAR</span>{" "}
        </p>
      </div>
    </StyledSlider>
  );
};
const StyledSlider = styled.div`
  width: 18.333vw;
  height: 5.486vw;
  padding: 1.111vw;
  background: #ffffff;
  border-radius: 0.278vw;
  .slider {
    .MuiSlider-track {
      background-color: #154cd6;
    }
    .MuiSlider-thumbColorPrimary {
      color: #154cd6;
    }
  }
  .values {
    display: flex;
    justify-content: space-between;
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 0.972vw;
      line-height: 1.25vw;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: ${desktopSizes._10px};
      line-height: 1.25vw;
      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 77.6vw;
    height: auto;
    padding: ${mobileSizes._16px} ;
    background: #ffffff;
    border-radius: 0.8vw;
    .slider {
      margin-bottom: ${mobileSizes._20px};
      .MuiSlider-root {
        padding : 0;
      }
      .MuiSlider-rail {
        height: ${mobileSizes._4px};
      }
      .MuiSlider-track {
        height: ${mobileSizes._4px};
        background-color: #154cd6;
      }
      .MuiSlider-thumbColorPrimary {
        color: #154cd6;
        width: ${mobileSizes._15px};
        height: ${mobileSizes._15px};
      }
    }
    .values {
      p {
        font-weight: 500;
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._18px};
      }
      span {
        font-weight: 500;
        font-size: ${mobileSizes._10px};
        line-height: ${mobileSizes._18px};
      }
    }
  }
`;
export default PriceSlider;
