import React, { useState, useEffect } from "react";
import styled from "styled-components";
import grid from "../../images/intro/gridiant.svg";
import car_img from "../../images/intro/car.png";
import { ReactComponent as Search } from "../../images/search.svg";
import { ReactComponent as SelectArrow } from "../../images/select-arrow-gray.svg";
import SelectMenu from "../assets/SelectMenu";
import PriceSlider from "./PriceSlider";
import { axiosCarvenui } from "../../util/integration";
import history from "../../util/history";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../util/sizes";

const Intro = ({ initialBrands, initialLoading, initialNextBrands }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([
    // { name: "SEDAN", value: "sedan" },
    // { name: "HATCHBACK", value: "hatchback" },
    // { name: "SUV", value: "suv" },
    // { name: "CONVERTIBLE", value: "convertible" },
    // { name: "PICKUP_TRUCK", value: "pickup truck" },
    // { name: "OTHER", value: "other" },
  ]);
  const [priceRange, setPriceRange] = useState([]);
  const [prices, setPrices] = useState([]);
  const [type, setType] = useState();
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [showPriceSlider, setShowPriceSlider] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  const [nextBrands, setNextBrands] = useState("");
  const [nextModels, setNextModels] = useState("");
  const [nextLoading, setNextLoading] = useState(false);
  const [car_details, setCar_details] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());

  useEffect(() => {
    setBrands(initialBrands);
    setLoading(initialLoading);
    setNextBrands(initialNextBrands);
  }, [initialBrands, initialLoading, initialNextBrands]);
  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);

  // useEffect(() => {
  //   const getBrands = async () => {
  //     try {
  //       setLoading(true);
  //       const res = await axiosCarvenui.get(`/brand?page_size=50`);
  //       const data = res.data.results.map((brand) => {
  //         return {
  //           ...brand,
  //           value: brand.id,
  //         };
  //       });
  //       setBrands(data);
  //       setNextBrands(res.data.next);
  //       setLoading(false);
  //       // setBrand(data[0].id);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getBrands();
  // }, []);

  const clearData = () => {
    // setTypes([]);
    setPrices([]);
    setPriceRange([]);
  };

  const getNextBrands = async () => {
    if (nextBrands !== "" && nextBrands !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextBrands.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setBrands([
        ...brands,
        ...res.data.results.map((brand) => ({
          ...brand,
          value: brand.id,
        })),
      ]);
      setNextBrands(res.data.next);
      setNextLoading(false);
    }
  };

  const getNextModels = async () => {
    if (nextModels !== "" && nextModels !== null && nextLoading == false) {
      setNextLoading(true);
      let query = nextModels.split("api/")[1];
      const res = await axiosCarvenui.get(query);
      setModels([
        ...models,
        ...res.data.results.map((model) => ({
          ...model,
          value: model.id,
        })),
      ]);
      setNextModels(res.data.next);
      setNextLoading(false);
    }
  };

  useEffect(() => {
    const getModels = async () => {
      try {
        const res = await axiosCarvenui.get(
          `car_model/?brand=${brand}&page_size=50`
        );
        const data = res.data.results.map((model) => {
          return {
            ...model,
            value: model.id,
          };
        });
        setModels(data);
        setNextModels(res.data.next);
      } catch (e) {}
    };
    if (brand !== undefined) {
      setModel();
      setModels([]);
      setType();
      clearData();
      getModels();
    }
  }, [brand]);

  useEffect(() => {
    if (models.length == 1) {
      setModel(models[0].value);
    } else {
      setModel();
    }
  }, [models]);

  useEffect(() => {
    const getCarDetails = async () => {
      try {
        let res;
        if (model) {
          res = await axiosCarvenui.get(`/car_detail/?car__model=${model}`);

          if (res) {
            let details = res.data.results;
            let types_arr = [];
            details.map((d) => {
              types_arr.push({
                name: t.body_types[d.car?.body_type],
                value: d.car?.body_type,
                name_ar: ar.body_types[d.car?.body_type],
              });
            });

            setCar_details(details);
            setTypes([...new Map(types_arr.map((t) => [t.name, t])).values()]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    setType();
    setTypes([]);
    setPrices([]);
    setPriceRange([]);
    setShowPrices(false);
    getCarDetails();
  }, [model]);

  useEffect(() => {
    if (types.length == 1) {
      setType(types[0].value);
    } else {
      setType();
    }
  }, [types]);

  useEffect(() => {
    if (type) {
      let prices_arr = [];

      let filtered = car_details.filter((d) => d.car.body_type == type);
      setFilteredDetails(filtered);
      filtered.map((d) => {
        prices_arr.push(+d.car_price);
      });
      console.log("prices", [Math.min(...prices_arr), Math.max(...prices_arr)]);
      setPrices([Math.min(...prices_arr), Math.max(...prices_arr)]);
      setPriceRange([Math.min(...prices_arr), Math.max(...prices_arr)]);
      setShowPrices(true);
    }
  }, [type, car_details]);

  const type_field = {
    name: "body_type",
    placeholder: t.intro.search.type,
    options: types,
    // disableArabic: true,
  };
  const brand_field = {
    name: "brand",
    placeholder: t.intro.search.brand,
    options: brands,
    // disableArabic: true,
  };
  const model_field = {
    name: "model",
    placeholder: t.intro.search.model,
    options: models,
    // disableArabic: true,
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
    if (!showPrices) {
      setShowPrices(true);
    }
  };

  const searchBrand = async (value) => {
    const res = await axiosCarvenui.get(
      "/brand?per_page=50&name__icontains=" + value
    );
    const data = res.data.results.map((brand) => {
      return {
        ...brand,
        value: brand.id,
      };
    });
    setBrands(data);
    setNextBrands(res.data.next);
  };

  // const [price, setPrice] = useState();
  return (
    <StyledIntro language={language}>
      <div className="intro">
        <img src={car_img} className="intro-img desktop-only" />
        <div className="intro-title">
          <p> {t.intro.title_l1}</p>
          <p> {t.intro.title_l2}</p>
        </div>
        <div className="intro-text desktop-only">
          <p> {t.intro.text_l1}</p>
          <p> {t.intro.text_l2}</p>
        </div>

        <div className="intro-text mobile-only">{t.intro.text}</div>

        <div className="search">
          <div className="search-inputs">
            <SelectMenu
              inputWidth="11.181vw"
              inputFont="Poppins"
              inputMobileFont="Inter"
              field={brand_field}
              value={brand}
              allowTyping={true}
              disabled={false}
              search={searchBrand}
              setValue={(value) => {
                setBrand(value);
              }}
              getPaginationData={getNextBrands}
            />
            <SelectMenu
              inputWidth="11.181vw"
              inputFont="Poppins"
              inputMobileFont="Inter"
              field={model_field}
              value={model}
              disabled={!brand}
              setValue={(value) => {
                setModel(value);
              }}
              getPaginationData={getNextModels}
            />
            <SelectMenu
              inputWidth="11.181vw"
              inputFont="Poppins"
              inputMobileFont="Inter"
              field={type_field}
              value={type}
              disabled={!model}
              setValue={(value) => {
                setType(value);
              }}
            />

            {/* <SelectMenu
              inputWidth="11.181vw"
              field={price_options}
              value={price}
              disabled={false}
              setValue={(value) => {
                setPrice(value);
              }}
            /> */}
          </div>
          <button
            disabled={!type}
            className="price"
            onClick={() => {
              setShowPriceSlider(!showPriceSlider);
            }}
          >
            {language == "ar" ? <div className="desktop-only"></div> : null}
            <div className="col">
              <p
                className={`text ${
                  showPrices && priceRange.length > 0 && "text-label"
                }`}
              >
                {t.intro.search.price}
              </p>
              {showPrices && priceRange.length > 0 && (
                <div className="values desktop-only">
                  {priceRange[0].toLocaleString()}
                  <span> SAR </span> - {priceRange[1].toLocaleString()}
                  <span> SAR </span>
                </div>
              )}
            </div>
            <SelectArrow
              style={
                showPriceSlider
                  ? {
                      transform: "scaleY(-1)",
                    }
                  : {}
              }
            />
          </button>
          <button
            className="search-btn"
            onClick={() => {
              console.log("search by values ", type, model, brand, priceRange);
              history.push("/cars", {
                brand: brand ? brand : null,
                model: model ? model : null,
                type: type ? type : null,
                maxPrice: priceRange.length > 0 ? priceRange[1] : null,
                minPrice: priceRange.length > 0 ? priceRange[0] : null,
              });
            }}
            // disabled={priceRange.length == 0}
          >
            <Search />
            <p>{t.intro.search.search}</p>
          </button>
        </div>
        {showPriceSlider && model && priceRange.length > 0 && (
          <div className="price-slider">
            <PriceSlider
              min={prices[0] ? prices[0] : priceRange[0]}
              max={prices[1] ? prices[1] : priceRange[1]}
              priceRange={priceRange}
              handlePriceChange={handlePriceChange}
            />
          </div>
        )}
      </div>
    </StyledIntro>
  );
};
const StyledIntro = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: 0 10.417vw;
  .intro {
    padding: 11.181vw 2.708vw 6.181vw 2.708vw;
    position: relative;
    background: #154cd6;
    border-radius: 1.389vw;
    width: 78.958vw;
    height: 42.083vw;
    background-image: url(${grid});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    .intro-img {
      position: absolute;
      width: 46.319vw;
      height: 24.028vw;
      left: ${(props) =>
        props.language === "en" ? "31.667vw" : desktopSizes._12px};
      top: 5.139vw;
      -webkit-transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
      transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
    }
    .intro-title {
      width: ${(props) => (props.language == "en" ? "30.972vw" : "25vw")};
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-weight: ${(props) => props.language == "en" && "700"};
        font-size: 3.194vw;
        line-height: 4.444vw;
        color: #ffffff;

        @media (min-width: 1440px) {
          font-size: 46px;
          line-height: 64px;
        }
      }
    }
    .intro-text {
      margin-top: 2.708vw;
      width: 30.972vw;
      height: 3.75vw;
      font-family: ${(props) =>
        props.language == "en" ? "Inter" : "GE-SS-Bold"};
      font-weight: ${(props) => props.language == "en" && "700"};
      color: #ffffff;
      p {
        font-family: ${(props) =>
          props.language == "en" ? "Inter" : "GE-SS-Bold"};
        font-weight: ${(props) => props.language == "en" && "700"};
        font-size: 1.389vw;
        line-height: 1.875vw;
        color: #ffffff;
        @media (min-width: 1440px) {
          font-size: 20px;
          line-height: 27px;
        }
      }
    }
    .search {
      width: 60.833vw;
      background: #fff;
      height: 4.306vw;
      background: #ffffff;
      // border: 0.069vw solid #ede8e9;
      border-radius: 0.278vw;
      margin-top: 5vw;
      display: flex;
      align-items: center;
      padding-left: 0.139vw;
      padding-right: 0.139vw;
      .search-inputs {
        display: flex;
        align-items: flex-start;
        .menu-container {
          width: 11.181vw;
        }
        input {
          padding: ${(props) =>
            props.language == "en"
              ? "1.458vw 1.667vw 1.25vw 1.25vw"
              : "1.458vw 1.25vw 1.25vw 1.667vw"};
          // height: 4.306vw;
          background-size: 0.694vw 0.694vw;
          color: #0d0a19;
          background-color: #fff;
          border: none;
          // border-radius: 0.278vw;
          border-right: ${(props) =>
            props.language == "en" && "0.069vw solid #ede8e9"};
          border-left: ${(props) =>
            props.language == "ar" && "0.069vw solid #ede8e9"};
          background-position-x: ${(props) =>
            props.language == "en" ? "91%" : "9%"};
          outline: none;
          font-weight: 500;
          font-size: 0.972vw;
          line-height: 1.458vw;
          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
        input::placeholder {
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
          font-style: normal;
          font-weight: 500;
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: #9a9ea7;
          opacity: 1;
          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .price {
        border-radius: 0.278vw;
        border: none;
        outline: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 18.056vw;
        padding: 0 1.111vw;
        cursor: pointer;
        .col {
          display: flex;
          flex-direction: column;
          gap: 0.208vw;
          .text {
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
            font-weight: 500;
            font-style: normal;
            font-size: 0.972vw;
            line-height: 1.458vw;
            color: #9a9ea7;
            color: #9a9ea7;
            text-align: start;
            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
            }
          }
          .text-label {
            font-size: ${desktopSizes._12px};
            @media (min-width: 1440px) {
              font-size: 12px;
            }
          }
          .values {
            direction: ltr;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 0.972vw;
            line-height: 1.458vw;
            color: #0d0a19;

            span {
              font-size: 0.694vw;
              line-height: 1.111vw;
            }

            @media (min-width: 1440px) {
              font-size: 14px;
              line-height: 21px;
              span {
                font-size: 10px;
                line-height: 16px;
              }
            }
          }
        }
        svg {
          width: 0.694vw;
          height: 0.694vw;
        }
      }
      .search-btn {
        &:disabled {
          opacity: 0.5;
        }
        direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
        flex-direction: ${(props) => props.language == "ar" && "row-reverse"};
        width: 8.333vw;
        height: 3.194vw;
        // margin-top: 0.556vw;
        background: #154cd6;
        border-radius: 0.278vw;
        gap: 0.417vw;
        cursor: pointer;
        p {
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-Bold"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
          font-size: 1.111vw;
          line-height: 1.458vw;
          color: #ffffff;
          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
        svg {
          width: 1.389vw;
          height: 1.389vw;
          @media (min-width: 1440px) {
            width: 20px;
            height: 20px;
          }
          fill: #ffffff;
          path {
            fill: #ffffff;
          }
        }
      }
    }
    .price-slider {
      position: absolute;
      left: 36.389vw;
      margin-top: 0.139vw;
    }
  }

  @media (max-width: 768px) {
    padding: 0 4.267vw;

    .intro {
      padding: 14.933vw 2.667vw ${mobileSizes._27px} 2.667vw;
      border-radius: 2.133vw;
      width: 91.467vw;
      height: auto;

      .intro-title {
        width: 86.133vw;
        p {
          font-size: 9.6vw;
          line-height: 14.4vw;
          font-weight: 600;
          text-align: center;
        }
      }
      .intro-text {
        margin-top: 4.267vw;
        width: 86.133vw;
        font-size: 4.267vw;
        line-height: 7.2vw;
        text-align: center;
        height: auto;
      }
      .search {
        width: 86.133vw;
        height: auto;
        border: none;
        border-radius: 2.133vw;
        margin-top: 4.267vw;
        display: flex;
        flex-direction: column;
        padding: 1.6vw 4.267vw 6.4vw 4.267vw;
        .search-inputs {
          display: flex;
          flex-direction: column;
          .menu-container {
            width: 77.6vw;
          }
          input {
            width: 77.6vw;
            background-size: 2.667vw 2.667vw;
            background-position: ${(props) =>
              props.language == "en" ? "100% 50%" : "0 50%"};
            background-color: #fff;
            border: 0;
            border-bottom: 0.267vw solid #eeeeee;
            padding: 4.8vw 0;
            // border-radius: 0.278vw;
            outline: none;
            font-weight: 500;
            font-size: 4.267vw;
            line-height: 5.067vw;
          }
          input::placeholder {
            font-family: ${(props) =>
              props.language == "en" ? "Inter" : "GE-SS-Meduim"};
            font-style: normal;
            font-weight: 500;
            font-size: 4.267vw;
            line-height: 5.067vw;

            color: #9a9ea7;
          }
        }
        .price {
          width: 77.6vw;
          border-bottom: 0.267vw solid #eeeeee;
          padding: ${mobileSizes._16px} 0;
          .col {
            display: flex;
            flex-direction: column;
            gap: 0.208vw;
            .text {
              font-family: ${(props) =>
                props.language == "en" ? "Inter" : "GE-SS-Meduim"};
              font-style: normal;
              font-weight: 500;
              font-size: 4.267vw;
              line-height: 5.067vw;
            }
            .text-label {
              font-size: ${mobileSizes._16px};
            }

            .values {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 0.972vw;
              line-height: 1.458vw;
              color: #0d0a19;
              span {
                font-size: 0.694vw;
                line-height: 1.111vw;
              }
            }
          }
          svg {
            width: 2.667vw;
            height: 2.667vw;
          }
        }
        .search-btn {
          width: 77.6vw;
          height: 14.933vw;
          margin-top: 4.267vw;
          border-radius: 2.133vw;

          gap: 1.067vw;
          cursor: pointer;
          p {
            font-size: 4.267vw;
            line-height: 5.067vw;
          }
          svg {
            width: 6.4vw;
            height: 6.4vw;
          }
        }
      }
      .price-slider {
        left: 6.933vw;
        bottom: 13.333vw;
        margin: 0;
        height: 18vw;
      }
    }
  }
`;

export default Intro;
