import React, { cloneElement, useState } from "react";
import { StyledContainer } from "../Account";
import AccTitle from "../../../Components/MyAccount/AccTitle";
import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import Button from "../../../Components/MyAccount/Button";
import { ReactComponent as LoginIcon } from "../../../images/Account/protect.svg";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { ButtonGroup, FormContainer, InfoCard, StyledBoxInput } from "../../../Components/MyAccount/CommonStyled";
import { ClosedEye, Eye } from "../../../Components/assets/Icons";
import { useForm } from "react-hook-form";
import { useUpdatePassword } from "../../../hooks/AccountHooks";

function LoginDetails() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();

  const { updatePassword, isLoading } = useUpdatePassword();

  const onSubmit = data => {
    delete data.confirm_password;
    updatePassword(data);
  };

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <AccTitle title={t.account.loginDetails} description={t.account.loginDetailsDesc} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <InputGroup>
            <Head>{t.account.changePassword}</Head>

            <PasswordInput label={t.account.currentPassword} errMessage={errors?.old_password?.message}>
              <input
                id="old_password"
                placeholder={t.account.enterCurrentPassword}
                {...register("old_password", {
                  required: t.thisFieldIsRequired,
                  minLength: { value: 6, message: t.account.passwordLength },
                })}
              />
            </PasswordInput>

            <PasswordInput label={t.account.newPassword} errMessage={errors?.new_password?.message}>
              <input
                id="new_password"
                placeholder={t.account.enterNewPassword}
                {...register("new_password", {
                  required: t.thisFieldIsRequired,
                  minLength: { value: 6, message: t.account.passwordLength },
                })}
              />
            </PasswordInput>

            <PasswordInput label={t.account.confirmPassword} errMessage={errors?.confirm_password?.message}>
              <input
                id="confirm_password"
                placeholder={t.account.enterConfirmPassword}
                {...register("confirm_password", {
                  required: t.thisFieldIsRequired,
                  minLength: { value: 6, message: t.account.passwordLength },
                  validate: value => value === getValues("new_password") || t.account.passwordMatch,
                })}
              />
            </PasswordInput>
          </InputGroup>
          <div>
            <InfoCard>
              <span>
                <LoginIcon />
              </span>
              <h4>{t.account.PasswordAndQuestions}</h4>
              <p>...</p>
            </InfoCard>
          </div>
        </div>
        <BtnGroup>
          <div>
            <Button onClick={() => navigate(-1)} type="button" variation="secondary">
              {t.cancel}
            </Button>
            <Button isLoading={isLoading}>{t.account.updateAccount}</Button>
          </div>
        </BtnGroup>
      </FormContainer>
    </StyledContainer>
  );
}

const PasswordInput = ({ children, label, errMessage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => setShowPassword(!showPassword);
  return (
    <div>
      <StyledBoxInput err={!!errMessage}>
        <label htmlFor="current-password">{label}</label>
        {cloneElement(children, { type: `${showPassword ? "text" : "password"}` })}
        <button onClick={togglePassword} type="button" className="password_icon">
          {showPassword ? <Eye /> : <ClosedEye />}
        </button>
      </StyledBoxInput>
      <Error>{errMessage}</Error>
    </div>
  );
};

export default LoginDetails;

const Head = styled.h3`
  margin-bottom: 1.5rem;
`;

const Error = styled.span`
  padding: 4px;
  color: #ff0000;
  font-size: 0.8rem;
`;

const BtnGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  margin-top: 2rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  @media (max-width: 996px) {
    gap: 1rem;
  }
`;
