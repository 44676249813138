import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledForm from "../../assets/StyledForm";
import key from "../../../images/auth/key.svg";

import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { desktopSizes, mobileSizes } from "../../../util/sizes";
import { useForm } from "react-hook-form";
import { otp_code } from "../../../hooks/Auth/forget-password/authFunctions";
import { send_otp } from "../../../hooks/Auth/signup/authFunctions";
import { formatTime } from "../../../util/formatTime";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../../images/close-icon.svg";

const VerifyAccount = ({ onSubmit, formData, setFormData, isDisabled }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const inputDir = language === "ar"? [4, 3, 2, 1]: [1, 2, 3, 4]

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [otpLoading, setOTPLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const phone_number = localStorage.getItem("phone_number");

  const [timeLeft, setTimeLeft] = useState(300); // 10 minutes in seconds

  const verifySubmit = handleSubmit(async (data) => {
    const otpForm = {
      otp: `${data.digit_1}${data.digit_2}${data.digit_3}${data.digit_4}`,
      phone_number: phone_number
    }

    console.log("Data Send : ", otpForm);

    setOTPLoading(true);

    try {
      const response = await otp_code(otpForm);
      console.log("RES:", response.data)
      if (response?.status === 200) {
        onSubmit();
        localStorage.setItem("otp", otpForm.otp);
      }
    } catch (err) {
      console.error("Error during otp:", err);
    } finally {
      setOTPLoading(false);
    }
  });

  const handleDigitChange = (e, digit) => {
    if (e.target.value !== "" && formData[`digit_${digit}`].length > 0) {
      return;
    }
    const value = e.target.value.replace(/\D/g, "");
    setFormData({
      ...formData,
      ["digit_" + digit]: value,
    });

    let name = e.target.name;
    let index = parseInt(name.split("_")[1]);
    // console.log("index", index);
    const nextfield = document.querySelector(`input[name=digit_${index + 1}]`);
    if (nextfield !== null && value !== "") {
      nextfield.focus();
    }
  };

  const onSubmitResend = async () => {
    if (phone_number) {
      setResendLoading(true)
      try {
        const response = await send_otp(phone_number);
        if (response?.status === 200) {
          // console.log("OTP Resent Data:", response);
          setTimeLeft(300)
          setResendLoading(false)
        } 
      } catch (err) {
        console.error("Error during login:", err);
        setResendLoading(false)
      }
    }
  };

  // Countdown timer logic
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const handleSkip = () => {
    localStorage.removeItem("phone_number");
    localStorage.removeItem("otp");
  }

  return (
    <StyledSection>
      <div className={`skip ${language}`} onClick={handleSkip}> 
        <Link to="/login">
            <span>{t.authPages.skip}</span>
            <span>
                <CloseIcon/>
            </span>
        </Link>
      </div>
      <div className="container">
        <img src={key} className="section-icon" alt="img" />
        <div className="title">
          <p>{t.authPages.forget_password_page.verify.title_l1}</p>
          <p>{t.authPages.forget_password_page.verify.title_l2}</p>
        </div>
        <div className="text desktop-only">
          {t.authPages.forget_password_page.verify.text}
        </div>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            // onSubmit();
          }}
        >
          <div className="fields digits-fields">
            {inputDir.map((digit) => (
              <div className="input-div" key={`digit_${digit}`}>
                <input
                  className={`${errors[`digit_${digit}`]?.message ? "input-error" : ""
                    }`}
                  name={`digit_${digit}`}
                  placeholder="-"
                  // type={"number"}
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="1"
                  oninput="this.value=this.value.replace(/[^0-9]/g,''"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  required={true}
                  onInput={(e) => {
                    handleDigitChange(e, digit);
                  }}
                  {...register(`digit_${digit}`, {
                    valueAsNumber: true,
                    required: {
                      value: true,
                      message: "required",
                    },
                    validate: (value) => {
                      if (!isNaN(value) || formData[`digit_${digit}`]) {
                        return true;
                      } else return "required";
                    },

                    onChange: (e) => {
                      if (
                        e.target.value !== "" &&
                        formData[`digit_${digit}`].length > 0
                      ) {
                        return;
                      }
                      const value = e.target.value.replace(/\D/g, "");
                      setFormData({
                        ...formData,
                        ["digit_" + digit]: value,
                      });
                    },
                  })}
                  value={formData[`digit_${digit}`]}
                />
              </div>
            ))}
          </div>
          <div className="btn-container">
            <button
              className="submit"
              type="submit"
              onClick={verifySubmit}
              disabled={otpLoading}
            >
              {t.authPages.forget_password_page.verify.verify}
            </button>
          </div>
        </StyledForm>

        <StyledParagraph timeLeft={timeLeft}>
          If you didn't receive the code, Click{" "}
          <span
            onClick={() => {
              if (timeLeft === 0) {
                onSubmitResend();
              }
            }}
            disabled={timeLeft > 0 || resendLoading}
          >
            Resend Code
          </span>{" "}
          In <span>{formatTime(timeLeft)}</span>
        </StyledParagraph>
      </div>
    </StyledSection>
  );
};

const StyledSection = styled.div`

  

  .container {
    form {
      .digits-fields {
        display: flex;
        flex-direction: row;
        gap: 0.764vw;

        .input-div {
          input {
            width: 6.736vw;
            font-size: 1.389vw;
            line-height: 1.458vw;
            color: #0d0a19;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            @media (min-width: 1440px) {
              font-size: 20px;
              line-height: 21px;
            }
          }
          .input-error {
            border: ${desktopSizes._1px} solid #eb0100;
          }
          input::placeholder {
            font-weight: 400;
            font-size: 0.833vw;
            line-height: 1.458vw;
            color: #9a9ea7;
            @media (min-width: 1440px) {
              font-size: 12px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      form {
        .digits-fields {
          gap: ${mobileSizes._12px};

          .input-div {
            input {
              width: 20.533vw;
              padding: ${mobileSizes._18px} 8.8vw;
              font-family: Poppins;
              font-weight: 500;
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._24px};
            }
            .input-error {
              border: ${mobileSizes._1px} solid #eb0100;
            }
            input::placeholder {
              font-weight: 400;
              font-size: ${mobileSizes._12px};
              line-height: ${mobileSizes._21px};
            }
          }
        }
      }
    }
  }
`;
const StyledParagraph = styled.p`
    color: #9A9EA7;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;

    span {
        font-weight: 600;
        line-height: 20px;
        font-size: 14px;

        &:nth-child(1) {
        color: ${(props) => (props.timeLeft > 0 ? "#9A9EA7" : "#154CD0")};
        cursor: ${(props) => (props.timeLeft > 0 ? "not-allowed" : "pointer")};
        }

        &:nth-child(2) {
        font-weight: 700;
        color: #0D0A19;
        }
    }
`;
export default VerifyAccount;
