import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import bannerImage from "../../images/Account/acc-banner.png";
import { ReactComponent as InfoIcon } from "../../images/Account/info.svg";
import { ReactComponent as LoginIcon } from "../../images/Account/protect.svg";
import { ReactComponent as PaymentIcon } from "../../images/Account/dollar.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import AuthContext from "../../hooks/Auth/providers/AuthContext";

const cardData = [
  {
    icon: InfoIcon,
    title: "accInfo",
    description: "accInfoDesc",
    to: "/account/account-info",
  },
  {
    icon: LoginIcon,
    title: "loginDetails",
    description: "loginDetailsDesc",
    to: "/account/login-details",
  },
  {
    icon: PaymentIcon,
    title: "expenses",
    description: "expensesDesc",
    to: "/account/payment-plans",
  },
];

function Account() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const { user } = useContext(AuthContext);

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <BannerContainer>
        <StyledImage src={bannerImage} alt="account banner" />
        <AccInfo className="acc-info">
          <img src="https://avatar.iran.liara.run/public/boy" alt="avatar" />
          <h3>{user?.name}</h3>
          <p>{user?.email}</p>
        </AccInfo>
      </BannerContainer>
      <CardContainer>
        {cardData.map(({ icon: Icon, description, title, to }, index) => (
          <CardLink to={to} key={index}>
            <span>
              <Icon className="icon" />
            </span>
            <div>
              <h4>{t.account[title]}</h4>
              <p>{t.account[description]}</p>
            </div>
          </CardLink>
        ))}
      </CardContainer>
    </StyledContainer>
  );
}

export default Account;
export { bannerImage };

export const StyledContainer = styled.div`
  width: 78vw;
  margin: 16px auto 100px auto;
  font-family: ${props => (props.language === "en" ? "Inter" : "GE-SS-Bold")} !important;
  & * {
    font-family: inherit;
  }
`;

const BannerContainer = styled.div`
  color: white;
  position: relative;
`;

const AccInfo = styled.div`
  position: absolute;
  top: 2rem;
  inset-inline-start: 2rem;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  p {
    padding-top: 0.2rem;
    font-size: 0.8rem;
    color: #f1f1f1;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  gap: 2rem;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const CardLink = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  justify-content: start;
  align-items: center;
  color: #333;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  span {
    background-color: #f1f1f1;
    padding: 1rem 1.3rem;
    border-radius: 10px;
  }
  &:hover span {
    background-color: #0044ff33;
    transition: all 0.5s ease;
  }
  &:hover .icon {
    fill: #0044ff;
    transition: all 0.5s ease;
  }
  div {
    line-height: 1.6;
    p {
      font-size: 0.8rem;
      color: #818181;
    }
  }
`;
