import React from 'react'
import { useParams } from 'react-router'
import { useGetBlogById } from '../../hooks/blog/blogHooks';
import Spin from '../../Components/assets/Spin';
import styled from "styled-components";
import BlogBlock from '../../Components/Blogs/BlogBlock';
import RelatedBlog from '../../Components/Blogs/RelatedBlog';

const Blog = () => {

    const { blogId } = useParams()

    const { isFetching, data: blog, error } = useGetBlogById(blogId);

    if (isFetching) return (
        <StyledSection><div className='spin-page'><Spin /></div></StyledSection>
    );
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <BlogBlock blog={blog}/>
            <RelatedBlog/>
        </div>
    )
}

const StyledSection = styled.div`
    direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
    
    .spin-page {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 400px;
    }
`;

export default Blog