import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHandleClick } from "../../hooks/useHandleClick";

import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

function SelectDropDown({ options, variation, value, onChange, isLoading, label, errMessage }) {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  const ref = useHandleClick(() => setIsOpen(false));
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;

  const handleSelect = selectedValue => {
    onChange(selectedValue);
    setIsOpen(false);
    setSelectedLabel(options.find(option => option.value === selectedValue).label);
  };


  useEffect(() => {
    if (value && options) {
      const selectedOption = options?.find(option => option.value === value);
      if (!selectedOption) {
        setSelectedLabel(null);
        onChange(null);
      }
    }
  }, [onChange, options, value]);

  useEffect(() => {
    if (value && options) {
      const selectedOption = options.find(option => option.value === value);
      if (selectedOption) {
        setSelectedLabel(selectedOption.label);
      }
    }
  }, [value, options]);

  const dropdownWidth = buttonRef.current ? buttonRef.current.offsetWidth : "auto";

  return (
    <DropdownContainer variation={variation}>
      {label && <Label>{label}</Label>}
      <div ref={ref} style={{ width: "100%", position: "relative" }}>
        <TriggerButton onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
          <span>{selectedLabel || t.select}</span>
          {isLoading ? <span>Loading...</span> : <StyledArrowIcon isOpen={isOpen} />}
        </TriggerButton>
        {isOpen && (
          <DropdownMenu width={dropdownWidth}>
            <ul>
              {options?.map(
                (option, index) =>
                  !option.hidden && (
                    <OptionItem key={index} onClick={() => handleSelect(option.value)}>
                      {option.label}
                    </OptionItem>
                  )
              )}
              {options?.length === 0 && <OptionItem>{"No data"}</OptionItem>}
            </ul>
          </DropdownMenu>
        )}
      </div>
      <ErrorMessage ErrorMessage>{errMessage}</ErrorMessage>
    </DropdownContainer>
  );
}

export default memo(SelectDropDown);

// Styled Components
const DropdownContainer = styled.div`
  margin: 1rem 0;
  display: ${({ variation }) => (variation === "row" ? "flex" : "block")};
  width: ${({ variation }) => (variation === "row" ? "auto" : "100%")};
  align-items: ${({ variation }) => (variation === "row" ? "center" : "unset")};
  gap: 1rem;
`;

const Label = styled.div`
  padding-bottom: 0.6rem;
  padding-top: 0.4rem;
  z-index: 0;
  font-size: 13px;
  color: #4f4f4f;
`;

const TriggerButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
  border: 2px solid #e5e5e5;
  border-radius: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;

  cursor: pointer;
  z-index: 1;
  background-color: white;

  &:focus {
    border-color: ${({ error }) => (error ? "red" : "black")};
  }
  span {
    color: #4f4f4f;
  }
`;

const DropdownMenu = styled.div`
  width: ${({ width }) => `${width}px` || "auto"};
  position: absolute;
  top: 100%;
  background-color: white;
  z-index: 10;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 15rem;
  overflow-y: auto;
`;

const OptionItem = styled.li`
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-weight: 500;
  font-size: 14px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
`;
