import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import en from '../../util/locales/en';
import ar from '../../util/locales/ar';
import { BlogCard } from '../Home/ItemCard';
import { useGetAllBlogs } from '../../hooks/blog/blogHooks';
import CardSkeleton from '../assets/Skeleton/CardSkeleton';
import styled from "styled-components";

const LatestBlog = () => {

    const language = useSelector((state) => state.language);
    const t = language === "en" ? en : ar;

    const [query, setQuery] = useState({
        page: 1,
        page_size: 2,
        ordering: "-created_at"
    });

    const { isFetching, data: latest_reviews } = useGetAllBlogs(query);

    console.log("latest_reviews: ", latest_reviews)

    if (isFetching) {
        return (
            <LoadingPage>
                <CardSkeleton/>
                <CardSkeleton/>
            </LoadingPage>
        )
    }

    return (
        <div className="reviews">
            
            <div className="list latest">
                {latest_reviews?.results?.map((r, i) => (
                    <BlogCard item={r} key={i} page={"carReviews"} withDate/>
                ))}
            </div>
        </div>
    )
}

const LoadingPage = styled.div`
    display:flex;
    flex-direction:column;
    gap: 20px;
`;

export default LatestBlog