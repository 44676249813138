
import { axiosCarvenui } from '../../../util/integration';
import toast from 'react-hot-toast';

export const login = async ({ phonenumber, password }) => {
    try {
        const response = await axiosCarvenui.post('token/', {
            phonenumber,
            password,
        });
        toast.success('Successfully Login')
        return response.data;
    } catch (error) {
        console.error('Login failed:', error);
        toast.error(error.response.data.detail)
        throw error;
    }
};


export const refreshTokenHandler = async (accessToken, refreshToken, currentDecoded) => {
    const currentTime = Date.now() / 1000;
    const tokenExpiryTime = currentDecoded.exp;


    // Check if the access token is expired
    if (currentTime > tokenExpiryTime) {
        try {
            const response = await axiosCarvenui.post('/token/refresh/', { refresh: refreshToken });
            console.log('refreshTokenHandler : ', response)
            const { access, refresh } = response.data;

            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);

            return { access, refresh };
        } catch (error) {
            console.error("Error refreshing token:", error);
            return null;
        }
    }

    // If the token is not expired, return the existing tokens
    return { access: accessToken, refresh: refreshToken };
};
