import React, { useEffect } from "react";
import Hero from "../Components/Seller/Hero";
import useApi from "../hooks/useApi";
import SellerBrands from "../Components/Seller/SellerBrands";
import styled from "styled-components";
import ar from "../util/locales/ar";
import en from "../util/locales/en";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import SellerCars from "../Components/Seller/SellerCars";
import SellerBanks from "../Components/Seller/SellerBanks";
import WhySeller from "../Components/Seller/WhySeller";
import ContactUs from "../Components/Seller/ContactUs";

function SellerPage() {
  const { id } = useParams();
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const { get, loading, data } = useApi();

  useEffect(() => {
    get(`/seller/${id}`);
  }, [get, id]);


  return (
    <Container language={language} dir={language === "ar" ? "rtl" : "ltr"}>
      <Hero isLoading={loading} seller={data} language={language} t={t} />
      <SellerBrands sellerId={id} language={language} t={t} />
      <SellerCars sellerId={id} language={language} t={t} />
      <SellerBanks language={language} t={t} />
      <WhySeller language={language} t={t} seller={data} />
      <ContactUs seller={data} t={t} />
    </Container>
  );
}

export default SellerPage;

const Container = styled.div`
  font-family: ${props => (props.language === "en" ? "Poppins" : "GE-SS-Bold")};
  & * {
    font-family: inherit;
  }
`;
