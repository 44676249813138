import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const BrandCard = ({ brand }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledCard language={language}>
      <img src={brand.logo} className="logo" />
      <div className="info">
        <p className="name">{language == "en" ? brand.name : brand.name_ar}</p>
        <p className="cars-count">
          {brand.cars_count || 5} {t.brands.carsAvalibale}
        </p>
      </div>
    </StyledCard>
  );
};
const StyledCard = styled.div`
  width: 18.264vw;
  height: 5.556vw;
  display: flex;
  align-items: center;
  gap: 1.389vw;
  background: #ffffff;
  border: 0.069vw solid #f1f1f1;
  border-radius: 0.278vw;
  padding: 1.111vw 1.806vw;
  .logo {
    width: 2.778vw;
    height: 2.778vw;
    object-fit: contain;
  }
  .info {
    .name {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
      font-size: 1.25vw;
      line-height: 1.875vw;
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 21px;
        line-height: 27px;
      }
    }
    .cars-count {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
      font-style: normal;
      font-weight: 400;
      font-size: 0.972vw;
      line-height: 1.458vw;
      color: #9a9ea7;

      @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  @media (max-width: 768px) {
    width: 44.533vw;
    height: 16.533vw;
    padding: ${mobileSizes._12px};
    gap: ${mobileSizes._10px};
    border: ${mobileSizes._1px} solid #e4e6e8;
    border-radius: ${mobileSizes._8px};
    box-shadow: none;
    .logo {
      width: 7.467vw;
      height: 7.467vw;
      object-fit: contain;
    }
    .info {
      .name {
        font-size: ${mobileSizes._14px};
        line-height: ${mobileSizes._21px};
        color: #0d0a19;
        width: 27.467vw;
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .cars-count {
        font-size: ${mobileSizes._12px};
        line-height: ${mobileSizes._18px};
      }
    }
  }
`;
export default BrandCard;
