import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Search } from "../../images/search.svg";
import { axiosCarvenui } from "../../util/integration";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const SidebarFilter = ({
  homeBrand,
  homeShape,
  brands,
  brandSearch,
  transmissions,
  shapes,
  fuelTypes,
  engineCapacities,
  selectedBrands,
  selectedTransmissions,
  setSelectedBrands,
  setSelectedTransmissions,
  selectedShapes,
  setSelectedShapes,
  selectedFuelTypes,
  setSelectedFuelTypes,
  selectedEngineCapacities,
  setSelectedEngineCapacities,
  minPrice,
  setMinPrice,
  maxPrice,
  setMaxPrice,
  handleFilter,
  getAllBrands,
  brandsCount,
  isMobile,
  collapseBrands,
  setCollapseBrands,
  searchKey,
  setSearchKey,
  sortBrands,
  ...props
}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const isCleared = () => {
    return (
      selectedBrands.size == 0 &&
      selectedTransmissions.size == 0 &&
      selectedEngineCapacities.size == 0 &&
      selectedFuelTypes.size == 0 &&
      selectedShapes.size == 0 &&
      (minPrice == "" || minPrice == undefined) &&
      (maxPrice == "" || maxPrice == undefined)
    );
  };

  // const [cleared, setCleared] = useState(isCleared());
  // const toggleBrandCheckbox = (brand) => {
  //   if (selectedBrand.id == brand.id) {
  //     setSelectedBrand({});
  //   } else {
  //     setSelectedBrand(brand);
  //   }
  // };

  const toggleBrandCheckbox = (brand) => {
    if (selectedBrands.has(brand)) {
      const prev = new Set(selectedBrands);
      prev.delete(brand);
      setSelectedBrands(prev);
    } else {
      setSelectedBrands((prev) => new Set(prev).add(brand));
    }
  };

  const toggleTransimissionCheckbox = (transmission) => {
    if (selectedTransmissions.has(transmission)) {
      const prev = new Set(selectedTransmissions);
      prev.delete(transmission);
      setSelectedTransmissions(prev);
    } else {
      setSelectedTransmissions((prev) => new Set(prev).add(transmission));
    }
  };

  const toggleShapesCheckbox = (shape) => {
    if (selectedShapes.has(shape)) {
      const prev = new Set(selectedShapes);
      prev.delete(shape);
      setSelectedShapes(prev);
    } else {
      setSelectedShapes((prev) => new Set(prev).add(shape));
    }
  };

  const toggleFuelTypesCheckbox = (type) => {
    if (selectedFuelTypes.has(type)) {
      const prev = new Set(selectedFuelTypes);
      prev.delete(type);
      setSelectedFuelTypes(prev);
    } else {
      setSelectedFuelTypes((prev) => new Set(prev).add(type));
    }
  };

  const toggleEngineCapacitiesCheckbox = (capacity) => {
    if (selectedEngineCapacities.has(capacity)) {
      const prev = new Set(selectedEngineCapacities);
      prev.delete(capacity);
      setSelectedEngineCapacities(prev);
    } else {
      setSelectedEngineCapacities((prev) => new Set(prev).add(capacity));
    }
  };

  const clearFilters = () => {
    setMaxPrice();
    setMinPrice();
    setSelectedBrands(new Set());
    setSelectedEngineCapacities(new Set());
    setSelectedEngineCapacities(new Set());
    setSelectedFuelTypes(new Set());
    setSelectedShapes(new Set());
    setSelectedTransmissions(new Set());
  };

  // useEffect(() => {
  //   setCleared(isCleared());
  // }, [
  //   selectedBrands,
  //   selectedTransmissions,
  //   selectedEngineCapacities,
  //   selectedFuelTypes,
  //   selectedShapes,
  //   minPrice,
  //   maxPrice,
  // ]);

  useEffect(() => {
    if (selectedBrands.size == 1) {
      if (homeBrand !== undefined && homeBrand !== null) {
        const [first] = selectedBrands;
        if (first.id == homeBrand) {
          const element = document.getElementById(homeBrand);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }
        }
      }
    } else if (selectedShapes.size == 1) {
      if (homeShape !== undefined && homeShape !== null) {
        const [first] = selectedShapes;
        if (first == homeShape) {
          const element = document.getElementById(`s-${homeShape}`);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({
              behavior: "smooth",
              // block: "nearest",
              inline: "start",
            });
          }
        }
      }
    }
  }, [selectedBrands, selectedShapes]);

  return (
    <StyledSidebar collapseBrands={collapseBrands} language={language}>
      <div className="sidebar-header">
        <div className="title">{t.carsFilter.detailedSearch}</div>
        <button className="clear" onClick={clearFilters} disabled={isCleared()}>
          {isMobile ? t.carsFilter.clear.split(" ")[0] : t.carsFilter.clear}
        </button>
      </div>
      <div className="filters">
        <div className="filter-section">
          {brands && (
            <>
              <div className="filter-title">{t.carsFilter.carBrand}</div>
              <div className="search-form">
                <input
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  className="search-input"
                  placeholder={t.carsFilter.searchforBrand}
                  id="search-brand"
                />
                <div className="search">
                  <Search />
                </div>
              </div>
              <div className="list brand-list" id="brands-list">
                {brands
                  .slice(0, collapseBrands ? 7 : brands.length)
                  .map((brand) => (
                    <div className="list-item">
                      <div className="brand">
                        <img src={brand.logo} />
                        <label for={brand.id}>
                          {language == "en" ? brand.name : brand.name_ar}
                        </label>
                      </div>
                      <input
                        id={brand.id}
                        type="checkbox"
                        checked={selectedBrands.has(brand)}
                        onChange={() => {
                          toggleBrandCheckbox(brand);
                        }}
                      />
                    </div>
                  ))}
              </div>
              {brandsCount > 7 && (
                <button
                  className="view-all"
                  onClick={() => {
                    if (collapseBrands && brands.length == 7) {
                      getAllBrands();
                    } else {
                      sortBrands(brands);
                    }
                    if (!collapseBrands) {
                      const element = document.getElementById("search-brand");
                      if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "start",
                        });
                      }
                    }
                    setCollapseBrands(!collapseBrands);
                  }}
                >
                  {collapseBrands
                    ? t.carsFilter.viewAllBrands
                    : t.carsFilter.viewLess}
                </button>
              )}
            </>
          )}
        </div>
        <div className="filter-section">
          <div className="filter-title">{t.carsFilter.price}</div>
          <div className="price-inputs">
            <div className="input-div">
              <input
                type={"number"}
                placeholder={t.carsFilter.from}
                className="price-input"
                id="min"
                value={minPrice || ""}
                onChange={(e) => {
                  setMinPrice(e.target.value);
                }}
              />
              <label for="min">{t.sar}</label>
            </div>
            <div className="input-div">
              <input
                type={"number"}
                placeholder={t.carsFilter.to}
                className="price-input"
                id="max"
                value={maxPrice || ""}
                onChange={(e) => {
                  setMaxPrice(e.target.value);
                }}
              />
              <label for="max">{t.sar}</label>
            </div>
          </div>
        </div>
        <div className="filter-section">
          {transmissions && transmissions.length > 0 && (
            <>
              <div className="filter-title">{t.carsFilter.transmission}</div>
              <div className="list">
                {transmissions.map((transmission) => (
                  <div className="list-item" key={transmission}>
                    <label>
                      {t.transmissions[transmission] || transmission}
                    </label>
                    <input
                      type="checkbox"
                      id={`t-${transmission}`}
                      checked={selectedTransmissions.has(transmission)}
                      onChange={() => {
                        toggleTransimissionCheckbox(transmission);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        <div className="filter-section" id="body-shapes">
          {shapes && shapes.length > 0 && (
            <>
              <div className="filter-title">{t.carsFilter.bodyShape}</div>
              <div className="list">
                {shapes.map((shape) => (
                  <div className="list-item" key={shape}>
                    <label>
                      {language == "en" ? shape : ar.body_types[shape] || shape}
                    </label>
                    <input
                      type="checkbox"
                      id={`s-${shape}`}
                      checked={selectedShapes.has(shape)}
                      onChange={() => {
                        toggleShapesCheckbox(shape);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="filter-section">
          {fuelTypes && fuelTypes.length > 0 && (
            <>
              <div className="filter-title">{t.carsFilter.fuelType}</div>
              <div className="list">
                {fuelTypes.map((type) => (
                  <div className="list-item" key={type}>
                    <label>{t.fuel_types ? t.fuel_types[type] : type}</label>
                    <input
                      type="checkbox"
                      id={`f-${type}`}
                      checked={selectedFuelTypes.has(type)}
                      onChange={() => {
                        toggleFuelTypesCheckbox(type);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="filter-section">
          {fuelTypes && fuelTypes.length > 0 && (
            <>
              <div className="filter-title">{t.carsFilter.engineCapacity}</div>
              <div className="list">
                {engineCapacities.map((capacity) => (
                  <div className="list-item" key={capacity}>
                    <label className="capacity-label">{capacity + " CC"}</label>
                    <input
                      type="checkbox"
                      id={capacity}
                      checked={selectedEngineCapacities.has(capacity)}
                      onChange={() => {
                        toggleEngineCapacitiesCheckbox(capacity);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="btn-container">
        <button className="submit-btn" onClick={handleFilter}>
          View Result
        </button>
      </div> */}
    </StyledSidebar>
  );
};

const StyledSidebar = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  width: 25vw;
  background: #ffffff;
  border: 0.069vw solid #e4e6e8;
  border-radius: 0.278vw;
  padding: 2.222vw 0 1.667vw;
  .sidebar-header {
    padding: 0 1.111vw;
    margin-bottom: 1.25vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
      font-size: 1.389vw;
      line-height: 2.222vw;
      color: #0d0a19;

      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 32px;
      }
    }

    .clear {
      cursor: pointer;
      border: none;
      background: none;
      font-family: ${(props) =>
        props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
      font-size: 1.111vw;
      line-height: 2.222vw;
      color: #eb0100;
      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 32px;
      }
    }
    .clear: disabled {
      opacity: 0.4;
    }
  }
  .filters {
    // padding: 0 1.111vw;
    // height: 40vw;
    height: calc(100vh - 22vw);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 1.667vw;
    // margin-bottom: 1.667vw;
    scrollbar-color: #154cd6 #f3efe9;
    // scrollbar-width: .3vw;
    scrollbar-width: thin;
    /* Customizing scroll bar */
    ::-webkit-scrollbar {
      cursor: pointer;
      width: 0.3vw;
      height: 1vw;

      @media (max-width: 768px) {
        width: 1.7vw;
      }
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f3efe9;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #154cd6;
      border-radius: 1vw;
      @media (max-width: 768px) {
        border-radius: 3vw;
      }
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      /* background: #154CD6; */
    }

    .filter-section {
      padding: 0 1.111vw 1.667vw 1.111vw;
      border-bottom: 0.069vw solid #e4e6e8;
      .filter-title {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        font-size: 1.111vw;
        line-height: 2.222vw;
        color: #0D0A1;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 32px;
        }
      }
      .search-form {
        position: relative;
        margin-top: 1.111vw;
        .search-input {
          width: 22.778vw;
          height: 4.306vw;
          background: #ffffff;
          border: 0.069vw solid #e4e6e8;
          border-radius: 0.556vw;
          font-weight: 500;
          font-size: 1.111vw;
          line-height: 2.222vw;
          color: #0d0a19;
          padding: 1.111vw;
          padding-right: ${(props) => props.language == "en" && " 3.125vw"};
          padding-left: ${(props) => props.language == "ar" && " 3.125vw"};

          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 32px;
          }
        }
        .search-input::placeholder {
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
          font-size: 1.111vw;
          line-height: 2.222vw;
          color: #0d0a19;
          opacity: 0.5;

          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 32px;
          }
        }
        .search {
          position: absolute;
          border: none;
          background: none;
          left: ${(props) => props.language == "en" && "20vw"};
          right: ${(props) => props.language == "ar" && "20vw"};
          top: 1.319vw;
          svg {
            width: 1.667vw;
            height: 1.667vw;
            @media (min-width: 1440px) {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .view-all {
        margin-top: 1.667vw;
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        font-size: 1.111vw;
        line-height: 1.319vw;
        color: #154cd6;
        border: none;
        background: none;
        cursor: pointer;

        @media (min-width: 1440px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .price-inputs {
        display: flex;
        align-items: center;
        gap: 0.556vw;
        margin-top: 1.111vw;
        .input-div {
          position: relative;

          input {
            background: #ffffff;
            border: 0.069vw solid #e4e6e8;
            border-radius: 0.556vw;
            width: 11.111vw;
            height: 4.306vw;
            padding: ${(props) =>
              props.language == "en"
                ? "1.528vw 3.125vw 1.528vw 1.111vw"
                : "1.528vw 1.111vw  1.528vw 3.125vw"};
            font-weight: 400;
            font-size: 1.111vw;
            line-height: 1.319vw;
            color: #0d0a19;

            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
          input::placeholder {
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
            font-style: normal;
            font-weight: ${(props) => (props.language == "en" ? "400" : "300")};
            font-size: 1.111vw;
            line-height: 1.319vw;
            color: #0d0a19;
            opacity: 0.5;
            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
          label {
            position: absolute;
            left: ${(props) => props.language == "en" && "8.333vw"};
            right: ${(props) => props.language == "ar" && "8.333vw"};
            top: 1.667vw;
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
            font-style: normal;
            font-weight: 500;
            font-size: 0.833vw;
            line-height: 1.042vw;
            color: #0d0a19;
            @media (min-width: 1440px) {
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 1.319vw;
        margin-top: 1.667vw;
        .list-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          input {
            cursor: pointer;
            border: 0.069vw solid #e4e6e8;
            border-radius: 0.139vw;
            width: ${desktopSizes._20px};
            height: ${desktopSizes._20px};
            @media (min-width: 1440px) {
              width: 20px;
              height: 20px;
            }
          }

          label {
            cursor: pointer;
            font-family: ${(props) =>
              props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
            font-style: normal;
            font-weight: ${(props) => (props.language == "en" ? "500" : "400")};
            font-size: 1.111vw;
            line-height: 1.667vw;
            color: #0d0a19;

            @media (min-width: 1440px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .capacity-label {
            direction: ltr;
            font-family: "Poppins";
            font-weight: 500;
          }
          .brand {
            display: flex;
            align-items: center;
            gap: 1.111vw;

            img {
              width: 2.222vw;
              height: 2.222vw;
              object-fit: contain;

              @media (min-width: 1440px) {
                width: 32px;
                height: 32px;
              }
            }
          }
        }
      }
      // .brand-list {
      //   // height: ${(props) => (props.collapseBrands ? "22.57vw" : "auto")};
      //   // overflow: hidden;
      // }
    }
    .filter-section:last-of-type {
      border: 0;
      padding: 0 1.111vw;
    }
  }
  .btn-container {
    padding: 0.556vw 1.111vw;
    background: #ffffff;
    .submit-btn {
      width: 22.6vw;
      height: 3.194vw;
      cursor: pointer;
      border: none;
      background: #154cd6;
      color: #ffffff;
      border-radius: 0.278vw;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 1.111vw;
      line-height: 1.458vw;

      @media (min-width: 1440px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 91.467vw;
    border: ${mobileSizes._1px} solid #e4e6e8;
    border-radius: ${mobileSizes._4px};
    padding: ${mobileSizes._32px} 0 ${mobileSizes._24px} 0;
    .sidebar-header {
      padding: 0 ${mobileSizes._16px};
      margin-bottom: ${mobileSizes._16px};
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._32px};
      }

      .clear {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._32px};
      }
    }
    .filters {
      height: auto;
      gap: ${mobileSizes._24px};
      margin-bottom: 0;

      .filter-section {
        padding: 0 ${mobileSizes._16px} ${mobileSizes._24px}
          ${mobileSizes._16px};
        border-bottom: ${mobileSizes._1px} solid #e4e6e8;
        .filter-title {
          font-size: ${mobileSizes._14px};
          line-height: ${mobileSizes._32px};
        }
        .search-form {
          margin-top: ${mobileSizes._16px};
          .search-input {
            width: 82.933vw;
            height: 16.533vw;
            border: ${mobileSizes._1px} solid #e4e6e8;
            border-radius: ${mobileSizes._8px};
            font-size: ${mobileSizes._16px};
            line-height: ${mobileSizes._32px};
            padding: ${mobileSizes._16px};
            padding-right: 12.8vw;
          }
          .search-input::placeholder {
            font-size: ${mobileSizes._16px};
            line-height: ${mobileSizes._32px};
          }
          .search {
            left: ${(props) => props.language == "en" && "72.267vw"};
            right: ${(props) => props.language == "ar" && "72.267vw"};
            top: ${mobileSizes._19px};
            svg {
              width: ${mobileSizes._24px};
              height: ${mobileSizes._24px};
            }
          }
        }
        .view-all {
          margin-top: ${mobileSizes._24px};
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: ${mobileSizes._16px};
          line-height: ${mobileSizes._19px};
        }
        .price-inputs {
          gap: ${mobileSizes._16px};
          margin-top: ${mobileSizes._16px};
          .input-div {
            input {
              border: ${mobileSizes._1px} solid #e4e6e8;
              border-radius: ${mobileSizes._8px};
              width: 39.2vw;
              height: 16.533vw;
              padding: ${(props) =>
                props.language == "en"
                  ? `${mobileSizes._21px} 13.333vw ${mobileSizes._21px}
              ${mobileSizes._16px}`
                  : `  ${mobileSizes._21px}  ${mobileSizes._16px}  ${mobileSizes._21px}
                 13.333vw`};
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._19px};
            }
            input::placeholder {
              font-weight: 400;
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._19px};
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }
            label {
              position: absolute;
              left: ${(props) => props.language == "en" && "28.533vw"};
              right: ${(props) => props.language == "ar" && "28.533vw"};
              top: ${mobileSizes._24px};
              font-weight: 500;
              font-size: ${mobileSizes._12px};
              line-height: ${mobileSizes._15px};
            }
          }
        }

        .list {
          gap: ${mobileSizes._19px};
          margin-top: ${mobileSizes._24px};
          .list-item {
            input[type="checkbox"] {
              border: ${mobileSizes._1px} solid #e4e6e8;
              border-radius: ${mobileSizes._2px};
              width: ${mobileSizes._20px};
              height: ${mobileSizes._20px};
            }

            label {
              font-size: ${mobileSizes._16px};
              line-height: ${mobileSizes._24px};
            }
            .brand {
              display: flex;
              align-items: center;
              gap: ${mobileSizes._12px};

              img {
                width: ${mobileSizes._24px};
                height: ${mobileSizes._18px};
                object-fit: contain;
              }
            }
          }
        }
        // .brand-list {
        //   // height: ${(props) =>
          props.collapseBrands ? "22.57vw" : "auto"};
        //   // overflow: hidden;
        // }
      }
      .filter-section:last-of-type {
        border: 0;
        padding: 0 ${mobileSizes._16px};
      }
    }
    .btn-container {
      padding: 0.556vw 1.111vw;
      background: #ffffff;
      .submit-btn {
        width: 22.6vw;
        height: 3.194vw;
        cursor: pointer;
        border: none;
        background: #154cd6;
        color: #ffffff;
        border-radius: 0.278vw;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 1.111vw;
        line-height: 1.458vw;
      }
    }
  }
`;

export default SidebarFilter;
