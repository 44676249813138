import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/logo.svg";
import { ReactComponent as Facebook } from "../../images/social-media/facebook.svg";
import { ReactComponent as Twitter } from "../../images/social-media/twitter.svg";
import { ReactComponent as Google } from "../../images/social-media/google.svg";
import { ReactComponent as Youtube } from "../../images/social-media/youtube.svg";
import { ReactComponent as Linkedin } from "../../images/social-media/linkedin.svg";
import { ReactComponent as LanguageIcon } from "../../images/language.svg";
import { ReactComponent as Dots } from "../../images/dots.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../redux/slices/LanguageSlice";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
const Footer = ({}) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  const dispatch = useDispatch();
  return (
    <StyledFooter language={language}>
      <div className="part1">
        <div className="row">
          <div className="info">
            <Link to="/">
              <img className="logo" src={logo} />
            </Link>
            <div className="text">{t.footer.text}</div>
          </div>
          <div className="links">
            <Link to="/build-car">{t.navBar.buildCar}</Link>
            <Link to="/car-finance">{t.navBar.carFinance}</Link>
            <Link to="/reviews">{t.navBar.reviews}</Link>
            <Link to="/cars">{t.navBar.CarsForSale}</Link>
          </div>
        </div>
        <div className="social-media-links">
          <Link to="">
            <Youtube />
          </Link>
          <Link to="">
            <Twitter />
          </Link>
          <Link to="">
            <Google />
          </Link>
          <Link to="">
            <Facebook />
          </Link>
          <Link to="">
            <Linkedin />
          </Link>
        </div>
      </div>
      <div className="part2">
        <div className="col1">
          <Link to="">{t.footer.privacyPolicy}</Link>
          <Link to="">{t.footer.termUse}</Link>
          <p>© 2021 All rights reserved</p>
        </div>
        <div className="col2">
          <button
            className="language"
            onClick={() => {
              dispatch(changeLanguage());
            }}
          >
            <LanguageIcon />
            <p>{language == "en" ? "English" : "العربية"}</p>
          </button>
          <div>
            <Dots />
            <p>USD</p>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};
const StyledFooter = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: 0 10.417vw 2.222vw 10.417vw;
  .part1 {
    display: flex;
    flex-direction: column;
    gap: 1.667vw;
    padding: 2.222vw;
    padding-bottom: 2.639vw;
    background: #f5f6f7;
    border-radius:  0.556vw .556vw  0 0;
    width: 78.889vw;
    height: 22.083vw;
    .row {
      display: flex;
      align-items: flex-start;
      gap: 8.681vw;
      .info {
        .logo {
          width: 16.042vw;
          height: 3.958vw;
          @media (min-width: 1440px) {
            width: 231px;
            height: 57px;
          }
        }
        .text {
          width: 22.778vw;
          height: 6.944vw;
          margin-top: 2.222vw;
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-UltraLight"};
          font-style: normal;
          font-weight:font-family: ${(props) =>
            props.language == "en" ? "300" : "400"};
          font-size: 0.972vw;
          line-height: 1.736vw;
          color: #0d0a19;

          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
      .links {
        display: flex;
        flex-direction: column;
        gap: 1.458vw;
        a {
          font-family: ${(props) =>
            props.language == "en" ? "Inter" : "GE-SS-Bold"};
          font-style: normal;
          font-weight: ${(props) =>
            props.language == "en" ? "600" : "700"};
          font-size: 1.111vw;
          line-height: 1.667vw;
          color: #0d0a19;

          @media (min-width: 1440px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .social-media-links {
      display: flex;
      align-items: center;
      gap: 0.972vw;
      a {
        svg {
          width: 1.389vw;
          height: 1.389vw;

          @media (min-width: 1440px) {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  .part2 {
    direction : ltr;
    padding: 1.111vw 3.125vw 1.111vw 3.889vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    width: 78.889vw;
    height: 3.889vw;

    .col1 {
      display: flex;
      align-items: center;
      gap: 0.972vw;

      a {
        font-family: ${(props) =>
          props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        font-style: normal;
        font-weight:700;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #0d0a19;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      p {
        font-weight: 400;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #9a9ea7;
        @media (min-width: 1440px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .col2 {
      display: flex;
      align-items: center;
      gap: 1.528vw;

      
      .language, div {
        display: flex;
        align-items: center;
        gap: 0.417vw;

        svg {
          width: 1.25vw;
          height: 1.25vw;
          fill: #9a9ea7;
          path {
            fill: #9a9ea7;
          }

          @media (min-width: 1440px) {
            width: 18px;
            height: 18px;
          }
        }

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: #9a9ea7;
          @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
      .language { 
        background : none;
        outline : none;
        border : none;
        cursor : pointer;
        p{
          font-family: ${(props) =>
            props.language == "en" ? "Poppins" : "GE-SS-Bold"};
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 0 10.667vw 0;
    .part1 {
      gap: 6.4vw;
      padding: 6.4vw 4.267vw;
      border-radius: 0;
      width: 100%;
      height: 123.2vw;
      .row {
        display: flex;
        flex-direction: column;
        gap: 6.4vw;
        .info {
          .logo {
            width: 40.8vw;
            height: 10.133vw;
          }
          .text {
            width: auto;
            height: auto;
            margin-top: 4.267vw;
            font-size: 3.733vw;
            line-height: 5.6vw;
          }
        }
        .links {
          display: flex;
          flex-direction: column;
          gap: 3.2vw;
          a {
            font-size: 4.267vw;
            line-height: 6.4vw;
          }
        }
      }
      .social-media-links {
        gap: 3.2vw;
        a {
          svg {
            width: 6.4vw;
            height: 6.4vw;
          }
        }
      }
    }
    .part2 {
      padding: 4.8vw 4.267vw;
      display: flex;
      flex-direction: column;
      gap: 4.267vw;
      align-items: flex-start;
      background: #fbfbfb;
      width: 100%;
      height: auto;

      .col1 {
        width: 100%;
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4.267vw;
        a {
          font-weight: 600;
          font-size: 3.733vw;
          line-height: 5.6vw;
        }
        p {
          font-weight: 600;
          font-size: 3.733vw;
          line-height: 5.6vw;
          margin-top: 2.133vw;
        }
      }
      .col2 {
        order: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .language,div {
          display: flex;
          align-items: center;
          gap: 3.2vw;

          svg {
            width: 4.267vw;
            height: auto;
          }

          p {
            font-weight: 600;
            font-size: 3.733vw;
            line-height: 5.6vw;
          }
        }
      }
    }
  }
`;
export default Footer;
