import "./App.css";
import 'react-calendar/dist/Calendar.css';
import Car from "./pages/Car";
import GlobalStyle from "./util/globalStyles";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Cars from "./pages/Cars";
import BuildCar from "./pages/BuildCar";
import BlankContainer from "./BlankContainer";
import DefaultContainer from "./DefaultContainer";
import ScrollTop from "./Components/assets/ScrollTop";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import CarFinance from "./pages/CarFinance";
import { useEffect, useState } from "react";
import CarReviews from "./pages/CarReviews";
import { AuthContextProvider } from "./hooks/Auth/providers/AuthContext.jsx";
import Interceptor from "./hooks/Auth/providers/Interceptor.jsx";
import { Toaster } from "react-hot-toast";
import AccountRoutes from "./pages/Account/AccountRoutes";
import ProtectRoute from "./Components/assets/ProtectedRoute.jsx";
import VerifyUser from "./pages/VerifyUser.jsx";
import Blog from "./pages/Blogs/Blog.jsx";
import SellerPage from "./pages/Seller.jsx";

function App() {
  const checkIsMobile = () => window.innerWidth <= 768;
  const [isMobile, setIsMobile] = useState(checkIsMobile());
  useEffect(() => {
    const onResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [window.innerWidth]);
  return (
    <div className="App">
      <ScrollTop />
      <GlobalStyle />
      <Toaster position="top-right" />
      <AuthContextProvider>
        <Interceptor>
          <Routes>
            <Route element={<DefaultContainer />}>
              <Route path="/" element={<Home />} />
              <Route path="/car/:id" element={<Car />} />
              <Route path="/blog/:blogId" element={<Blog />} />
              <Route path="/cars" element={<Cars />} />
              <Route path={"car-finance"} element={<CarFinance />} />
              <Route path={"reviews"} element={<CarReviews />} />
              <Route
                path="account/*"
                element={
                  <ProtectRoute>
                    <AccountRoutes />
                  </ProtectRoute>
                }
              />
              <Route path="/seller/:id" element={<SellerPage />} />
              {isMobile && <Route path="/build-car" element={<BuildCar />} />}
              {isMobile && <Route path="login" element={<Login />} />}
              {isMobile && <Route path="sign-up" element={<Signup />} />}
              {isMobile && <Route path="verify-user" element={<VerifyUser />} />}
              {isMobile && <Route path="forget-password" element={<ForgetPassword />} />}
            </Route>
            {!isMobile && (
              <Route element={<BlankContainer />}>
                <Route path="/build-car" element={<BuildCar />} />
                <Route path="sign-up" element={<Signup />} />
                <Route path="verify-user" element={<VerifyUser />} />
                <Route path="login" element={<Login />} />
                <Route path="forget-password" element={<ForgetPassword />} />
              </Route>
            )}
            <Route path="*" element={<DefaultContainer />} />
          </Routes>
        </Interceptor>
      </AuthContextProvider>
    </div>
  );
}

export default App;
