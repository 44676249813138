import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Check } from "../../images/check.svg";
import { ReactComponent as Calc } from "../../images/calc-icon.svg";
import Offer from "./Offer";
import car_img from "../../images/car/range-rover.png";
import covered_car from "../../images/covered-car.png";
import { desktopSizes, mobileSizes } from "../../util/sizes";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const BanksOffers = ({ offers, banks, car_detail, setCalculateAgain, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  return (
    <StyleSection language={language}>
      <div className="contact-alert">
        <Check />
        <p>{t.carFinance.result.contact_alert}</p>
      </div>
      <div className="results">
        <div className="offers-container">
          <p className="title">{t.carFinance.result.banksOffers}</p>
          <div className="offers-list">
            {offers.map((offer, index) => (
              <Offer
                offer={offer}
                index={index}
                bank={banks[index]}
                offersLength={offers.length}
                carDetail={car_detail}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="car-card">
            <img src={car_detail?.car.meta_data?.images?.image1 ?? covered_car} className="car-img" />
            <div className="price-div">
              <p className="text">{t.totalPrice}</p>
              <div className="value-div">
                <p className="value">
                  {car_detail ? (
                    (+(+car_detail.car_price).toFixed(2)).toLocaleString()
                  ) : (
                    <span className="currency">--</span>
                  )}
                  <span className="currency"> SAR</span>
                </p>
                <p className="tax">{t.taxExcluded}</p>
              </div>
            </div>
          </div>
          <button
            className="calculate-again"
            onClick={() => {
              setCalculateAgain(true);
            }}
          >
            <Calc />
            <p>{t.calculateAgain}</p>
          </button>
        </div>
      </div>
    </StyleSection>
  );
};

const StyleSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: ${(props) =>
    props.language == "en" ? "2.222vw 10.417vw 3.75vw 10.417vw" : "2.222vw 7.639vw 3.75vw 10.417vw"};
  .contact-alert {
    direction: ltr;
    position: absolute;
    left: ${(props) => (props.language == "en" ? "64.583vw" : desktopSizes._8px)};

    top: 2.222vw;
    width: 24.444vw;
    height: 4.306vw;
    display: flex;
    align-items: center;
    flex-direction: ${(props) => props.language == "ar" && "row-reverse"};
    gap: 0.694vw;
    padding: 1.111vw 1.667vw;
    background: rgba(52, 168, 83, 0.1);
    border-radius: 0.347vw;
    svg {
      width: 1.667vw;
      height: 1.667vw;
    }
    p {
      direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
      font-family: ${(props) => (props.language == "en" ? "Poppins" : "GE-SS-Meduim")};
      font-style: normal;
      font-weight: 500;
      font-size: 1.389vw;
      line-height: 2.083vw;
      color: #34a853;
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .results {
    display: flex;
    align-items: start;
    gap: 1.875vw;
    .offers-container {
      .title {
        font-family: ${(props) => (props.language == "en" ? "Poppins" : "GE-SS-Bold")};
        font-style: normal;
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        font-size: 1.667vw;
        line-height: 2.5vw;
        color: #0d0a19;
        @media (min-width: 1440px) {
          font-size: 24px;
          line-height: 36px;
        }
      }
      .offers-list {
        margin-top: 2.5vw;
        display: flex;
        flex-direction: column;
        gap: 3.75vw;
      }
    }
    .car-card {
      margin-top: 6.597vw;
      width: 25.069vw;
      height: 23.611vw;
      background: #ffffff;
      border: 0.069vw solid #e4e6e8;
      border-radius: 0.278vw;
      padding: 0 1.111vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .car-img {
        width: 22.847vw;
        height: 12.847vw;
        object-fit: cover;
        object-fit: cover;
        -webkit-transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
        transform: ${(props) => props.language === "ar" && "scaleX(-1)"};
        border-radius: ${desktopSizes._8px};
      }
      .price-div {
        width: 22.847vw;
        height: 5.208vw;
        margin-top: 2.222vw;
        background: rgba(185, 185, 185, 0.1);
        border-radius: 0.556vw;
        padding: 1.111vw 0.694vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          font-family: ${(props) => (props.language == "en" ? "Poppins" : "GE-SS-Bold")};
          font-style: normal;
          font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
          font-size: 1.667vw;
          line-height: 2.5vw;
          color: #0d0a19;
          @media (min-width: 1440px) {
            font-size: 24px;
            line-height: 36px;
          }
        }
        .value-div {
          .value {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 1.667vw;
            line-height: 2.5vw;
            color: #0d0a19;
            @media (min-width: 1440px) {
              font-size: 24px;
              line-height: 36px;
            }

            .currency {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 0.972vw;
              line-height: 1.458vw;

              @media (min-width: 1440px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
          .tax {
            font-family: ${(props) => (props.language == "en" ? "Poppins" : "GE-SS-UltraLight")};
            font-style: normal;
            font-family: ${(props) => (props.language == "en" ? "400" : "300")};
            font-size: 0.833vw;
            line-height: 1.25vw;
            color: #0d0a19;
            opacity: 0.6;

            @media (min-width: 1440px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
  .calculate-again {
    cursor: pointer;
    width: 25.069vw;
    height: 4.306vw;
    background: #154cd6;
    border-radius: 0.278vw;
    padding: 0 1.111vw;
    justify-content: center;
    gap: 1vw;
    margin-top: 2.222vw;
    p {
      font-family: ${(props) => (props.language == "en" ? "Poppins" : "GE-SS-Bold")};
      font-style: normal;
      font-weight: ${(props) => (props.language == "en" ? "60" : "700")};
      font-size: 1.389vw;
      line-height: 2.083vw;
      color: #ffffff;
      @media (min-width: 1440px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    svg {
      width: 2.5vw;
      height: 2.5vw;
    }
  }

  @media (max-width: 768px) {
    padding: 5.333vw ${mobileSizes._16px} ${mobileSizes._24px} ${mobileSizes._16px};
    .contact-alert {
      position: absolute;
      left: 16.267vw;
      top: -${mobileSizes._16px};
      width: 79.467vw;
      height: 14.933vw;
      display: flex;
      align-items: center;
      gap: ${mobileSizes._12px};
      padding: ${mobileSizes._16px} ${mobileSizes._24px};
      border-radius: ${mobileSizes._5px};
      svg {
        width: ${mobileSizes._20px};
        height: ${mobileSizes._20px};
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: ${mobileSizes._16px};
        line-height: ${mobileSizes._24px};
      }
    }
    .results {
      display: flex;
      flex-direction: column;
      gap: ${mobileSizes._24px};
      .offers-container {
        order: 2;
        .title {
          font-size: ${mobileSizes._20px};
          line-height: ${mobileSizes._30px};
        }
        .offers-list {
          margin-top: ${mobileSizes._28px};
          display: flex;
          flex-direction: column;
          gap: ${mobileSizes._30px};
        }
      }
      .car-card {
        margin: 0;
        width: 100%;
        height: auto;
        background: none;
        border: 0;
        padding: 0 1.111vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .car-img {
          width: 79.467vw;
          height: 44.533vw;
          object-fit: contain;
          border-radius: ${mobileSizes._4px};
        }
        .price-div {
          width: 100%;
          height: 20vw;
          margin-top: ${mobileSizes._28px};
          border-radius: ${mobileSizes._8px};
          padding: ${mobileSizes._16px};
          .text {
            font-size: ${mobileSizes._20px};
            line-height: ${mobileSizes._30px};
          }
          .value-div {
            .value {
              font-size: ${mobileSizes._20px};
              line-height: ${mobileSizes._30px};

              .currency {
                font-size: ${mobileSizes._12px};
                line-height: ${mobileSizes._18px};
              }
            }
            .tax {
              font-size: ${mobileSizes._10px};
              line-height: ${mobileSizes._15px};
            }
          }
        }
      }
    }
    .calculate-again {
      width: 82.933vw;
      height: 16.533vw;
      border-radius: ${mobileSizes._4px};
      padding: 0 ${mobileSizes._16px};
      margin-top: ${mobileSizes._24px};
      p {
        font-size: ${mobileSizes._20px};
        line-height: ${mobileSizes._30px};
      }
      svg {
        width: ${mobileSizes._24px};
        height: ${mobileSizes._24px};
      }
    }
  }
`;

export default BanksOffers;
