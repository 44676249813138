import { useState, useEffect } from 'react';
import { me } from './me';

export function useUserMe(shouldFetch) {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        if (!shouldFetch) {
            return;
        }

        const fetchUser = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const userData = await me();
                setData(userData);
                localStorage.setItem("user", JSON.stringify(userData));
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [shouldFetch]);

    return { isLoading, data, error };
}
