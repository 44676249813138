import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledForm from "../../assets/StyledForm";
import { ReactComponent as Eye } from "../../../images/eye.svg";
import { ReactComponent as ClosedEye } from "../../../images/eye-close.svg";
import { useSelector } from "react-redux";
import en from "../../../util/locales/en";
import ar from "../../../util/locales/ar";
import { mobileSizes } from "../../../util/sizes";
import { useForm } from "react-hook-form";
import { reset_password } from "../../../hooks/Auth/forget-password/authFunctions";
import mail from "../../../images/auth/success-mail.svg";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./../../../images/close-icon.svg";

const ResetPassword = ({ onSubmit, formData, setFormData, isDisabled }) => {
  const [password1Type, setPassword1Type] = useState("password");
  const [password2Type, setPassword2Type] = useState("password");
  const [matching, setMatching] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [resetLoading, setResetLoading] = useState()
  const [success, setSuccess] = useState();

  const savedPhoneNumber = localStorage.getItem("phone_number");
  const savedOTP = localStorage.getItem("otp");

  const resetSubmit = handleSubmit(async (data) => {
    const { password2, password1 } = data;
    const resetForm = {
      otp: savedOTP,
      password: password1,
      confirm_password: password2,
      phone_number: savedPhoneNumber,
    }
    // console.log("Data Send To Reset: ", resetForm);

    setResetLoading(true)

    try {
      const response = await reset_password(resetForm);

      if (response.status === 200) {
        setSuccess(true);
        onSubmit();
        localStorage.removeItem("phone_number");
        localStorage.removeItem("otp");
      }
    } catch (err) {
      console.error("Error during login:", err);
    } finally {
      setResetLoading(false);
    }
  });

  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const handleConfirmPassword = () => {
    if (formData.password1 !== formData.password2) {
      setMatching(false);
    } else {
      setMatching(true);
    }
  };

  useEffect(() => {
    if (formData.password2) {
      handleConfirmPassword();
    } else {
      if (formData.password1 === formData.password2) {
        handleConfirmPassword();
      }
    }
  }, [formData.password1, formData.password2]);

  const handleSkip = () => {
    localStorage.removeItem("phone_number");
    localStorage.removeItem("otp");
  }

  return (
    <StyledSection language={language}>
      {!success && <div className="">
        <div className={`skip ${language}`} onClick={handleSkip}>
          <Link to="/login">
            <span>{t.authPages.skip}</span>
            <span>
              <CloseIcon />
            </span>
          </Link>
        </div>
      </div>}
      {success ? (
        <div className="success-reset">
          <img src={mail} className="section-icon" alt='img' />
          <div className="main-title">
            <p className="title-1">{t.authPages.forget_password_page.success_reset_password.title}</p>
            <p className="title-2">{t.authPages.forget_password_page.success_reset_password.text}</p>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="rest-title">
            {t.authPages.forget_password_page.reset_password.title}
          </div>
          <div className="text desktop-only">
            {t.authPages.forget_password_page.reset_password.text}
          </div>
          <StyledForm
            onSubmit={(e) => {
              e.preventDefault();
              // onSubmit();
            }}
          >
            <div className="fields">
              <div className="input-div" key="password1">
                {formData["password1"] && (
                  <p className="label">{t.authPages.password}</p>
                )}
                <input
                  name="password1"
                  placeholder={t.authPages.password}
                  type={password1Type}
                  required={true}
                  {...register("password1", {
                    onChange: (e) =>
                      setFormData({
                        ...formData,
                        password1: e.target.value,
                      }),

                    required: {
                      value: true,
                      message: "required",
                    },
                  })}
                  value={formData["password1"] || ""}
                  className={`password-input ${formData["password1"] ? "active_label" : ""
                    }`}
                />
                <button
                  className="password-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    password1Type === "password"
                      ? setPassword1Type("text")
                      : setPassword1Type("password");
                  }}
                >
                  {password1Type == "password" ? <ClosedEye /> : <Eye />}
                </button>
                {errors["password1"]?.message && (
                  <p className="error-text">
                    {language == "en"
                      ? `${t.authPages.password} is ${t[errors["password1"]?.message]
                      }`
                      : `${t[errors["password1"]?.message]} ${t.authPages.password
                      }`}
                  </p>
                )}
              </div>
              <div className="input-div" key="password2">
                {formData["password2"] && (
                  <p className="label">{t.authPages.confirm_password}</p>
                )}
                <input
                  name="password2"
                  placeholder={t.authPages.confirm_password}
                  type={password2Type}
                  required={true}
                  {...register("password2", {
                    onChange: (e) =>
                      setFormData({
                        ...formData,
                        password2: e.target.value,
                      }),

                    required: {
                      value: true,
                      message: "required",
                    },
                    validate: (value) =>
                      value === formData["password1"] || "dont_match",
                  })}
                  value={formData["password2"] || ""}
                  className={`password-input ${formData["password2"] ? "active_label" : ""
                    }`}
                />
                <button
                  className="password-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    password2Type == "password"
                      ? setPassword2Type("text")
                      : setPassword2Type("password");
                  }}
                >
                  {password2Type == "password" ? <ClosedEye /> : <Eye />}
                </button>
                {errors["password2"]?.message ? (
                  errors["password2"]?.message == "required" ? (
                    <p className="error-text">
                      {language == "en"
                        ? `${t.authPages.confirm_password} is ${t[errors["password2"]?.message]
                        }`
                        : `${t[errors["password2"]?.message]} ${t.authPages.confirm_password
                        }`}
                    </p>
                  ) : (
                    !matching && (
                      <p className="error-text">{t.passwords_dont_match}</p>
                    )
                  )
                ) : (
                  !matching && (
                    <p className="error-text">{t.passwords_dont_match}</p>
                  )
                )}
              </div>
            </div>
            <div className="btn-container">
              <button
                className="submit"
                type="submit"
                onClick={resetSubmit}
                disabled={resetLoading}
              // disabled={isDisabled || !matching}
              >
                {t.authPages.forget_password_page.reset_password.setPassword}
              </button>
            </div>
          </StyledForm>
        </div>
      )}
    </StyledSection>
  );
};

const StyledSection = styled.div`



  .success-reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .success-reset .main-title .title-1 {
    color: #0D0A19;
    font-weight: 600;
    font-size: 24px;
    margin: 32px 0 10px;
    line-height: 35px;
  }
  .success-reset .main-title .title-2 {
    color: #9A9EA7;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .main-reset-skip {
    position:relative
  }

  .container {

    .rest-title {
      font-family: ${(props) =>
    props.language == "en" ? "Poppins" : "GE-SS-Bold"};
      font-style: normal;
      font-weight: 700;
      font-size: 2.083vw;
      line-height: 3.125vw;
      color: #0d0a19;
      margin-bottom: 0.833vw;

      @media (min-width: 1440px) {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      align-items: flex-start;
      .rest-title {
        font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
        font-size: ${mobileSizes._20px};
        line-height: ${mobileSizes._30px};
        margin-bottom: ${mobileSizes._24px};
      }
    }
  }
`;

export default ResetPassword;
