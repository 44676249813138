import Calendar from 'react-calendar';
import "./global_blog.css";

function CalendarBlog({ onChangeDate, dateValue }) {

    const clearDate = () => {
        onChangeDate(null)
    }

    return (
        <div className="tags-container">
            <div className="header">
                <h3>Archives</h3>
                <button disabled={!dateValue} onClick={clearDate}>Reset</button>
            </div>
            <Calendar onChange={onChangeDate} value={dateValue} />
        </div>
    );
}
export default CalendarBlog