import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import empty_offers_image from "../../images/empty-offers.jpg"; // Adjust image import path

const EmptyBanksOffers = ({ offers, banks, car_detail, ...props }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  return (
    <StyleSection language={language}>
      <p className="p-title">{t.banks_offers}</p>
      <ContentWrapper language={language}>
        <img src={empty_offers_image} alt="No Offers Found" />
        <h2>{t.no_offers}</h2>
        <p>{t.no_offers_description}</p>
      </ContentWrapper>
    </StyleSection>
  );
};

const StyleSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  padding: ${(props) =>
    props.language == "en" ? "2.222vw 10.417vw 3.75vw 10.417vw" : "2.222vw 7.639vw 3.75vw 10.417vw"};

  background-color: #fbfbfb;
  .p-title {
    font-family: ${(props) => (props.language == "en" ? "Inter" : "GE-SS-Meduim")};
    font-size: calc((24 / 1440) * 100vw);
    line-height: calc((36 / 1440) * 100vw);
    font-weight: 600;
    margin-bottom: calc((36 / 1440) * 100vw);
    color: #0d0a19;
  }
  @media (max-width: 768px) {
    padding: calc((20 / 375) * 100vw) calc((16 / 375) * 100vw) calc((24 / 375) * 100vw) calc((16 / 375) * 100vw);
    .p-title {
      font-size: calc((20 / 375) * 100vw);
      line-height: calc((24 / 375) * 100vw);
      font-weight: 600;
      margin-bottom: calc((24 / 375) * 100vw);
      color: #0d0a19;
    }
  }
`;

const ContentWrapper = styled.div`
  width: calc((1136 / 1440) * 100vw);
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e6e8;
  border-radius: 4px;
  padding: calc((24 / 1440) * 100vw) calc((16 / 1440) * 100vw) calc((24 / 1440) * 100vw) calc((16 / 1400) * 100vw);

  img {
    width: calc((170 / 1440) * 100vw);
    max-width: 200px;
    margin-bottom: calc((30 / 1440) * 100vw);
  }

  h2 {
    font-family: ${(props) => (props.language == "en" ? "Inter" : "GE-SS-Bold")};
    font-size: calc((36 / 1440) * 100vw);
    line-height: calc((48 / 1440) * 100vw);
    font-weight: 700;
    margin-bottom: calc((6 / 1440) * 100vw);
    color: #0d0a19;
  }

  p {
    text-align: center;
    font-size: calc((16 / 1440) * 100vw);
    line-height: calc((24 / 1440) * 100vw);
    font-weight: 400;
    color: #9a9ea7;
    font-family: ${(props) => (props.language == "en" ? "Inter" : "GE-SS-UltraLight")};
  }

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    min-height: 100vh;
    padding: calc((20 / 375) * 100vw) calc((14 / 375) * 100vw) calc((20 / 375) * 100vw) calc((14 / 375) * 100vw);
    img {
      width: calc((200 / 375) * 100vw);
    }

    p {
      font-size: calc((14 / 375) * 100vw);
      line-height: calc((21 / 375) * 100vw);
    }
    h2 {
      font-size: calc((24 / 375) * 100vw);
      line-height: calc((28 / 375) * 100vw);
      margin-bottom: calc((6 / 375) * 100vw);
    }
  }
`;

export default EmptyBanksOffers;
