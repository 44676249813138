import React from 'react'
import { useSelector } from 'react-redux';
import styled from "styled-components";

const BlogBlock = ({ blog }) => {

    const language = useSelector((state) => state.language);

    console.log("BlogBlock blog is : ", blog)

    return (
        <StyledSection language={language}>
            <div>
                <h1 className='main-title'>{blog?.title}</h1>
                <img className='main-image' src={blog?.main_image} alt='image-main_image' />
                <div className="remain-data">
                    <p className='main-content'>{blog?.content}</p>
                    {blog?.sections != null && (
                        <div
                            className='blog-sections'
                            dangerouslySetInnerHTML={{ __html: blog?.sections }}
                        />
                    )}
                </div>
            </div>
        </StyledSection>
    )
}

const StyledSection = styled.div`
    direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
    .main-image {
        width: 100%;
    }
    .remain-data {
        padding: 1.944vw 10.417vw 3.333vw;
    }
    .main-title {
        padding: 50px;
        text-align: center;
        color: #0D0A19;
        font-weight: 600;
        font-size: 45px;
        line-height: 68px;
    }
    .main-content {
        color: #5A5A5A;
        font-size: 16px;
        line-height: 34px;
    }
    .blog-sections {
        padding: 20px 0;
    }
    .blog-sections .sections-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .blog-sections .sections-container img,
    .blog-sections .sections-container video {
        width: 100%;
    }
    .blog-sections .sections-container h1 {
        line-height: 30px;
        color: #0D0A19;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .blog-sections .sections-container p {
        color: #5A5A5A;
        font-size: 16px;
        line-height: 34px;
        margin-bottom: 15px;
    }
`;

export default BlogBlock