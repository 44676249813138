import React, { useContext, useEffect, useState } from 'react'
import VerifyUserComponent from '../Components/Auth/Verify/VerifyUserComponent'
import styled from "styled-components";
import { mobileSizes } from '../util/sizes';
import Card from '../Components/Auth/Card';
import { useSelector } from "react-redux";
import en from "../util/locales/en";
import ar from "../util/locales/ar";
import mail from "../images/auth/success-mail.svg";
import { formatTime } from '../util/formatTime';
import { send_otp } from '../hooks/Auth/signup/authFunctions';
import AuthContext from '../hooks/Auth/providers/AuthContext';
import history from '../util/history';
import { ReactComponent as CloseIcon } from "./../images/close-icon.svg";

const VerifyUser = () => {

    const { logoutHandler, user } = useContext(AuthContext);

    const returnUrl = localStorage.getItem("saved_path");


    const language = useSelector((state) => state.language);
    const t = language === "en" ? en : ar;

    const phone_number = localStorage.getItem("phone_number"); // Retrieve the phone number from localStorage

    const [success, setSuccess] = useState(false);
    const [resendLoading, setResendLoading] = useState(false)

    const [timeLeft, setTimeLeft] = useState(300); // 10 minutes in seconds

    const [digits, setDigits] = useState({
        digit_1: "",
        digit_2: "",
        digit_3: "",
        digit_4: "",
    });

    const isDisabled = (data) => {
        const disabled = Object.values(data).some((x) => x === null || x === "");
        return disabled;
    };

    const verifySubmit = () => {
        setSuccess(true)
    };

    const onSubmitResend = async () => {
        // console.log("phone_number:", phone_number)

        if (phone_number) {
            setResendLoading(true)
            try {
                const response = await send_otp(phone_number);
                if (response.status === 200) {
                    // console.log("OTP Resent Data:", response);
                    setTimeLeft(300)
                    setResendLoading(false)
                }
            } catch (err) {
                console.error("Error during login:", err);
                setResendLoading(false)
            }
        }
    };

    const handleSkip = () => {
        localStorage.removeItem("otp");
        localStorage.removeItem("phone_number");
        if (user) {
            logoutHandler()
        } else {
            history.push("/login")
        }
    }

    // Countdown timer logic
    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [timeLeft]);

    // If User Verified
    useEffect(() => {
        if (user && user?.is_phone_verified) {
            console.log(returnUrl)
            if (returnUrl) {
                history.push(returnUrl);
            } else {
                history.push('/');
            }
        }
    }, [returnUrl, user]);

    return (
        <StyledPage language={language}>
            <Card />
            {success ? (
                <div className="success">
                    <img src={mail} alt='img' />
                    <div className="success-title">
                        {t.authPages.sign_up_page.success_title}
                    </div>
                    <div className="success-text">
                        <p>
                            {" "}
                            {t.authPages.sign_up_page.success_txt_p1} {phone_number}
                        </p>
                        <p>{t.authPages.sign_up_page.success_txt_p2}</p>
                    </div>
                </div>
            ) : (
                <div className="page-verify">
                    <div className={`skip ${language}`}>
                        <button onClick={handleSkip} className={language}>
                            <span>{t.authPages.skip}</span>
                            <span>
                                <CloseIcon />
                            </span>
                        </button>
                    </div>
                    <VerifyUserComponent
                        setTimeLeft={setTimeLeft}
                        onSubmit={verifySubmit}
                        isDisabled={isDisabled(digits)}
                        formData={digits}
                        setFormData={setDigits}
                    />
                    <StyledParagraph timeLeft={timeLeft}>
                        If you didn't receive the code, Click{" "}
                        <span
                            onClick={() => {
                                if (timeLeft === 0) {
                                    onSubmitResend();
                                }
                            }}
                            disabled={timeLeft > 0 || resendLoading}
                        >
                            Resend Code
                        </span>{" "}
                        In <span>{formatTime(timeLeft)}</span>
                    </StyledParagraph>
                </div>
            )}
        </StyledPage>
    )
}

export default VerifyUser

const StyledPage = styled.div`
    direction: ${(props) => (props.language === "en" ? "ltr" : "rtl")};
    display: flex;
    align-items: center;
    gap: 10.903vw;
    padding: 2.222vh 2.083vw 0 2.083vw;
    background: #fff;
    position:relative

    .success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .page-verify .skip{
        position:absolute;
        font-size:16px;
        font-weight:600;
        color:#9A9EA7;
    }
    .page-verify .skip button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:5px;
        cursor:pointer;
        background:transparent;
        font-size: 16px;
        font-weight: 600;
    }
    .page-verify .skip button span{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
    width: 12.569vw;
    height: 8.611vw;
    margin-bottom: 1.667vw;
    }
    .success-title {
    font-family: ${(props) =>
        props.language === "en" ? "Poppins" : "GE-SS-Bold"};
    font-style: normal;
    font-weight: ${(props) => (props.language === "en" ? "600" : "700")};
    font-size: 1.667vw;
    line-height: 2.5vw;
    color: #0d0a19;
    margin-bottom: 0.833vw;
    @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
    }
    }
    .success-text {
    p {
        font-family: ${(props) =>
        props.language === "en" ? "Poppins" : "GE-SS-Meduim"};
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        text-align: center;
        color: #9a9ea7;
        @media (min-width: 1440px) {
        font-size: 14px;
        line-height: 21px;
        }
    }
    }
    }

    .container {
        // width: 29.167vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        .section-icon {
        width: 12.569vw;
        height: 8.611vw;
        margin-bottom: 1.667vw;
        }
        .title {
        margin-bottom: 0.833vw;
        text-align: center;
        p {
            font-family: ${(props) =>
        props.language === "en" ? "Poppins" : "GE-SS-Bold"};
            font-style: normal;
            font-weight: ${(props) => (props.language === "en" ? "600" : "700")};
            font-size: 1.667vw;
            line-height: 2.5vw;
            color: #0d0a19;
            @media (min-width: 1440px) {
            font-size: 24px;
            line-height: 36px;
            }
        }
        }
        .text {
        font-family: ${(props) =>
        props.language === "en" ? "Poppins" : "GE-SS-Meduim"};
        font-style: normal;
        font-weight: 500;
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: #9a9ea7;
        margin-bottom: 1.667vw;
        @media (min-width: 1440px) {
            font-size: 14px;
            line-height: 21px;
        }
        }
    }

    @media (max-width: 768px) {
        padding: ${mobileSizes._12px} ${mobileSizes._16px} ${mobileSizes._24px}
        ${mobileSizes._16px};
        background: #fbfbfb;
        .container {
        .section-icon {
            width: 48.267vw;
            height: 33.067vw;
            margin-bottom: ${mobileSizes._24px};
        }
        .title {
            margin-bottom: ${mobileSizes._32px};
            text-align: center;
            p {
            font-size: ${mobileSizes._20px};
            line-height: ${mobileSizes._30px};
            }
        }
        }
    }
`;

const StyledParagraph = styled.p`
    color: #9A9EA7;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;

    span {
        font-weight: 600;
        line-height: 20px;
        font-size: 14px;

        &:nth-child(1) {
        color: ${(props) => (props.timeLeft > 0 ? "#9A9EA7" : "#154CD0")};
        cursor: ${(props) => (props.timeLeft > 0 ? "not-allowed" : "pointer")};
        }

        &:nth-child(2) {
        font-weight: 700;
        color: #0D0A19;
        }
    }
`;