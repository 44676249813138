import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";

const CustomStepper = ({ steps, activeStep }) => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;
  return (
    <StyledStepper language={language}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </StyledStepper>
  );
};
const StyledStepper = styled.div`
direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  .MuiStepper-root {
    padding: 0;
    background:none;
    width : ${(props) => (props.language == "en" ? "78.819vw" : "80.347vw")};
  }
  .MuiStepLabel-label {
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Bold"};
    font-style: normal;
    font-weight: 500;
    font-size: 1.111vw;
    line-height: 1.667vw;
    text-align: center;
    color: #000000;
    @media(min-width : 1440px){
      font-size: 16px;
      line-height: 24px;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    color: #154cd6;
    font-family: ${(props) =>
      props.language == "en" ? "Poppins" : "GE-SS-Meduim"};
    font-weight: ${(props) => (props.language == "en" ? "600" : "700")};
  }
  .MuiStepConnector-lineHorizontal {
    border-top-width: 0.347vw;
    border-radius: 2.778vw;
  }
  .MuiStepConnector-line {
    border-color: #d9d9d9;
  }
  .MuiStepConnector-active,
  .MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: #154cd6;
    }
  }
  .MuiStepIcon-text {
    color:transparent;
    font-size : 0;
  }
  .MuiPaper-root {
    display : flex;
    align-items : center;
    gap : ${(props) => (props.language == "en" ? "10vw" : "9vw")};
    // justify-content : space-between;
  }
  
  .MuiStep-horizontal {
    padding : 0;
  }

  .MuiStepConnector-alternativeLabel {
    top: 12px;
    left:  ${(props) => (props.language == "en" ? "calc(-130%)" : "calc(95%)")};
    right: ${(props) =>
      props.language == "en" ? "calc(100%)" : "calc(-110%)"};
    position: absolute;
`;
export default CustomStepper;
