import React from "react";
import { PhoneIcon } from "../assets/Icons";
import styled from "styled-components";
import SellerPhoneModal from "../Car/SellerPhoneModal";
import Skeleton from "./Skeleton";

function ContactUs({ t, seller, isLoading }) {
  if (isLoading) return <Skeleton height="100px" />;

  return (
    <Container>
      <Title>{t.seller.enoughQuestion}</Title>
      <SellerPhoneModal seller={seller}>
        <Button>
          <PhoneIcon />
          {t.seller.ContactUs}
        </Button>
      </SellerPhoneModal>
    </Container>
  );
}

export default ContactUs;

const Container = styled.div`
  padding: 2rem 10vw;
  background-color: #154cd6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-bottom: 2rem;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const Button = styled.button`
  background-color: white;
  color: #154cd6;
  padding: 0.7rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    stroke: #154cd6;
  }
  &:hover {
    background-color: #f5f5f5;
  }
`;
