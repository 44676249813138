import React from "react";
import { Outlet } from "react-router";

 const BlankContainer=()=> {
  return (
    <>
      <Outlet />
    </>
  );
}
export default BlankContainer;