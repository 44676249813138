import React, { useEffect, useState } from 'react';
import './Skeleton.css';

const BoxSkeleton = ({ avatar, className }) => {

    const [isFlashing, setIsFlashing] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFlashing(true);
        }, 3000); 

        return () => clearTimeout(timer); 
    }, []);

    return (
        <div className={`card-skeleton ${isFlashing ? 'flash' : ''} ${className}`}>
            {avatar && <div className="skeleton-header"></div>}
            <div className="skeleton-thumbnail"></div>
        </div>
    );
};

export default BoxSkeleton;
