import React, { useEffect, useState } from 'react';
import './Skeleton.css';

const CardSkeleton = ({ avatar }) => {

    const [isFlashing, setIsFlashing] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFlashing(true);
        }, 3000); 

        return () => clearTimeout(timer); 
    }, []);

    return (
        <div className={`card-skeleton ${isFlashing ? 'flash' : ''}`}>
            {avatar && <div className="skeleton-header"></div>}
            <div className="skeleton-thumbnail"></div>
            <div className="skeleton-title"></div>
            <div className="skeleton-text"></div>
            <div className="skeleton-text"></div>
        </div>
    );
};

export default CardSkeleton;
