import { current } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  body_types: {
    sedan: "Sedan",
    hatchback: "Hatchback",
    suv: "Suv",
    convertible: "Convertible",
    "pickup truck": "Pickup Truck",
    other: "Other",
  },
  transmissions: {
    "4x4": "Four wheel",
  },
  required: "required",
  invalid: "Invalid",
  passwords_dont_match: "Passwords don't match",
  showMore: "Show More",
  showLess: "Show Less",
  next: "Next",
  calculate: "Calculate",
  taxExcluded: "Tax Excluded",
  totalPrice: "Total Price",
  price: "Price",
  mobileNumber: "Mobile Number",
  sar: "SAR",
  recommended: "RECOMMENDED",
  completed: "Completed",
  years: "Years",
  header: {
    lang: "English",
    signup: "Sign Up",
    logout: "Logout",
  },
  navBar: {
    CarsForSale: "Cars For Sale",
    buildCar: "Build Your Car",
    carFinance: "Car Finance",
    reviews: "Car Reviews",
  },
  intro: {
    title: "Find your next awesome Car ...",
    title_l1: "Find your next",
    title_l2: "awesome Car ...",
    text: "Search for you preferred sedan, hatchback and suv cars from +54 international brand",
    text_l1: "Search for you preferred sedan, hatchback",
    text_l2: "and suv cars from +54 international brand",
    search: {
      brand: "Brand",
      model: "Model",
      type: "Type",
      price: "Price",
      search: "Search",
    },
  },
  brands: {
    title: "Explore Cars by Top Brands",
    mobileTitle: "Explore Top Brands",
    carsAvalibale: "Cars Available",
  },
  buildCar: {
    title: "Build Your Car ",
    text: "Customize your car by selecting your preferred Brand, Model, Type, Body Shape, Color, Custom Trim, Transmission, Engine Capacity and Fuel Type",
    button: "Let’s Build a Car",
  },
  shapes: {
    title: "Explore by Body Shape",
  },
  calculator: {
    title: "Car Loan Calculator",
    brand: "Brand",
    model: "Model",
    year: "Production Year",
    color: "Body Color",
    type: "Car Type",
    button: "Calculate Your Loan",
  },
  carCard: {
    price: "Price",
    seller: "Seller",
  },
  sellers: {
    topSellers: "Top Sellers",
  },
  news: {
    latestNews: "Latest News",
    relatedNews: "Related News",
  },
  reviews: {
    carvinuReviews: "Carvinu Reviews ",
    review: "REVIEW",
    readMore: "Read More",
    readless: "Read Less",
  },
  footer: {
    text: " F-Series captures America’s best-selling truck crown for 46th straight year, Ford posts record electric vehicles sales, harnesses no. 2 ev automaker spot, ford expands total market",
    privacyPolicy: "Privacy Policy",
    termUse: "Term of Use",
  },
  carsFilter: {
    detailedSearch: "Detailed Search",
    carBrand: "Car Brand",
    searchforBrand: "Search for Brand ...",
    viewAllBrands: "View All Brands",
    viewLess: "View Less",
    price: "Price",
    from: "From",
    to: "To",
    transmission: "Transmission",
    bodyShape: "Body Shape",
    fuelType: "Fuel Type",
    engineCapacity: "Engine Capacity",
    viewResult: "View Result",
    clear: "Clear Filters",
    filter: "Filter",
  },
  carFinanceSteps: {
    carDetails: "Car Details",
    personalInfo: "Personal Info",
    workInfo: "Work Info",
    financialInfo: "Financial Info",
    loansInfo: "Loans Info",
  },
  carFinance: {
    carDetails: {
      brand: "Brand",
      model: "Model",
      year: "Production Year",
      color: "Body Color",
      interiorMaterial: "Interior Material",
      materialColor: "Material Color",
      seller: "Seller",
    },
    personalInfo: {
      fname: "First Name",
      lname: "Last Name",
      email: "Email Address",
      mobileNumber: "Mobile Number",
      nationality: "Nationality",
      nationalID: "National ID",
    },
    workInfo: {
      workType: "Work Type",
      workDuration: "Work Duration",
      salary: "Salary",
      payrollBank: "Payroll Bank",
    },
    financialInfo: {
      food_expense: "Food Expense",
      wages_for_domestics: "Wages for Domestics",
      housing_rent: "Housing (RENT)",
      education_expense: "Education Expense",
      health_care_expense: "Healthcare Expense",
      transport_and_communication_expense: "Transport & communication expense",
      insurance_expense: "Insurance Expense",
      other_expense: "Other Expense",
    },
    loanInfo: {
      homeFinance: "Home Finance",
      personalFinance: "Personal Finance",
      SocialBank_others: "Social Bank & others",
      autoLease: "Auto Lease",
      creditCards: "Credit Cards",
    },
    result: {
      contact_alert: "We will contact you shortly",
      banksOffers: "Banks Offers",
      plans: "Plans",
      installment: "Installment",
      year: "Years",
      downPayment: "Down Payment ",
      loanAmount: "Loan Amount",
      adminFees: "Admin Fees",
      tenure: "Tenure",
      lastBatch: "Last Batch",
      bookNow: "Book Now",
      loginToBook: "Login to Book",
      MonthlyInstallment: "Monthly Installment",
    },
    finicialInfoModal: {
      calculateLoan: "Calculate your car loan",
      savedInfo: "Saved Info",
      savedInfoText: "Calculate loan using your previous saved info",
      newInfo: "New Info",
      newInfoText: "Use a new calculator with new info",
    },
  },
  buildCarPage: {
    step_title: "Select you preferred",
    brand: "Brand",
    engineCapacity: "Engine Capacity",
    carTrim: "Car Trim",
    gearBox: "Gear Box",
    paymentMethod: "Payment Method",

    sectionCard: {
      title_line1: "Build Your",
      title_line2: "Custom Car",
    },
  },
  carPage: {
    price: {
      starting: "Starting",
      text: "Price may be changed based on customization",
    },
    calculator: {
      header: "Calculate Loan",
      text: "Create your custom loan plan",
    },
    specifications: {
      specifications: "Specifications",
      body_type: "Body type",
      body_volume: "Body Volume",
      tank: "Tank",
      engine: "Engine",
      seats: "Seats",
      liters: "Liters",
      cylinders: "-Cylinders",
    },
    warranty: {
      title: "Warranty Coverage",
      warranty: "Warranty",
      distance: "Distance",
      km: "Km",
      years: "Years",
    },
    features: {
      title: "Features",
      fuel_type: "Fuel Type",
      trim: "Trim",
      internal_toolkit: "Internal Tool Kit",
      engine: "Engine",
    },
    availability: {
      title: " Find Your Preferred Car In",
      car_customization: "Car Customization",
      body_color: "Body Color",
      interior_material: "Interior Material",
      interior_color: "Interior Material Color",
      footer_text: "You can request quotation to book this car or start your loan calculation",
      calculateLoan: "Calculate Loan",
      requestQuotation: "Request Quotation",
    },
    quotationSuccess: {
      title: "Request Send Successfully",
    },
    fullname: "Full Name",
    mobile: "Mobile Number",
    createPassword: "Create Password",
    password: "Password",
    LoginandRequest: "Login & Request Quotation",
    registerModal: {
      title: "You need to have an account ",
      text: "Please enter info below to get back to you with requested quotation ",
    },
    loginModal: {
      title: "You need to have to log in",
      text: "Please enter info below to get back to you with requested quotation ",
    },
    quotationModal: {
      title: "Request received successfully",
      text: "One of our team will contact you shortly",
      Okay: "Okay",
      useLoanCalc: " Use Loan Calculator",
    },
  },
  carReviewsPage: {
    title: "Car Reviews",
    latestReviews: "Latest Reviews",
    allReviews: "All Reviews",
  },
  authPages: {
    haveAcount: "Already have an account?",
    dontHaveAccount: "Don’t have an account?",
    sign_up: "Sign Up",
    sign_in: "Sign In",
    login: "Login",
    forget_password: "Forgot Password?",
    email: "Email",
    fname: "First name",
    lname: "Last name",
    mobile: "Mobile Number",
    password: "Password",
    confirm_password: "Confirm password",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    email_address: "Email Address",
    skip: "Skip",
    or: "Or",
    card: {
      text_line1: "A new way",
      text_line2: "to buy and sell cars",
    },
    sign_up_page: {
      header: "Tell us about yourself",
      text: "Enter your details to create your own account",
      agreeTerms: "I agree with terms & conditions",
      continue: "Continue",
      success_title: "Thank you!",
      success_txt_p1: "We sent an email to  ",
      success_txt_p2: "Click confirmation link in the email to verify your account",
    },
    login_page: {
      header: "Sign In to your account",
      text: "Enter your details to proceed",
      signup_google: "Sign Up with Google",
      signup_fb: "Sign Up with Facebook",
      signup_tw: "Sign Up with Twitter",
    },
    forget_password_page: {
      recovery: {
        title_line1: "Forgot your password?",
        title_line2: " Enter your details to recover",
        text: "Enter your details to proceed further",
        recover: "Recover",
      },
      verify: {
        title_l1: "Verify your account",
        title_l2: "Enter 4 digits sent to your phone number",
        text: "Enter your details to proceed further",
        verify: "Verify",
      },
      reset_password: {
        title: "Reset your password",
        text: "Enter your new password and its confirmation",
        setPassword: "Set Password",
      },
      success_reset_password: {
        title: "Password Changed  Successfully",
        text: "You are successfully updated your password ",
      },
    },
  },
  account: {
    accInfo: "Account Info",
    accInfoDesc: "Profile, Photo and Name",
    loginDetails: "Login Details",
    loginDetailsDesc: "Password & Security",
    expenses: "Expenses",
    expensesDesc: "Leasing info",
    fullName: "Full Name",
    fullNameAr: "Full Arabic Name",
    gender: "Gender",
    birthDate: "Birth Date",
    enterBirthDate: "Enter Birth Date",
    email: "Email Address",
    enterEmail: "Enter Email",
    address: "Address",
    enterAddress: "Enter Address",
    nationalId: "National Id",
    enterNationalId: "Enter National Id",
    generalInfo: "General information",
    generalInfoDesc:
      "Everybody that has ever been to a meeting, can recall the all familiar passing of the business cards.",
    deactivateAccount: "Request To Deactivate Account",
    updateAccount: "Update settings",
    workInfo: "Work Info",
    workType: "Work Type",
    WorkDuration: "Work Duration",
    enterYourWorkDuration: "Enter your work duration",
    salary: "Salary",
    enterSalary: "Enter Salary",
    payrollBank: "Payroll Bank",
    financialInfo: "Financial Info",
    enterFinancialInfo: "Enter Financial Info",
    foodExpense: "Food Expense",
    enterFoodExpense: "Enter Food Expense",
    wagesForDomestics: "Wages for Domestics",
    enterWagesForDomestics: "Enter Wages for Domestics",
    housingRent: "Housing (RENT)",
    enterHousingRent: "Enter Housing",
    educationExpense: "Education Expense",
    enterEducationExpense: "Enter Education Expense",
    healthCareExpense: "Healthcare Expense",
    enterHealthCareExpense: "Enter Healthcare Expense",
    transportAndCommunicationExpense: "Transport & communication expense",
    enterTransportAndCommunicationExpense: "Enter Transport & communication expense",
    insuranceExpense: "Insurance Expense",
    enterInsuranceExpense: "Enter Insurance Expense",
    otherExpense: "Other Expense",
    enterOtherExpense: "Enter Other Expense",
    homeFinance: "Home Finance",
    enterHomeFinance: "Enter Home Finance",
    personalFinance: "Personal Finance",
    enterPersonalFinance: "Enter Personal Finance",
    socialBankOthers: "Social Bank & others",
    enterSocialBankOthers: "Enter Social Bank & others",
    autoLease: "Auto Lease",
    enterAutoLease: "Enter Auto Lease ",
    creditCards: "Credit Cards",
    enterCreditCards: "Enter Credit Cards",
    loansInfo: "Loans Info",
    PasswordAndQuestions: "Password & Security",
    changePassword: "Change password",
    currentPassword: "Current password",
    enterCurrentPassword: "Enter Current password",
    newPassword: "New password",
    enterNewPassword: "Enter New password",
    confirmPassword: "Confirm password",
    enterConfirmPassword: "Enter Confirm password",
    sendCode: "Send Code",
    mobileNumber: "Mobile Number",
    enterMobileNumber: "Enter Mobile Number",
    invalidMobileNumber: "Invalid Mobile Number",
    enter4Digits: "Enter 4 digits sent to",
    enterToProceed: "Enter your code to proceed further",
    contactSupport: "Contact support",
    facingIssues: "if you are facing any issues",
    myAccount: "My Account",
    accountUpdated: "Account updated successfully",
    selectBank: "Select Bank",
    selectWorkType: "Select Work Type",
    employmentStatus: "Employment Status",
    selectEmploymentStatus: "Select Employment Status",
    government: "Government",
    private: "Private",
    retired: "Retired",
    stillWorking: "Still Working",
    passwordLength: "Password should be at least 6 characters",
    passwordMatch: "Passwords should match",
    passwordUpdated: "Password updated successfully",
    employee: "Employee",
    area: "Area",
    enterArea: "Enter Area",
    city: "City",
    enterCity: "Enter City",
  },
  thisFieldIsRequired: "This field is required",
  cancel: "Cancel",
  edit: "Edit",
  confirm: "Confirm",
  select: "-Select-",
  yes: "Yes",
  no: "No",
  banks_offers: "Banks Offers",
  no_offers: "No Offers Found",
  no_offers_description: "Please try to search for other vehicles or enter valid calculation info.",
  sellerPopup: {
    title: "WE ARE ALWAYS HERE TO HELP",
    workingHours: "our work hours from 9:00 AM to 11:00 PM",
  },
  calculateAgain: "Calculate Again",
  seller: {
    exploreBrands: "Explore Our Brands Collection",
    showMore: "Show More",
    CarsAvailable: "Cars Available",
    seller: "Seller",
    companyDescription:
      "The company has good experience in the field of buying and selling cars and their spare parts, and the knowledge in this field is complete at the level of the Riyadh region.",
    CarsWeSell: "Cars We Sell",
    trusted: "We Trusted By",
    something_to_make_you_love_us_more: "SOMETHING TO MAKE YOU LOVE US MORE",
    premium_support: "Premium Support",
    available_for_helping_you: "We're available for helping you, including holiday",
    high_quality_cars: "High quality cars",
    cars_certificated_by_gurus: "Our cars 're certificated by gurus who has 20+ experience years",
    trusted_by_10_clients: "Trusted by 10+ clients",
    happy_clients_ready_for_our_cars: "We have 10k+ happy clients who love us and ready for our cars",
    free_cancelation: "Free cancelation",
    free_cancelation_description: "No extra fee, you can cancel your booking anytime",
    payment_with_many_plans: "payment with many plans",
    provide_us_your_details: "Just provide us your Details and we will calculate the plan which suites",
    and_more_features: "And more features",
    this_space_is_too_small: "This space is too small to show you everything we have",
    enoughQuestion: "Enough reasons for you?",
    ContactUs: "Contact Us",
    noCars: "No cars available",
    noPhone: "No phone available",
  },
  Why: "Why",
};
