import React, { useState } from "react";
import "./global_blog.css";

const tagsData = [
    { value: "auto", label: "Auto" },
    { value: "auto_body", label: "Auto Body" },
    { value: "brakes", label: "Brakes" },
    { value: "car", label: "Car" },
    { value: "car_service", label: "Car Service" },
    { value: "mechanics", label: "Mechanics" },
    { value: "oil_change", label: "Oil Change" },
    { value: "repair", label: "Repair" },
    { value: "sound", label: "Sound" },
    { value: "transmissions", label: "Transmissions" },
];

const Tags = ({ setTags ,tags }) => {
    // const handleTagClick = (value) => {
    //     setTag(value)
    // };

    const handleTagClick = (value) => {
        if (tags.includes(value)) {
            setTags(tags.filter((tag) => tag !== value));
        } else {
            setTags([...tags, value]);
        }

    };

    console.log("correct tags: ",tags)

    return (
        <div className="tags-container">
            <h3>Tags</h3>
            <div className="tags-list">
                {tagsData.map((tag, index) => (
                    <span
                        className={`tag ${tags.includes(tag.value) ? "selected" : ""}`} // Add class if selected
                        key={index}
                        onClick={() => handleTagClick(tag.value)}
                    >
                        {tag.label}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default Tags;
