import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import n1 from "../../images/news.png";
import PrevNextPagination from "../assets/PrevNextPagination";
import { useSelector } from "react-redux";
import en from "../../util/locales/en";
import ar from "../../util/locales/ar";
import { BlogCard } from "./ItemCard";
import { useGetAllBlogs } from "../../hooks/blog/blogHooks";
import Spin from "../assets/Spin";
import CardSkeleton from "../assets/Skeleton/CardSkeleton";

const News = () => {
  const language = useSelector((state) => state.language);
  const t = language === "en" ? en : ar;

  const [query, setQuery] = useState({
    page: 1,
    page_size: 3,
    ordering: "-created_at"
  });

  const { isFetching, data: blogs, error } = useGetAllBlogs(query);

  console.log("blogs: ", blogs)

  const handlePageChange = (page) => {
    setQuery({ ...query, page });
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <StyledSection language={language}>
      <div className="header">
        <div className="section-header">{t.news.latestNews}</div>
        <div className="desktop-only">
          <PrevNextPagination
            currentPage={query.page}
            totalItems={blogs?.count || 0}   // Total items from backend
            pageSize={query.page_size}       // Fixed at 10
            onPageChange={handlePageChange}  // Pass function to update page
          />
        </div>
      </div>
      <div className="news-list">
        {isFetching ? (
          <div className="spin-page">
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </div>
        ) : blogs?.results.map((n) => (
          <Link to={"/blog/" + n.id}>
            <BlogCard item={n} />
          </Link>
        ))}
      </div>
    </StyledSection>
  );
};
const StyledSection = styled.div`
  direction: ${(props) => (props.language == "en" ? "ltr" : "rtl")};
  margin-top: 5.694vw;
  padding: ${(props) =>
    props.language == "en" ? " 0 10.417vw" : "0 10.694vw "};
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .news-list {
    display: flex;
    align-items: center;
    gap: 1.944vw;
    margin-top: 2.222vw;
  }
  .news-list > a > div {
    width: 100% !important;
  }
  .news-list > a > div img{
    width: 100% !important;
  }
  .news-list .item-img {
    width: 100% !important;
  }
  .spin-page {
    height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-top: 15px;
  }
  @media (max-width: 768px) {
    margin-top: 0;

    padding: ${(props) =>
    props.language == "en" ? "0 0 0 4.267vw" : "0 4.267vw 0 0"};
    .header {
      margin-right: 0;
    }
    .news-list {
      gap: 3.2vw;
      margin-top: 4.267vw;
      overflow-y: scroll;
      padding-bottom: 6.4vw;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }
    .news-list::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
`;
export default News;
